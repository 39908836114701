.call-center-wrap {

    .mdh-reactable tbody tr:last-child td:first-child {
        padding-bottom: 2px;
        padding-top: 24px;
    }
    .mdh-reactable td {
        padding: 16px 32px;
    }
    .careteam-modal__team-item--text:hover,
    .careteam-modal__team-item--text:focus,
    .careteam-modal__team-item--text:active {
        text-decoration: none;
        color: $mdh-black;
    }
}
.call-center-profile__head-btn {

    .btn-primary,
    .btn-outline-primary {
        margin: 8px 13px;
    }
}
// Call-Center Profile CSS Starts

.call-center-profile-wrap {
    padding: 10px 33px;

    .careteam-modal__team-item {
        justify-content: flex-end;
    }
    .mdh-forms {
        padding: 15px;
    }
    .call-center-profile__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 38px 0;
        border-bottom: solid 1px #E0E0E0;

        .careteam-modal__team-item--text {
            font-size: 24px;
        }
    }
    .call-center-profile__call-request,
    .call-center-profile__call-details {

        .call-center-profile__call-request-head,
        .call-center-profile__call-details-head {
            font-weight: 600;
            font-size: 18px;
            color: #333333;
            padding: 10px;
            border-bottom: 1px solid #E0E0E0;
        }
    }
    .call-center-profile__call-request-item {
        padding: 17px 0;

        .call-center-profile__call-request-item-text {
            padding-left: 60px;
            color: #333333;
            font-size: 15px;
            margin-bottom: 0;
            padding-bottom: 16px;
        }
        &:last-child {
            padding-bottom: 21px;
            border-bottom: 1px solid #E0E0E0;
        }
    }
    .call-center-profile__call-request-link {
        font-weight: 600;
        font-size: 15px;
        color: #137878;
        padding-left: 25px;
    }
    .call-center-profile__call-request-link:hover,
    .call-center-profile__call-request-link:focus,
    .call-center-profile__call-request-link:active {
        text-decoration: none;
        color: #137878;
    }
    .call-center-profile__call-details-item {
        border-bottom: 1px solid #e0e0e0;
        margin-bottom: 43px;

        .call-center-profile__call-details-item-head {
            padding-left: 10px;
            font-weight: 600;
            font-size: 16px;
            color: #333333;
        }
    }
    .call-center-profile__call-details-sub-item {
        border-top: 1px solid #e0e0e0;
        padding-top: 15px;

        td {
            padding: 10px;
        }
    }
}

@media (max-width : 767.98px) {
    .call-center-profile-wrap .careteam-modal__team-item {
        justify-content: flex-start;
        padding-left: 20px;
    }
}

// Call-Center Profile CSS Ends
