.dropdown-menu {
    opacity: 0;
    transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out !important;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out !important;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out !important;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    display: block;
    pointer-events: none;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.0592448);
  }

  .dropdown-menu.show {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
  }
  .dropdown-menu--admin-care-team,
  .dropdown-menu--admin-care-team.show {
      display: flex !important;
      align-items: flex-start !important;
      justify-content: center;
      flex-direction: column !important;
      padding: 8px 21px !important;

      a {
          color: #333333;
          font-size: 13px;
          line-height: 35px;

          &:hover {
              text-decoration: none;
          }
      }
  }

  .dropdown-menu--admin-care-team.dropdown-menu-right {
      right: -20px;
  }
.dropdown-menu-members:after, .dropdown-menu-members:before{
    right:35% !important;
}
