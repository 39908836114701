.messagecenter-box {
    @extend %position-relative;
    height: 619px;
    background: #FFFFFF;
    border: 1px solid rgba(204, 204, 204, 0.6);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.0533571);
    border-radius: 3px;
    margin-top: 10px;
    overflow: auto;
}
@media( max-width:767.98px ){
    .messagecenter-box{
        height: auto;
    }
}
.messagecenter-box::-webkit-scrollbar {
    width: 10px;
}

.messagecenter-box::-webkit-scrollbar-thumb {
    background-color: #b7d6d1;
    border-radius: 50px;
}

.messagecenter-box::-webkit-scrollbar-track {
    background-color: #fafafa;
}

.contact-list {
    @extend %position-relative;
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        height: 64px;
        padding: 12px 13px;
        border-bottom: solid 1px rgba(204, 204, 204, 0.6);
        @extend %common-flex;
        cursor: pointer;
    }

    .selected {
        background-color: $mdh-green100;
    }

    .contact-list--img {
        width: 40px;
        height: 40px;
        background-color: #00CC99;
        border-radius: 50%;
        float: left;
        @extend %common-flex-j;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        overflow: hidden;

        img {
            width: 40px;
            height: 40px;
        }
    }

    .contact-list--name {
        width: calc(100% - 63px);
        padding: 0 24px;

        h4 {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            padding: 0;
        }

        h6 {
            font-size: 13px;
            color: #333;
            opacity: .65;
            margin: 0;
        }
    }

    .contact-list--badge {
        position: relative;
        width: 23px;
        height: 23px;
        background-color: #F1FCF9;
        color: #137878;
        border-radius: 50%;
        float: left;
        @extend %common-flex-j;
        font-size: 12px;
        font-weight: 400;
    }
}

.chat-box--top {
    @extend %position-relative;
    padding: 12px 15px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.6);
    @extend %common-flex;

    .contact--img {
        width: 40px;
        height: 40px;
        background-color: #00CC99;
        border-radius: 50%;
        float: left;
        @extend %common-flex-j;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        overflow: hidden;
        position: relative;

        img {
            width: 40px;
            height: 40px;
        }
    }

    .contact--name {
        width: calc(100% - 64px);
        padding: 0 10px;

        h4 {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            padding: 0;
        }

        h6 {
            font-size: 13px;
            color: #333;
            opacity: .65;
            margin: 0;
        }
    }

    .chat-box-profile-search {
        min-width: 20%;
        @media( max-width:1199.98px){
            min-width: 33%;
        }
        @media( max-width:767.98px){
            min-width: 25%;
        }
        .profile-link {
            font-size: 13px;
            font-weight: 600;
            color: #137878;
            margin: 0 9px;

            &:last-child {
                margin-right: 0px !important;
            }
        }
    }
}

.chat-box-user-details {
    @extend %position-relative;
    @extend %common-flex;

    .badge {
        width: 12px;
        height: 12px;
        z-index: 11;
        padding: 3px;
        background-color: $mdh-green300;
        border-radius: 50%;
        border: 2px solid $mdh-white;
        position: absolute;
        top: 0px;
        left: 30px;
        display: block;
    }
}

.chat-box--middle {
    @extend %position-relative;
    height: 505px;
    overflow-y: auto;
    background-color: #fff;
    overflow-y: auto;
}

.chat-box--middle::-webkit-scrollbar {
    width: 10px;
}

.chat-box--middle::-webkit-scrollbar-thumb {
    background-color: #b7d6d1;
    border-radius: 50px;
}

.chat-box--middle::-webkit-scrollbar-track {
    background-color: #fafafa;
}

.chat-box--bottom {
    @extend %position-relative;
    height: 47px;
    border-top: 1px solid rgba(204, 204, 204, 0.6);
    @media( max-width:1199.98px){
        height: 44px;
    }
    .attachment-wrap {
        width: 46px;
        height: 100%;
        @extend %common-flex-j;
        border-right: 1px solid rgba(204, 204, 204, 0.6);
        float: left;
    }

    .type-data {
        width: calc(100% - 146px);
        height: 100%;
        float: left;

        .form-control {
            height: 100%;
            border-radius: 0;
            line-height: 1;
            border: none;
            resize: none;

            &:focus {
                border: none;
                box-shadow: none;
            }
        }
    }

    .send-btn {
        width: 100px;
        height: 100%;
        float: right;
        justify-content: flex-end;
        padding-right: 10px;
        @extend %common-flex;

        .btn {
            font-size: 13px;
            padding: 3px 16px;
        }
    }
}

.chat-main-content {
    padding: 20px 0;
}

.chat-item {
    padding: 10px 15px;
    font-size: 10px;
    margin-bottom: 10px;

}

.chat-item.flex-row-reverse .bubble {
    margin-right: 16px;
    margin-left: 0;
}

.chat-item.flex-row-reverse .bubble .bubble-inner {
    background-color: #edfbf8;
    color: #333;
    border-radius: 5px;
}

.chat-item .bubble {
    position: relative;
    margin-left: 16px;
    max-width: 590px;

    @media(max-width: 1199.98px) {
        max-width: 350px;
    }
    @media(max-width: 767.98px) {
            max-width: 100%;
            padding-right: 10px;
            margin-left: 15px;
    }
}

.chat-item .bubble .bubble-inner {
    background-color: #f8f8f8;
    padding: 8px;
    border-radius: 5px;
    word-wrap: break-word;
    img{
        max-width: 100%;
    }
}

.chat-item .sender {
    line-height: 1;
    font-size: 16px;
    font-weight: 400;
    margin: 8px 0 8px 0;
}

.chat-item .message {
    line-height: 1.4;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 8px;

}

.chat-item .message .img-wrap {
    max-width: 100%;
    height: 320px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    align-items: flex-start;
}

.chat-item .message a {
    color: #fff;
    font-weight: 600;
}

.chat-item .message img {
    margin-bottom: 10px;
}

.chat-item.flex-row-reverse .time {
    text-align: right;
    color: #333;
    font-size: 10px;
    margin-bottom: 5px;
}

.chat-item .time {
    margin-bottom: 5px;
    font-size: 10px;
    color: #9a9a9a;
    font-style: italic;
}

@media(max-width:767.98px) {
    .messagecenter-box {
        height: auto;
    }

    .chat-item.flex-row-reverse .bubble {
        margin-right: 0px;
    }
}



/* Nurse Information Modal */
.nurse-info-wrapper {
    padding: 20px 0px;

    h4 {
        font-size: 22px;
        margin: 17px 0 4px 0;
    }

    h6 {
        font-size: 16px;
        color: #888;
        margin-bottom: 15px;
    }

    p {
        font-size: 14px;
        color: #333;
    }

    .section-heading {
        font-size: 18px;
        margin-top: 55px;
    }

    ul {
        margin: 19px 0 0 0;
        padding: 0;

        li {
            list-style: none;
            margin-bottom: 7px;
            position: relative;
            padding-left: 20px;

            &:after {
                position: absolute;
                content: '';
                width: 8px;
                height: 8px;
                top: 7px;
                left: 0;
                background-color: #DDE63C;
            }
        }
    }
}

.nurse-info--img {
    width: 114px;
    height: 114px;
    border-radius: 50%;
    background-color: #00CC99;
    float: left;
    overflow: hidden;

    img {
        height: 114px;
        object-fit: fill;
    }
}




.files-shared-wrapper {
    padding: 20px 0px;

    .files-wrapper {
        @extend %position-relative;
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            border-radius: 4px;
            background-color: #edfbf8;
            display: flex;
            align-items: center;
            padding: 14px;
            margin-bottom: 10px;

            h5 {
                margin: 0;
                padding: 0;
                font-family: Raleway;
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                color: #333;
                img{
                    margin-right:10px;
                }
            }

            h6 {
                margin: 0;
                padding: 0 15px 0 0;
                font-size: 16px;
                color: #888;
            }
        }
    }


}
.start-conversation{
    padding: 5px 30px;
}
.message-row{
    display: flex;
    justify-content: space-between;
    .btn-primary{
        padding:5px 30px !important;
    }
}
