.react-date-range-pick{
    width: 100%;
    .react-daterange-picker__wrapper{
        border: thin solid #cccccc;
        padding: 8px;
        width: 100%;
        border-radius: 4px;
    }
    .react-daterange-picker__calendar-button {
        background-image: url(/images/Forms/date.svg);
        background-repeat: no-repeat;
        background-position: center right 0px;
        width: 32px;
        outline: none !important;
        svg{
            display: none;
        }
    }
    .react-daterange-picker__clear-button svg{
        display: none;
        outline: none;
    }
    .react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
        background: #00cc99;
        color: #fff;
        border-radius: 4px;
    }
    .react-calendar__tile--hasActive {
        background: #00cc99;
        color: #fff;
        border-radius: 4px;
    }
    .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
        background-color: #e6e6e6;
        border-radius: 4px;
    }
}
