.known-allergies {
    width: 100%;
    background: rgba(230, 60, 60, 0.06696);
    border-radius: 3px;
    padding: 19px 22px;
    display: flex;
    align-items: center;
    margin-top: 22px;
    margin-bottom: 38px;

    h4 {
        font-family: "Raleway", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #E63C3C;
        margin: 0;
        flex-wrap: wrap;
    }
}

.known-allergies--list {
    width: 80%;
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        float: left;
        margin: 10px 0;
        margin-left: 14px;

        a {
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: $mdh-grey;
            background-color: $mdh-white;
            padding: 6px 14px;
            border-radius: 3px;

            &:hover {
                text-decoration: none;
                opacity: .8;
            }
        }
    }
}

@media(max-width:767.98px) {
    .known-allergies {
        display: block;
        width: 100%;
        float: left;

        h4 {
            text-align: center;
            margin-bottom: 20px;
        }

        .known-allergies--list {
            width: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .known-allergies--list li {
            margin-left: 10px;

            &:first-child {}
        }
    }
}

.medicine-cabinet .section-heading {
    color: $mdh-grey-200;
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.medicine-cabinet .section-heading--green {
    color: $mdh-green300;
}

@media(max-width:767.98px) {
    .known-allergies--list li {
        margin-bottom: 15px;
        margin-left: 0px;
    }

    .known-allergies h4 {
        width: 100%;
    }
}

/* New UI */
.active-medication-wrap {
    float: left;
    width: 100%;
    margin-bottom: 17px;

    .mdh-card {
        position: relative;
        margin-bottom: 19px;
    }
}

.active-medication-head {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: $mdh-blue;
    margin-bottom: 10px;
}

.active-medication-head.completed {
    color: $mdh-green300 !important;
}

.active-medication-head.stopped {
    color: $mdh-red300 !important;
}

.inactive .active-medication-head {
    color: $mdh-grey-200 !important;
}

.inactive .mdh-card {
    -webkit-filter: opacity(.7);
    filter: opacity(.7);
}

.active-medication-name-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    word-break: break-word;
    padding: 20px 0 20px 23px;
    min-height: 203px;
    height: 100%;

    &::before {
        content: "";
        width: 1px;
        height: 100%;
        background-color: $mdh-grey-300;
        position: absolute;
        top: 0;
        right: 0;
    }
    p {
        color: $mdh-grey-200;
    }
    a {
        padding: 3px 0 !important;
    }
}

.active-medication-name {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: $mdh-grey;
    margin-bottom: 0;

    a img {
        margin-left: 8px;
        width: 17px;
        height: 17px;
    }


}
.active-medication-name-wrap {
    .btn-edit-medicine {
        width: 40px;
        height: 40px;
        float: right;
        position: absolute;
        top: 6px;
        right: 20px;
        z-index: 1;
    }
}

.active-medication-details-wrap {
    float: left;
    width: 100%;
    padding: 26px;
    padding-bottom: 0;
    min-height: 203px;
    height: 100%;
    position: relative;

    .btn--edit {
        width: 40px;
        height: 40px;
        float: right;
        position: absolute;
        top: 6px;
        right: 6px;
        z-index: 1;
    }
}

.active-medication-details {
    float: left;
    width: 100%;
    padding-bottom: 28px;

    label {
        font-family: "Source Sans Pro",
            sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: $mdh-grey;
        margin-bottom: 0;
    }

    p {
        font-family: "Source Sans Pro",
            sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: $mdh-grey-200;
        margin-bottom: 0;
    }
}

.active-medication-details-status {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: $mdh-grey;

    .plan-status {
        margin: 0;
        margin-right: 5px;
    }

    .active .plan-status {
        background-color: $mdh-blue !important;
        box-shadow: 0px 0px 15px rgba(70, 167, 250, 0.5) !important;
    }

    a {
        text-decoration: none;
        color: $mdh-red300;

        &:hover {
            color: $mdh-red300;
        }
    }
}

.stopping-reason-select-wrap {
    display: none;
}

@media(max-width: 1199.98px) {
    .active-medication-name {
        font-size: 15px;
    }

    .active-medication-details-head {
        font-size: 13px;
    }

    .active-medication-details-sub {
        font-size: 12px;
    }
}

@media(max-width: 767.98px) {
    .active-medication-name-wrap {
        min-height: 75px;
    }

    .active-medication-name-wrap::before {
        width: 100%;
        height: 1px;
        top: 100%;
    }

    .active-medication-details {
        padding: 20px 22px;
    }
}
@media(min-width: 575.98px) {
    .active-medication-name-wrap {
        .btn--edit {
            display: none;
        }
    }
}
@media(max-width: 575.98px) {
    .active-medication-name-wrap {
        justify-content: flex-start;

        &::before {
            display: none;
        }

        .active-medication-name {
            font-size: 20px;
            font-weight: 600;
            color: $mdh-green600;
        }
    }

    .active-medication-details-wrap {

        .btn--edit {
            display: none;
        }

        .active-medication-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .active-medication-details-head,
        .active-medication-details-sub {
            font-size: 17px;
            font-weight: normal;
            color: $mdh-grey;
        }

        .active-medication-details-sub {
            text-align: right;
        }
    }

    .active-medication-wrap {

        .mdh-card {
            border-radius: 8px;
        }
    }

    .active-medication-details-wrap {
        padding: 0;
    }
}
