.rain-toggle-drop-menu{
    display: flex;
    align-items: center;
    justify-content: center;
    .rain-toggle {
       width: 36px;
       height: 36px;
       background-color: #F5F9F9 !important;
        padding: 0 !important;
    }
    .rain-toggle::after {
        content: "";
        display: none;
    }
    .dropdown-menu {
        width: 175px;
        padding: 12px 21px !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        top: 50%;

        &::after,&::before {
            right: 29%;
        }
    }
    .dropdown-menu--rain-drop a {
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;
        line-height: 35px;
        color: #2F2F2F;
        display: block;

        &:hover,&:focus,&:active {
            text-decoration: none;
        }
    }
    .dropdown-menu--task {
        width: 175px;
        padding: 12px 21px !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        top: 50%;

        &::after,&::before {
            right: 29%;
        }
    }
    .dropdown-menu--rain-drop--task a {
        font-family: 'Open Sans', sans-serif;
        font-size: 11px;
        line-height: 35px;
        color: #2F2F2F;
        display: block;

        &:hover,&:focus,&:active {
            text-decoration: none;
        }
    }
}




