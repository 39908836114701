.care-team-wrap {

    .care-team__dropdown-toggle {
        margin: 0 auto;
        background-color: transparent !important;
        border: none !important;
        color: #44cc98 !important;

        &:after {
            content: "";
            display: none;
        }
    }
    .dropdown-menu {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        min-height: 129px;
        min-width: 233px;
        padding: 10px !important;
        right: -15px;
        top: 32px;

        &:after,&::before {
            right: 50% !important;
        }
    }
    .dropdown-menu.show {
        right: -15px;
        top: 32px;
    }
    .care-team__dropdown-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        color: #888888;

        a {
            color: #44cc98 !important;

            &:hover {
                text-decoration: none;
            }
        }
    }
    .mdh-reactable tbody tr:last-child td:first-child {
        padding-bottom: 0 !important;
    }
    // .table-responsive {
    //     overflow-x: unset;
    // }
    .resources-table__link-icon {
        margin-left: 10px
    }
    .mdh-reactable td {
        padding: 15px 32px;
    }
}
.careteam-modal__team-item {
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.careteam-modal__team-item--text {
    font-size: 15px;
    font-weight: 600;
    color: $mdh-grey;
    padding-left: 20px;
}

// Responsive Section

@media (max-width : 1198.98px) {
    .care-team-wrap .resources-table__link-icon {
        margin-top: 6px;
    }
    .care-team-wrap .dropdown-menu.show,
    .care-team-wrap .dropdown-menu {
        right: -70px;
    }
}

@media (max-width : 991.98px) {
    .care-team-wrap .dropdown-menu.show,
    .care-team-wrap .dropdown-menu {
        right: -70px;
    }
}
@media (max-width : 767.98px) {
    .care-team-wrap .table-responsive {
        overflow-x: auto;
    }
    .care-team-wrap .dropdown-menu.show,
    .care-team-wrap .dropdown-menu {
        right: -70px;
    }
}
.care-team-wrap .dropdown-menu{
    display: none;
}
.care-team-wrap .dropdown-menu.show{
    display: flex;
}
