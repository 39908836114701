.dashboard__alertbox {
    font-size: 12px;
    color: $mdh-grey !important;
    background-color: $mdh-yellow-light !important;
    border-color: $mdh-yellow !important;
}

.dashboard__alertbox--head {
    font-size: 12px;
}

.dashboard__caution-icon {
    padding: 2px 8px;
    border-radius: 50%;
    background-color: $mdh-yellow;
    color: $mdh-white;
    font-size: 14px;
    display: inline-block;
    font-weight: 600;
    margin-right:2px;
}

.dashboard__alertbox--head {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    font-weight: 600;
    padding: 7px 0;
    margin: 0;
}

.dashboard__alertbox--sub {
    line-height: 19px;
    margin: 0;
    padding-bottom: 6px;
    padding-left: 25px;
    font-size: 13px;
}



.dashboard__task {
    padding: 0px 24px 23px 24px;
    padding-bottom: 0;
    list-style: none;
    margin: 0;
}

.dashboard__task-item {
    padding:24px 0 0 0;
    font-weight: 600;
    float: left;
    border-top: solid 1px $mdh-grey100;
    &:first-child{
        border-top: none;
    }
}

.dashboard__task-item--label {
    font-size: 15px;
    font-weight: 600;
    padding-left: 24px;
    @extend %position-relative;
}

.dashboard__task-item-block--label.custom-control-label::after {
    top: 1.4px;
    left: -22.9px;
    width: 18px;
    height: 18px;
}

.dashboard__task-item-block--label:before{
    top:0px;
    color: #ffffff;
    border-color: $mdh-grey;
    background-color: #ffffff;
}

.custom-checkbox .custom-control-input:checked~.dashboard__task-item-block--label::after{
    width: 34px;
    height: 43px;
    background-image: url(/images/dashboard/tick.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    box-shadow: none;
    top: 2px;
    left: -16px;
}
.dashboard__task-item--sub-label {
    @extend %position-relative;
    padding-top: 9px;
    padding-bottom: 5px;
    font-size: 12px;
}

.dashboard__task-item--label-color em {
    font-weight: normal;
    color: $mdh-green400;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
}
.dashboard__task-item--label-text{
    padding-left:25px;
}
.dashboard__task-item--label-text i{
    margin-right:5px;
}
.dashboard__task-item--label-color {
    color: $mdh-grey-200;
}

.dashboard__recent-msg-wrapper,
.dashboard__resources-wrapper {
    margin-left: 15px;
}

.dashboard__recent {
    padding: 10px 11px;
    max-height: 235px;
    overflow-x: auto;
    margin: 0;
    list-style: none;
}

.alert-border {
    padding-bottom: 10px;
    border-bottom: 1px solid #ececec;
}

.dashboard__recent--label {
    margin: 0;
    padding: 12px 16px;
    margin-bottom: 5px;
    width: 100%;
    background-color: rgba(237, 246, 245, 0.8705882352941177);
    border-radius: 4px;
}

.dashboard__recent--label-text {
    font-size: 15px;
    font-weight: 600;
    color: $mdh-grey;
    cursor: pointer;
}

.dashboard__recent--label-text:hover,
.dashboard__recent--label-text:focus,
.dashboard__recent--label-text:active {
    text-decoration: none;
    color: $mdh-green400 !important;
}

.dashboard__recent li:last-child a {
    font-weight: 400;

    span {
        display: none;
    }
}

.dashboard__resources {
    padding: 10px 11px;
    max-height: 270px;
    overflow-x: auto;
    margin: 0;
    list-style: none;
}

.dashboard__resources--label {
    margin: 0;
    padding: 12px 16px;
    margin-bottom: 5px;
    width: 100%;
    background-color: rgba(237, 246, 245, 0.8705882352941177);
    border-radius: 4px;
}

.dashboard__resources--label-text {
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 0px;
}
.navbar__notification-dropdown-messages--time{
    font-size: 13px;
}
input[type=checkbox]:checked + label.dashboard__task-item-block--label {
    text-decoration: line-through;
}
@media (max-width : 767.98px) {

    .dashboard__task-wrapper,
    .dashboard__recent-msg-wrapper,
    .dashboard__resources-wrapper {
        margin: 12px 0;
    }

    .dashboard__task-item--sub-label div {
        display: block !important;
    }

    .dashboard__task-item--label-text {
        font-size: 11px;
        padding-right: 0 !important;
        padding-left: 25px !important;
        padding-top: 10px;
    }
    .dashboard__task{
        padding: 0px 15px 23px 15px;
    }
    .dashboard__task-item-block--label:before{
        top:-2px;
    }
    .dashboard__task-item--label{
        line-height: 1.1;
    }
}
