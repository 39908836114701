.cp-tab-pane{
    padding:44px 10px !important;
}
.cp-list{
    width: 100%;
    margin:0;
    padding:0;
    position: relative;
    li{
        list-style: none;
        position: relative;
        padding-left:25px;
        margin-bottom: 20px;
    &:after{
        position: absolute;
        content:'';
        width:8.54px;
        height:8.54px;
        top:5px;
        left:0;
        background-color:$mdh-yellow;
    }
    }

}
.cp-date{
    font-size: 15px;
    font-weight: 400;
    color: $mdh-grey-200;
    margin-bottom: 8px;
}
.cp-text{
    font-size: 18px;
    font-weight: 400;
    color: $mdh-black;
}
.task-item--row{
    border-top: solid 1px $mdh-grey50;
    padding: 17px 0 8px 0;
    &:first-child{
        border-top:none;
    }
}



.custom-checkbox .custom-control-input:checked~.tasks-checkbox--item::after{
    width: 18px;
    height: 16px;
    background-image: url(/images/dashboard/tick.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    box-shadow: none;
    top: 2px;
    left: 6px;
}
.tasks-checkbox--item{
    padding-left:26px;
    font-weight: 600;
}
.tasks-checkbox--item:before{
    top:0px;
    left:0px;
    color: #ffffff;
    border-color: $mdh-grey;
    background-color: #ffffff;
}
input[type=checkbox]:checked + label.tasks-checkbox--item {
    text-decoration: line-through;
}

@media (max-width : 767.98px) {
    .tasks-checkbox--item:before{
        top:3px;
    }
}
