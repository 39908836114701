.btn {
    font-size: 16px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    padding: 5px 16px;
    border-radius: 3px;
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 4px 15px;
}

// primary

.btn-primary {
    background-color: $mdh-green300 !important;
    border: 1px solid $mdh-green300 !important;
    color: $mdh-white !important;
    font-weight: 500;
    box-shadow: 0px 5px 20px rgba(0, 204, 153, 0.3) !important;
    padding: 5px 49px //box-shadow: 0px -1px 36px rgba(0, 204, 153, .55) !important;
}

.btn-primary:hover,
.btn-primary:active {
    opacity: .8;
}

.btn-primary.btn-lg, .btn-outline-primary.btn-lg {
    padding: 6px 40px;
}

.import-companies__close-btn {
    padding: 4px 36px;
}

// secondary

.btn-secondary {
    background-color: $mdh-green250 !important;
    border: 1px solid $mdh-green250 !important;
    color: $mdh-grey !important;
    font-weight: 500;
}

.btn-secondary:hover,
.btn-secondary:active {
    opacity: .8;
}

.btn--add-task {
    background-color: $mdh-lime !important;
    border: 1px solid $mdh-lime !important;
    color: $mdh-grey !important;
    font-weight: 500;
}

.btn--add-task:hover,
.btn--add-task:active {
    opacity: .8;
}


.btn--request-call{
    padding:5px 15px 5px 35px;
    background-image: url("/images/global/buttons/request-call.svg");
    background-position: 8px center;
    background-repeat: no-repeat;
    background-size: 21px;

}
.btn--care-team{
    padding:5px 15px 5px 35px;
    background-image: url("/images/global/buttons/care-team.svg");
    background-position: 8px center;
    background-repeat: no-repeat;
    background-size: 21px;
}
.btn--patient-notes{
    padding:5px 15px 5px 35px;
    background-image: url("/images/global/buttons/patient-notes.svg");
    background-position: 8px center;
    background-repeat: no-repeat;
    background-size: 21px;
}
.btn--nutrition{
    padding:5px 15px 5px 35px !important;
    background-image: url("/images/global/buttons/nutrition.svg");
    background-position: 8px center;
    background-repeat: no-repeat;
    background-size: 21px;
}
.btn-outline-primary {
    background-color: transparent !important;
    border: 1px solid $mdh-green300 !important;
    color: $mdh-green300 !important;
    font-weight: 500;
}
.btn-outline-danger {
    background-color: transparent !important;
    border: 1px solid $mdh-red !important;
    color: $mdh-red !important;
    font-weight: 500;
    box-shadow: none !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
    opacity: .8;
}

.btn-outline-secondary {
    background-color: transparent !important;
    border: 1px solid $mdh-green600 !important;
    color: $mdh-green600 !important;
    font-weight: 500;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active {
    opacity: .8;
}

.btn-icon{
    width: 29px;
    height: 29px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    padding:0 !important;
    margin-left:6px;
    float: left;
    img{
        max-width: 70%;
    }

}
.btn--remove{
    background-color: $mdh-red-alpha7 !important;
}
.btn--edit{
    background-color: $mdh-green-alpha1 !important;
}
.btn-icon-large {
    width:60px;
}


.add-new-link-wrap {
    display: flex;

    @media(max-width: 575.98px) {
        flex-direction: column;
    }
}

.add-new-link {
    font-size: 13px;
    line-height: 1.2;
    color: #484a1b !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
}

.add-new-link:hover {
    text-decoration: none;
    opacity: .8;
}

.add-new-link i {
    margin-right: 5px;
    float: left;
}

.add-new-link i img {
    float: left;
}
.btn--in-header{
    padding:5px 15px 5px 15px;
}
@media( max-width:1399px) and ( min-width: 1200px){
    .btn--request-call span, .btn--care-team span, .btn--patient-notes span{
        display: none;
    }
    .btn--request-call , .btn--care-team , .btn--patient-notes{
        height: 36px;
        width:36px;
        padding: 0px 15px 0px 20px;
    }
}
@media( max-width:1199.98px){
    .btn--request-call , .btn--care-team , .btn--patient-notes{
        height: 40px;
        width:40px;
        padding: 0px 15px 0px 20px;
    }
    .dropdown-toggle-indicator {
       height: 40px;
    }
    .btn--request-call span, .btn--care-team span, .btn--patient-notes span{
        display: none;
    }
    .main-header-right li {
        margin-left: 6px !important;
    }
}

.action-icon{
    position: relative;
    width: 34px;
    height: 34px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    border-radius: 3px;
    background-color: #E8F2F2;
}
.action-icon:last-child{
    margin-right: 0px;
}
.action-icon--remove{
    background-color: #FCEBEB;
}


.btn-primary--download {
    background-color: $mdh-yellow !important;
    border: 1px solid $mdh-yellow !important;
    color: $mdh-black !important;
    font-weight: 500;
    height: 100px;
    width: 100px;
}
.chat-item.flex-row-reverse .btn-primary--download {
    float: right;
}
.btn-primary--download img{
    position: relative;
    top:3px;
}

.btn-primary:hover,
.btn-primary:active {
    opacity: .8;
}
.status-change-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media(max-width: 1199.98px) {
        flex-direction: column;
        align-items: flex-start;
    }
    .btn {
        margin-left: auto;
        padding: 2px 20px;

        @media(max-width: 1199.98px) {
            margin-top: 8px;
            margin-left: 0;
        }
    }
} 