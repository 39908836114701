.mdh-tabs--demographics .nav-item{
    flex-grow: 1;
    text-align:center;
}
.mdh-tabs .nav-item .nav-link .badge{
    display: inline-block;
    border-radius: 0px;
    font-size: 15px;
    background-color: $mdh-grey150;
}
.mdh-tabs .nav-item.show .nav-link .badge, .mdh-tabs .nav-link.active  .badge{
    background-color: $mdh-green300;
}
.mdh-tabs .nav-link:hover .badge{
    background-color: $mdh-green300;
}
.patient-checkbox .custom-control-label:before{
    top:0px;
}
@media (max-width: 767.98px) {
    .mdh-tabs--demographics .nav-item{
        width: 100% !important;
        padding:0px !important;
    }
    .mdh-tabs--demographics  .nav-item .nav-link{
        padding: 15px 15px !important;
    }
}
