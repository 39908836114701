.resources-top-section{
    width: 100%;
    float: left;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: $mdh-green-alpha05;
    padding: 11px;
    .form-group{
        margin-bottom: 0px;
        .form-control{
            height: 32px;
        }
    }
}
.custom-checkbox--resources{
    float: left;

    .custom-control-label::before, .custom-control-input:checked ~ .custom-control-label::before{
        top:1px;
    }
    .custom-control-label::after {
        top:6px !important;
        left: -19.9px !important;
    }
}
.custom-react-select--resources{
    margin-bottom: 0px;
    width: 40% !important;
    .react-select__control{
        min-height: 32px !important;
    }
    .react-select__value-container{
        padding: 0 8px;
        .css-1wa3eu0-placeholder{
            line-height: 1;
        }
    }
    .react-select__indicator{
        padding: 4px;
    }
}
.resources-input{
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: flex-end;
}
.btn__add-resources.btn-outline-secondary {
    background-color: transparent !important;
    box-shadow: none !important;
}
.mdh-reactable {

    .resources-table__link-icon {
        padding: 15.5px 10.5px;
        background: rgba(19, 120, 120, 0.0960428);
        border-radius: 3px;
    }
    .resources-table__link-icon.active,
    .resources-table__link-icon.active path {
        fill: #ff0000;
        background-color: rgba(230, 60, 60, 0.1) !important;
    }

}
.resource-span-icon {
    position: relative;
    width: 43px;
    height: 43px;
    background-color: $mdh-green220;
    float:left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.resources-upload {
       .resources-upload--name {
        font-size: 16px;
        color: $mdh-green600;
        margin-bottom: 0;
        width: 100%;
    }
    .resources-upload--delete-icon {
        display: flex;
        justify-content: flex-end;
    }
}
.resources-upload.selected {
    background-color: #ffeaec;

    .resources-upload--name {
        color: #ff0000;

        svg path {
            fill: #ff0000;
        }
    }
}

@media (max-width: 575.98px) {
    .resources-upload--name {
        font-size: 15px;
    }
}

@media (max-width : 767.98px) {
    .resources-input .custom-checkbox--resources {
        width: 65%;
    }
}

@media (max-width : 767.98px) {
    .resources-input {
        margin-top: 8px;
    }
    .resources-input .custom-react-select--resources {
        width: 70% !important;
    }
    .resources-input .custom-checkbox--resources {
        margin-right: auto;
    }
    .resources-top-section .form-group .form-control {
        width: 100%;
    }
}
