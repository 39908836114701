.hr {
    margin: 20px 0 40px 0;
}

.hr-small {
    margin: 7px 0 22px 0;
}

.hr-small2 {
    margin: 0px 0 32px 0;
}

.p-30 {
    padding: 40px 30px;
}
.pt-13{
    padding-top:13px !important;
}
.px--5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.w-15 {
    width: 15% !important;
}

.round {
    border-radius: 50%;
}

.mt-3-1 {
    margin-top: 17px !important;
}

.mb-1-6 {
    margin-bottom: 6px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}
.mb-40{
    margin-bottom: 40px;
}
.height205{
    height: 205px !important;
}
.bg-admin-green {
    background-color: #137878 !important;
}
/***Extended ***/
%position-relative {
    position: relative;
    width: 100%;
    float: left;
}

%common-flex {
    display: flex;
    align-items: center;
}

%common-flex-j {
    display: flex;
    align-items: center;
    justify-content: center;
}

.light-yellow {
    background-color: #DDE63C !important;
}

.dark-yellow {
    background-color: #B9BB98 !important;
}

.light-green {
    background-color: #00CC99 !important;
}

.dark-green {
    background-color: #979797 !important;
}
.remove-bg-color {
    background-color: transparent !important;
}
.no-border {
    border: none !important;
}
.color-black {
    color: #000000 !important;
}
.ft-size-20 {
    font-size: 20px !important;
}

/***Extended ***/
%position-relative {
    position: relative;
    width: 100%;
    float: left;
}

%common-flex {
    display: flex;
    align-items: center;
}

%common-flex-j {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mb-16{
    margin-bottom: 16px !important;
}

.mb-28 {
    margin-bottom: 28px;
}

.initial-loader {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.initial-loader--inner {
    position: relative;
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media( max-width:767.98px){
    .mtp-15{
        margin-top:8px;
    }
}

.resources-top-section .btn-primary {
    padding: 3px 16px;
    width: 100%;
}
