.custom-checkbox .custom-control-label::before{
    border-radius: 0px;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after{
    background: $mdh-green300;
    box-shadow: 0px 0px 10px rgba(0, 204, 153, 0.502576);
    top: 3px;
}



.custom-control-input{
    width: 20px !important;
    height: 20px !important;
    top: 0px !important;
    left: 0 !important;
    z-index: 99999 !important;
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}
.custom-control{
    display: flex;
    align-items: center;
}
.custom-control-label{
    padding-left:6px;
}
.custom-control-label::before{
    width: 20px;
    height: 20px;
    top: -2px;
}
.custom-control-label::after{
    top: 3.4px;
    left: -19px;
    width: 10px;
    height: 10px;

}
.custom-radio .custom-control-input:checked~.custom-control-label::after{
    background-image: none;
    background-color: $mdh-green300;
    border-radius: 50%;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: $mdh-white;
    border-color: $mdh-grey150;
    background-color: $mdh-white;
}

.custom-control-wrapper{
    position: relative;
    width: 100%;
    float: left;
    height: 38px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 24px;

    .custom-control{
        padding-left: 0px;
        display: inline;
        margin-right: 60px;
    }
}

.custom-checkbox--hipaa-agree .custom-control-label::after {
    top: 3.4px !important;
    left: -19.9px !important;
}

.custom-checkbox--terms-agree .custom-control-label::after {
    top: 3.4px !important;
    left: -18.9px !important;
}

.custom-react-select--admin .react-select__indicators {
    background-image: url(/images/Forms/date.svg);
    background-repeat: no-repeat;
    background-position: center right 10px;
    cursor: pointer;
}
.custom-react-select--admin .react-select__indicators svg {
    display: none;
}
.custom-react-select--admin .react-select__indicators .react-select__indicator-separator {
    margin-right: 23px;
}
.menu {
    border: 1px solid #ced4da;
    border-radius: 2px;
    padding: 10px;

    item {
        padding: 5px;
    }
}
.item-highlighted {
    background-color: $mdh-green250;
    color: $mdh-green600;
}
