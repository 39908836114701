.profile-details-wrap {

    .profile-details__head {
        padding-top: 7px;

        .profile-details__head-text {
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            letter-spacing: 0.95px;
            text-transform: uppercase;
            color: #333333;
            margin-bottom: 0;
        }
        .profile-details__head-pam-score {
            font-size: 15px;
            line-height: 25px;
            color: $mdh-green600;
            background: rgba(0, 204, 153, 0.063937);
            width: 118px;
            height: 24.5px;
            padding: 2px;
            margin-left: 14px;
            vertical-align: text-bottom;
        }
        .profile-details__head-last-updated {

            .profile-details__head-last-updated--text {
                font-size: 15px;
                line-height: 25px;
                color: #333333;
                margin-bottom: 0;

                span {
                    font-weight: 600;
                }
            }
        }
        .profile-details__head-item {

            .profile-details__head-item-links {
                font-weight: 600;
                font-size: 15px;
                line-height: 25px;
                color: $mdh-green300;
                padding-right: 7px;

                &:hover,&:focus,&:active {
                    text-decoration: none;
                }
            }
            .profile-details__head-item-dashes {
                font-size: 15px;
                line-height: 25px;
                color: $mdh-grey-200;
            }
        }
    }

    .mdh-tabs--demographics .nav-item {
        width: 11%;
    }
    .mdh-tabs .nav-item .nav-link {
        padding: 15px;
        font-size: 15px;
    }
    .navigation-tab-scrollor {
        margin-top: 0;
    }
    .mdh-acc-collapse {
        margin-top: 15px;
    }
    .mdh-accordion-level2 .mdh-accordion-level2--head {
        padding-bottom: 8px;
    }
}

.profile-details-tabs__demographics-wrap {

    .profile-details-tabs__demographics-footer {
        margin-top: 17px;
        margin-bottom: 9px;
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;

        .profile-details-tabs__demographics-footer-hipaa-text {
            font-family: 'OpenSans', sans-serif;
            font-size: 12px;
            line-height: 14px;
            padding: 11px;
            padding-top: 14px;
            padding-left: 0;
            margin-bottom: 21px;

            .profile-details-tabs__demographics-footer-hipaa-link {
                color: #137878;

                &:hover,&:focus,&:active {
                    text-decoration: none;
                }
            }
        }
    }
}

.profile-details-tabs__medical-history-wrap {

    .profile-details-tabs__medical-history-allergies-wrap {
        padding-top: 3px;
        padding-bottom: 54px;

        .profile-details-tabs__medical-history-allergies {
            display: flex;
            align-items: center;

            .profile-details-tabs__medical-history-allergies-text {
                font-size: 18px;
                line-height: 40px;
                color: #000000;
                margin-bottom: 0;
                padding-left: 16px;
            }
        }
    }
    .profile-details-tabs__medical-history-guarantor-wrap {
        padding-bottom: 9px;
        padding-top: 15px;

    }
    .btn-wrapper {
        align-items: center;
    }

    .assessment-summary--textarea {
        height: 150px;
    }
}
.care-plan-wrap {

    .care-plan__section-heading {
        font-size: 16px;
        line-height: 20px;
        color: #000000 !important;
        padding-top: 25px;
    }
    .mdh-tabs--demographics {
        white-space: nowrap;
        overflow-y: auto;
        flex-wrap: nowrap;

        // .nav-item {
        //     width: 20% !important;
        // }
    }
    .mdh-tabs--demographics .nav-item {
        width: 20% !important;
        margin-bottom: 0;

        @media( max-width:767.98px){
            width: auto !important;

        }
    }
    .cp-date {
        margin-bottom: 16px;
    }
    .completed .plan-status {
        background-color: #ccc;
        box-shadow: 0px 0px 15px rgba(204, 204, 204, 0.514);
    }
    .mdh-acc-arrow {
        width: 100%;
    }
}
.tab-content > .tab-pane {
    padding: 14px 30px;
}
.mdh-modal--add-care-plan .custom-control-wrapper {
    margin-left: 0;
    height: auto;
}
.btn-outline-secondary.btn-primary {
    box-shadow: none !important;
}

.mdh-modal--add-care-plan .custom-control-label {
    margin-left: 22px;
}
.medical-diagnosis-form {
    margin-top: 23px;

    .form-control {
        min-height: 42px !important;
    }
    .mdh-acc-collapse {
        margin-top: 22px;

        .form-group {
            margin-bottom: 24px;
        }
    }
}
.medical-diagnosis-form-group {
    margin-bottom: 14px !important;

    .medical-diagnosis-card-wrap {
        background-color: #F4F9F9;
        margin: 1px !important;
        padding: 10px;
    }
    .medical-diagnosis-card-delete {
        border: 1px solid #E63C3C;
        color: #E63C3C;
        border-radius: 3px;
        width: 41px;
        height: 41px;
        margin-bottom: 0;
    }
}
.care-plan__modal-add-btn {
    padding: 8.5px 19.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0 !important;
    border-radius: 3px;
    width: 100%;
    @media( max-width:767.98px){
        padding:0 19.5px;
        font-size: 30px;
    }
}
.profile-details-head-wrap {
    float: left;
    width: 100%;
}
.profile-details-tabs__medical-providers-wrap {
    margin-top: 0 !important;

    .profile-details-tabs__medical-providers-guarantor-wrap {
        padding-bottom: 22px;
        padding-top: 0;
    }
}
.profile-details-tabs__progress-note-wrap, .profile-details-tabs__visits-wrap {

    .profile-details-tabs__progress-note--head {
        padding-top: 7px;
        border-bottom: 2px solid #ededed;
        margin-bottom: 23px;

        .profile-details-tabs__progress-note--head-text {
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #333333;
            margin-bottom: 5px;
        }
    }
    .profile-details-tabs__progress-note-viewed {
        font-weight: 600;
        font-size: 12px;
        color: #FFFFFF;
        background-color: $mdh-green300;
        text-transform: uppercase;
        padding: 0 4px;
        margin-left: auto;
        margin-right: 18px;
        margin-bottom: 0;
    }
    .profile-details-tabs__progress-note-not-viewed {
        font-weight: 600;
        font-size: 12px;
        color: #ACACAC;
        background-color: #E8E8E8;
        text-transform: uppercase;
        padding: 0 4px;
        margin-left: auto;
        margin-right: 18px;
        margin-bottom: 0;
    }
    .btn-icon {
        width: 23.63px;
        height: 23.63px;
    }
    .notes-outer-wrapper::-webkit-scrollbar-thumb {
        background: rgba(19, 120, 120, 0.23);
    }
    .notes-outer-wrapper--grid li {
        padding: 15px 21px;
        padding-bottom: 8px;
    }
    .notes-outer-wrapper {
        height: auto;
        margin-bottom: 4px;
    }
}
.mdh-modal--add-progress-notes {
    margin-top: 18%;
    max-width: 753px;

    .modal-header, .modal-footer {
        border: none;
    }
    .mdh-modal--add-progress-notes-input {
        width: 100%;
        height: 213px;
        border: 1px solid #C5C5C5;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 15px;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: #333333;
    }
}
.mdh-accordion-level2--head.disabled {
    color: #acacac;

    .handle--plus {
        background: transparent url("/images/global/plus-disabled.svg") no-repeat center;
    }
    .handle--minus {
        background: transparent url("/images/global/minus-disabled.svg") no-repeat center;
    }
}
.profile-details__head-red-link-wrap {
    float: left;
    width: 100%;

    .known-allergies--list li {
        margin: 10px 14px 10px 0;

        a {
            background-color: rgba(230, 60, 60, 0.06696);
            color: #E63C3C;
            padding: 4px 14px;
        }
    }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
    .care-plan-wrap .mdh-tabs--demographics .nav-item {
        width: 25% !important;
    }
}

.patients-profile-dependents {
    position: relative;
    width: 100%;
    background-color: #fafafa;
    border: solid 1px rgba(19, 120, 120, 0.1);
    border-radius: 4px;
    padding: 8px 8px;
    margin-bottom: 15px;
    .has-access {
        float: right
    }
    .dependent-remove-text{
        color: red;
        padding-left: 5px;
        font-weight: 600
    }
    .dependent-name-text{
        font-weight: 600;
        padding-left: 20px;
    }
}
.has-access-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;

    @media(max-width: 575.98px) {
        justify-content: flex-start;
        margin-top: 5px;
    }
}
