.custom-react-time{
    margin-bottom: 30px;
}
.react-time-select{
    width: 100%;

    .rc-time-picker-icon {
        background-image: url(/images/Forms/date.svg);
        background-repeat: no-repeat;
        background-position: center right 10px;
        cursor: pointer;
        width: 100%;
        height: 40px;
        float: left;
        position: absolute;
        top: 0;
        left: 0;

        &::after {
            position: absolute;
            content: '';
            height: 100%;
            width: 1px;
            background-color: #cccccc;
            top: 0;
            right: 40px;
        }
    }
}
.react-time-select .rc-time-picker-input{
    height: 41px;
    width: 100%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.rc-time-picker-panel{
    width: 95% !important;
    outline: none !important;
    @media( max-width:767.98px){
        width: 60% !important;
    }
}
.rc-time-picker-panel-inner{
    width: 100%;
    top:3px;
    min-width: 200px;
}
.rc-time-picker-panel-input-wrap{
    padding:9px 6px;
}
.rc-time-picker-panel-select{
    width:33.33% !important;
}
.rc-time-picker-panel-select::-webkit-scrollbar {
    width: 10px;
}

.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
    background-color: #b7d6d1;
    border-radius: 50px;
}

.rc-time-picker-panel-select::-webkit-scrollbar-track {
    background-color: #fafafa;
}
.rc-time-picker-panel-select li{
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
}
.rc-time-picker-clear-icon{
    display: none;
}
.rc-time-picker-panel-input{
    font-size: 16px;
}
