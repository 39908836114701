.fh-auth-wrap {
    min-height: 100vh;
    width: 100%;
    background-color: $mdh-green200;
    padding: 15px;
    -webkit-animation: fadein 0.3s ease-in-out;
    animation: fadein 0.3s ease-in-out;
}
.fh-auth-wrap__data {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
    background: $mdh-white;
    width: 956px;
    max-width: 100%;
    position: relative;
    // overflow: hidden;
    border-radius: 4px;
    -webkit-animation: fadein 0.3s ease-in-out;
    animation: fadein 0.3s ease-in-out;
}
.auth-wrap {
    float: left;
    width: 100%;
}
.auth-wrap__col--left {
    .auth-wrap__col--left-inner {
        background: transparent url('/images/auth/auth-bg-full-grn.png') no-repeat center;
        background-size: cover;
        height: 100%;
        width: 92%;
        padding: 44px 44px 37px 44px;
        display: flex;
        align-items: flex-end;
        position: relative;
    }
    // &:after {
    //     position: absolute;
    //     content: '';
    //     top: 0;
    //     left: 0;
    //     width: 92%;
    //     height: 100%;
    //     background-color: $mdh-green-alpha5;
    //     float: left;
    //     z-index: 10;
    // }
}
.auth-wrap-left-data {
    width: 100%;
    text-align: left;
    z-index: 11;
    .auth-wrap-left-data__h2 {
        font-size: 28px;
        line-height: 36px;
        text-transform: uppercase;
        color: $mdh-white;
        margin-top: 14px;
        display: flex;
        align-items: center;
        font-weight: 600;
        img{
            margin-right:12px;
            @media( max-width:450px){
               margin-top:10px;
               }
        }
        @media( max-width:450px){
         align-items: flex-start;
        }
    }
    .auth-wrap-left-data__hr {
        width: 60%;
        height: 3.5px;
        background-color: $mdh-yellow;
        text-align: left;
        margin: 13px 0;
    }
    .auth-wrap-left-data__h6 {
        font-size: 24px;
        line-height: 30px;
        color: $mdh-white;
        font-weight: normal;
    }
}
.auth-wrap-right-data {
    height: 100%;
    padding: 38px 38px 38px 13px;
    width: 100%;
    .auth-wrap-right-data__logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $mdh-grey100;
        padding-bottom: 45px;

    }
}
.auth-wrap-right-data__form {
    width: 100%;
    float: left;
}
.auth-wrap__form .form-control {
    height: 41px;
}
.auth-wrap__form .form-group {
    margin-bottom: 14px;
}
.auth-wrap__form label {
    font-size: 15px;
    line-height: 1;
    color: $mdh-grey;
    margin-bottom: 8px;
}
.auth-wrap__form_input_number {
    /*Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance:textfield;
    }
}
.auth-wrap-right-data__inner {
    width: 100%;
    padding: 18px 0 36px 0;
    float: left;
    .auth-wrap-right-data__subheader {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 1.71429px;
        color: $mdh-grey;
        text-transform: uppercase;
        margin-bottom: 34px;
    }
}

.auth-wrap-actions {
    margin-top: 29px;
    margin-bottom: 0px !important;
    .auth-wrap-actions__have-account {
        margin-top: 6px;
        font-size: 17px;
        color: $mdh-grey;

        .auth-wrap-actions__have-account-link {
            color: $mdh-green500;
        }
    }
}
.auth-wrap__form--subscription{
    width:100%;
    border: solid 1px $mdh-grey50;
    background-color: $mdh-white100;
    padding:20px;
    border-radius: 3px;
    margin-bottom: 17px;
    float: left;
    display: flex;
    align-items: center;
    .subscription__box{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        float: left;
        .subscription__box-header{
            font-size: 20px;
            color: $mdh-grey;
            margin-bottom: 0;
            strong{
                font-weight: 600;
            }
            a{
                position: relative;
                top:-2px;
                margin-left:6px;
                &:hover{
                    opacity: .8;
                }
            }
        }
    }
}
.auth-wrap__form--subscription.active{
    background: rgba(0, 204, 153, 0.12612);
    border: 1px solid $mdh-green300;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 204, 153, 0.200311);
    border-radius: 3px;
}
@media (max-width: 767.98px) {
    .auth-wrap__col--left .auth-wrap__col--left-inner {
        width: 100%;
        padding: 30px 30px 30px 30px;
    }
    .auth-wrap-right-data {
        padding: 38px 13px 38px 13px;
    }
    .auth-wrap__col--left:after {
        width: 100%;
    }
    .auth-wrap__form--subscription .subscription__box{
        width:100%;
    }
}
@media (max-width: 575.98px) {
    .auth-wrap-right-data .auth-wrap-right-data__logo {
        justify-content: center;
        flex-wrap: wrap;
    }
    .auth-wrap-right-data .auth-wrap-right-data__logo img:first-child {
        margin-bottom: 20px;
    }
}

.qty-input{
    height: 21px !important;
    width:47px;
    font-size: 15px;
    padding: 10px 4px;
}
.auth-wrap__form--admin [class*='col-']{
    padding-left:0;
    padding-right: 0;
}
.verification-head{
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1.71429px;
    text-transform: uppercase;
    color: #333333;
    margin:25px 0;

}
.auth-wrap__form--duo [class*='col-']{
    padding-left:5px;
    padding-right: 5px;
}
.auth-wrap__form--duo .btn-primary{
    padding: 5px 15px;
}
.terms-agree__link {
    color: $mdh-green300;
    text-decoration: underline;

    &:hover {
        color: $mdh-green300;
    }
}
.boxed-custom-text-wrap {
    float: left;
    width: 100%;
    margin-top: 24px;

    @media(max-width: 575.98px) {
        margin-top: 0;
    }

    .boxed-custom-input-wrap.lightblue {
        background: #eff9f7 !important;
        border-color: #dcf5ef !important;
        position: relative;

        &::before {
            background-image: url(/images/global/tick-green-1.svg);
            background-repeat: no-repeat;
        }
        span {
            font-weight: 600;
            margin-right: 6px;
        }

    }
    .boxed-custom-input-wrap.danger {
        background: #fdefef !important;
        border-color: #efe1e1 !important;
        position: relative;

        &::before {
            background-image: url(/images/Signup/close.svg);
            background-repeat: no-repeat;
            top: 15px;
        }
    }
    .boxed-custom-input-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #f5f5f5;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px 25px 10px 35px;
        margin-bottom: 9px !important;

        &::before {
            content: "";
            position: absolute;
            top: 13px;
            left: 12px;
            width: 16px;
            height: 16px;
        }

    }

}
.auth-wrap-right-data__form {
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
        top: 3px;
    }
}
.import-companies-modal__custom-input-wrap {
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
        top: 3px;
    }
    &.dashboard__alertbox {
        background-color: #fcfdeb;
        border: 1px solid #e8ee81;
        border-radius: 3px;
        padding: 15px;
    }
}
