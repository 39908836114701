@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:600,700&display=swap');


body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 15px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    background-color: #F2F2F2;
    font-display: swap;
}
