.custom-react-select--admin-top-section{
    margin-bottom: 0px;
    .react-select__control{
        min-height: 32px !important;
    }
    .react-select__value-container{
        padding: 0 8px;
    }
    .react-select__indicator{
        padding: 4px;
    }
}
@media( max-width:1199.98px){
    .resources-top-section .form-group{
        margin:5px 0;
    }
}

.generic-file-upload{
    position: relative;
    width: 100%;
    border: 1px dashed #979797;
    border-radius: 4px;
    text-align: center;
    padding: 0;
    float: left;
    height: 300px;
    .filepond--drop-label {
        height: 298px!important;
        background: #fff;
    }
    p {
        margin:10px 0 0 0;
        font-size: 16px;
        color: #888;
        a{
            color: #00CC99;
        }
        span{
            color: #00CC99;
            cursor: pointer;
        }
    }
}

.file-upload__head--text {
    margin-bottom: 12px !important;
    margin-top: 6px !important;
    font-size: 22px !important;
}
.import-companies-success__head-text {
    width: 100%;
    text-align: center;
    margin-top: 36px;
    margin-bottom: 0 !important;
    font-size: 28px !important;
}
.import-companies-success__sub-text {
    color: #888888;
    font-size: 20px;
}
.import-companies-modal__table-wrap {
    border: 1px solid #ededed;
    height: 310px !important;
    overflow: auto;
}
.import-companies-modal__success-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 40px;
}
.mdh-global-card-ligth-green {
    background: rgba(0, 204, 153, 0.103283);
    border: 1px solid #00CC99;
    box-sizing: border-box;
    box-shadow: 0px 10px 15px rgba(0, 204, 153, 0.148492);
    border-radius: 8px;
}
