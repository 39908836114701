.nurse-auth-wrap {
    background-image: url("/images/auth/auth-bg-full.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #00CC99;
    width: 100%;
}

.nurse-auth-data-wrap {
    width: 444px;
    max-width: 100%;
}

.nurse-auth-data {
    height: 100%;
    padding: 38px;
    width: 100%;

    .nurse-auth-data__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 6px;
        padding-bottom: 10px;
    }

    .auth-wrap-right-data__text {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 2.82353px;
        text-transform: uppercase;
        color: #137878;
        margin-top: 9.3px;
        margin-bottom: 0;
    }
}
.nurse-auth-data__inner {
    width: 100%;
    padding: 22px 0 30px 0;

    .auth-wrap-actions__have-account {
        margin-top: 8px;
    }
    .auth-wrap-actions__have-account.mt-4 {
        margin-top: 21.6px !important;
    }
    .auth-wrap-actions {
        margin-top: 24px;
    }
}
