.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
    -webkit-text-fill-color: $mdh-grey-200;
    background-color: $mdh-white !important;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 50000s ease-in-out 0s;
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 15px;
    padding-left: 15px;
}

.form-control:focus {
    color: $mdh-grey-200;
    background-color: $mdh-white;
    border-color: $mdh-green200;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 204, 153, .25);
}

.custom-react-datepicker,
.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
    position: relative;
}

.react-select__control {
    min-height: 41px !important;
}

.react-select__control--is-focused {
    border-color: $mdh-green200 !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 204, 153, .25) !important;
}

.react-select__indicator-separator {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.react-select__menu {
    z-index: 6;
    }


.react-datepicker-wrapper .react-dob-input {
    background-image: url('/images/Forms/date.svg');
    background-repeat: no-repeat;
    background-position: center right 10px;
    cursor: pointer;
}

.react-datepicker__input-container:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 1px;
    background-color: hsl(0%, 0%, 80%);
    top: 0;
    right: 40px;
}

.mdh-forms .add-new-link {
    margin-top: 10px;
}

.mdh-forms.filled label {
    color: $mdh-grey-200;
}

.mdh-forms label {
    margin-bottom: 5px;
    font-size: 15px;
    color: $mdh-black;

    .required {
        background: transparent url(/images/Forms/required.svg) no-repeat;
        position: relative;
        width: 15px;
        height: 15px;
        text-indent: -9999999em;
        position: relative;
        display: inline-block;
        top: 6px;
    }
}
.auth-required {
    position: relative;
    position: relative;
    display: inline-block;
    top: 6px;
    font-size: 23px;
    color: red;
}

.mdh-forms .form-control {
    min-height: 41px !important;
}

.mdh-forms .form-group {
    margin-bottom: 30px;
}

.mdh-forms .form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mdh-forms .readonly-field {
    font-size: 18px;
    color: $mdh-black;
    word-wrap: break-word;
}

@media(max-width:767.98px) {
    .mdh-forms .form-actions {
        display: block;
    }

    .mdh-forms .form-actions .btn {
        display: block;
        width: 100%;
        margin-bottom: 15px;
    }
}

.is-valid .react-select__control,
.is-valid .react-dob-input {
    border-color: #28a745;
}

.is-invalid .react-select__control,
.is-invalid .react-dob-input {
    border-color: #dc3545;
}

.is-valid .react-select__control:hover {
    border-color: #28a745 !important;
}

.is-invalid .react-select__control:hover {
    border-color: #dc3545 !important;
}


.is-invalid-subscription {
    border-color: #dc3545 !important;

    h4,
    strong,
    .custom-control-label {
        color: #dc3545 !important;
    }

    .qty-input {
        border-color: #dc3545 !important;
    }
}
.shared-with-outer{
    position: relative;
    width: 100%;
    float: left;
    height: 316px;
    overflow-y: auto;
}
.shared-with-outer::-webkit-scrollbar {
    width: 6px;
}

.shared-with-outer::-webkit-scrollbar-track {
background: #f1f1f1;
}

.shared-with-outer::-webkit-scrollbar-thumb {
background: #2a8080 ;
}

.shared-with-outer::-webkit-scrollbar-thumb:hover {
background: #00cc99 ;
}
