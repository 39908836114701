.mdh-modal {
    margin-top: 41px;
    max-width: 568px;
}

.mdh-modal .modal-title {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: $mdh-grey20;
    padding: 8px 0 25px 0;
}

.mdh-modal--header {
    padding: 0;
    display: flex;
}

.mdh-modal--header .close {
    font-size: 35px;
    font-weight: 400;
    line-height: 1;
    color: $mdh-grey20;
    padding: 0;
    margin: 0;
    outline: none !important;
}

.mdh-modal .modal-content {
    padding: 15px 26px;
}



.request-a-call-modal-head {
    text-align: center;
    padding: 27px;
    border-bottom: 1px solid #e0e0e0;
}

.request-a-call-modal-title {
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: $mdh-black;

}
.requestcall-modal__reasonfrocall-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-right: 0;
}

.requestcall-modal__available-timeslot-wrapper {
    width: 100%;
    border-bottom: 1px solid #dedede;
    padding-top: 2.5px;
}

.requestcall-modal__availability-timeslot {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 21px;
    z-index: 1;
    background-color: $mdh-white;
}

.requestcall-modal__availability-timeslot--item {
    font-size: 14px;
    background-color: #f7f7f7;
    padding: 0;
    min-height: 120px;
    margin: 0 auto;
    margin-left: 0px;
    border-right: 4px solid $mdh-white;
    margin-bottom: 4px;
}

.requestcall-modal__availability-timeslot--item-text {
    font-size: 15px !important;
    font-weight: normal;
    color: $mdh-grey;
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;

}

.requestcall-modal__availability-timeslot--item-block {
    height: 120px;
    padding-left: 0;
}

.requestcall-modal__availability-timeslot--item-block-label {
    width: 100%;
    height: 120px;
    text-align: center;
}

.requestcall-modal__availability-timeslot--item-block-label img {
    margin-top: 23px;
}

.requestcall-modal__reasonfrocall-item {
    padding: 2px 25px;
}

.requestcall-modal__emergency-note {
    font-size: 12px;
    margin: 0;
    color: #cd0000;
}

.requestcall-modal__emergency-card {
    background-color: #f3e5e7 !important;
    border-color: #f3e5e7 !important;
    width: 100%;
    padding: 10px;
    margin-bottom: 32px;
}

.requestcall-modal__reasonforcall {
    padding-top: 25px;
    padding-bottom: 27px;
}

.requestcall-modal__reasonforcall-title {
    font-size: 17px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    margin: 0;
    color: $mdh-black;
}

.requestcall-modal__btn {
    padding: 6px 37px !important;
}

.requestcall-modal__additional-notes {
    border-radius: 4px;
    border-color: #cdcdcd;
    color: #5a5a5a;
}

.requestcall-modal__availability-timeslot--item .custom-checkbox .custom-control-input:checked~label.requestcall-modal__availability-timeslot--item-block-label::after {
    background-image: none;
    content: "";
    background-color: $mdh-green300;
    border-color: $mdh-green300;
    border: 4px solid #ffffff;
    top: 8px !important;
    left: 8px !important;
}

.requestcall-modal__availability-timeslot--item .custom-control-label::after {
    position: absolute;
    top: 5px;
    left: -23px;
    display: block;
    width: 15px;
    height: 15px;
    content: "";
}

.requestcall-modal__availability-timeslot--item .custom-control-label::before {
    position: absolute;
    top: 7px;
    left: 7px;
    display: block;
    width: 18px;
    height: 18px;
    pointer-events: none;
    content: "";
    background-color: $mdh-white;
    border: #BEC5C3 solid 1px;
}

label.requestcall-modal__availability-timeslot--item-block-label::before {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.requestcall-modal__availability-timeslot--item .custom-checkbox .custom-control-label::before {
    border-radius: 0 !important;
}

.requestcall-modal__availability-timeslot--item .custom-control-input:checked~label.requestcall-modal__availability-timeslot--item-block-label::before {
    border-color: $mdh-grey;
    background-color: $mdh-white;
}

.requestcall-modal__availability-timeslot--item .custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: $mdh-white;
    background-color: transparent;
    border-color: $mdh-grey;
}

.custom-control-input:not(:disabled):focus~.custom-control-label::before {
    outline-color: transparent;
}

// Custom Bootstrap Checkbox Ends

// Custom Bootstrap Radio Button Starts

.requestcall-modal__reasonfrocall-block .custom-control-input:checked~.custom-control-label::before {
    border-color: $mdh-grey !important;
    background-color: transparent !important;
}

.requestcall-modal__reasonfrocall-block .custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none !important;
    background-color: $mdh-green300;
    border-radius: 50%;
    border: 3px solid $mdh-white;
}

.requestcall-modal__reasonfrocall-block .custom-control-label::after {
    top: 1px !important;
    left: -21px !important;
    width: 14px !important;
    height: 14px !important;
}


/* Care Team Modal */
.mdh-modal--care-team {
    max-width: 744px !important;

    .request-a-call-modal-head {
        padding: 21px;
    }

    .careteam-modal__team-wrapper {
        padding: 0;
        margin-bottom: 43px;
    }

    .careteam-modal__team-item {
        display: inline-flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #dedede;
        padding: 19px 3px;
    }

    .careteam-modal__team-item--image {
        border-radius: 50%;
    }

    .careteam-modal__team-item--text {
        font-size: 15px;
        font-weight: 600;
        color: $mdh-grey;
        padding-left: 20px;
    }
}

/* Patient Notes Modal */
.mdh-modal--patient-notes {
    max-width: 744px !important;

    .notes-outer-wrapper {
        margin-bottom: 0px;
        height: 55vh;
    }

    .request-a-call-modal-head {
        padding: 21px;
    }

    .patientnote-modal__notes-message--footer {
        width: 100%;
        float: left;

        .form-group {
            margin-bottom: 0px;
        }

        .btn {
            padding: 6px 30px;
        }
    }

    .modal-footer {
        border-top: none;
    }

}

.mdh-modal--patient-notes .notes-outer-wrapper::-webkit-scrollbar {
    width: 10px;
}

.mdh-modal--patient-notes .notes-outer-wrapper::-webkit-scrollbar-thumb {
    background-color: #b7d6d1;
    border-radius: 50px;
}

.mdh-modal--patient-notes .notes-outer-wrapper::-webkit-scrollbar-track {
    background-color: #fafafa;
}
.mdh-modal--add-new-medicine {
    max-width: 834px !important;

    .form-group{
        margin-bottom: 18px;
    }

}
.mdh-modal--add-new-medicine .modal-body{
    padding:10px 1px;
}
.mdh-modal--nurse-message-center{
    max-width:945px !important;
}

/* Patient Sign-up Modal */

// HIPAA Agreement Section

.mdh-modal--patient-auth-hippa {
    max-width: 735px;

    .auth-wrap-actions {
        margin-top: 50px;
        margin-bottom: 16px !important;
    }
}
.patient-auth-hippa__discription {
    max-height: 260px;
    overflow-y: scroll;
    margin-top: 3px;
    padding-right: 30px;

    p {
        font-size: 16px;
        line-height: 30px;
    }
}
.mdh-modal--patient-auth-hippa .patient-auth-hippa__discription::-webkit-scrollbar {
    width: 10px;
}

.mdh-modal--patient-auth-hippa .patient-auth-hippa__discription::-webkit-scrollbar-thumb {
    background-color: #b7d6d1;
    border-radius: 50px;
}

.mdh-modal--patient-auth-hippa .patient-auth-hippa__discription::-webkit-scrollbar-track {
    background-color: #fafafa;
}

.patient-auth-hippa__canvas-wrap {
    display: none;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.patient-auth-hippa__canvas {
    margin: 0 auto;
    border-radius: 5px;
    border: 1px solid #ffffff;
}

.patient-auth-hippa__canvas-note {
    font-size: 15px;
    color: #9d9d9d;
    margin-bottom: 0;
    text-align: center;
}

.patient-auth-hippa__canvas-agreement {
    padding-top: 35px;
    padding-bottom: 0;
}

@media (max-width : 575.98px) {
    .patient-auth-hippa__canvas {
        width: 240px !important;
    }
    .patient-auth-hippa__canvas-wrap::before {
        left: 27px;
        top: 283px;
        font-size: 13px;
    }
    .patient-auth-hippa__canvas-wrap::after {
        border: none;
    }
    .mdh-modal .modal-content {
        padding: 15px 6px;
    }
}

// Terms of Service Section

.mdh-modal--patient-auth-terms {
    max-width: 735px;
}
.patient-auth-terms__discription {
    max-height: 568px;
    margin-top: 6px;
    padding-right: 30px;
    overflow-y: scroll;

    p {
        font-size: 16px;
        line-height: 30px;
    }
}
.mdh-modal--patient-auth-terms .patient-auth-terms__discription::-webkit-scrollbar {
    width: 10px;
}

.mdh-modal--patient-auth-terms .patient-auth-terms__discription::-webkit-scrollbar-thumb {
    background-color: #b7d6d1;
    border-radius: 50px;
}

.mdh-modal--patient-auth-terms .patient-auth-terms__discription::-webkit-scrollbar-track {
    background-color: #fafafa;
}

/* HIPAA Agreement Confirm Modal */

.mdh-modal--patient-auth-hippa-confirm {
    max-width: 535px;
    margin-top: 200px;
}

// .patient-auth-hippa__confirm-btn-group .btn-outline-secondary:hover,
// .patient-auth-hippa__confirm-btn-group .btn-outline-secondary:focus {
//     background-color: #00cc99 !important;
//     border: 1px solid #00cc99 !important;
//     color: #ffffff !important;
//     box-shadow: 0px 5px 20px rgba(0, 204, 153, 0.3) !important;
// }

.patient-auth-hippa__confirm-btn-group .btn-outline-secondary {
    border: none !important;
}
/* Tele Call Proxy Modal */
.mdh-modal--tele-call-proxy {
    max-width: 480px !important;
    .tele-call-data{
        position: relative;
        width: 100%;
        height: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
       text-align: center;
       flex-wrap: wrap;
       .top-section{
           position: relative;
           width: 100%;
           float: left;
       }
       .loader-section{
           position: relative;
           width: 100%;
           float: left;
       }
       .tele-error{
           position: relative;
           width: 100%;
           font-size: 18px;
           text-align: center;
           color:#ff0000;
       }
    }
}
.loader-section{
    position: relative;
    width: 100%;
    float: left;
    text-align: center;
}


/* Call Center Profile Modal */
.mdh-modal--call-center-details {
    max-width: 744px !important;

    .notes-outer-wrapper {
        margin-bottom: 0px;
        height: 55vh;
    }
    .patientnote-modal__notes-message--footer {
        width: 100%;
        float: left;

        .form-group {
            margin-bottom: 0px;
        }
        .btn {
            padding: 6px 40px !important;
            float: right;
        }
    }
    .modal-footer {
        border-top: none;
    }
    .notes-outer-wrapper::-webkit-scrollbar {
        width: 9px;
    }
    .notes-outer-wrapper::-webkit-scrollbar-thumb {
        background-color: #b7d6d1;
        border-radius: 50px;
    }
    .notes-outer-wrapper::-webkit-scrollbar-track {
        background-color: #fafafa;
    }
    .call-notes--textarea {
        height: 150px;
    }
    .call-notes-save--wrap {
        display: flex;
        flex-direction: column-reverse;
    }
}

/* Nurse Care Plan Modal */
.mdh-modal--add-care-plan {
    max-width: 753px;

    .modal-footer {
        border: none;
        padding-top: 0;

        .btn-primary {
            padding: 7.5px 49px;
        }
    }
}
.care-plan__modal--head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    color: #333333;

    .care-plan__modal--head-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        color: #333333;
    }
    .care-plan__modal--head-pagination {
        width: 52px;
        height: 52px;
        background-color: rgba(0, 204, 153, 0.15);
        float: right;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
}
.care-plan__modal-form {
    height: 350px;
    width: 100%;
    padding-right: 25px;
    overflow-x: hidden;

    .care-plan__modal-care-control {
        background-color: rgba(19, 120, 120, 0.05);
        padding: 15px 0px 15px 28px;
        width: 100%;
        border-radius: 4px;
        margin-left: 0;
        margin-right: 0px;

        label {
            margin-left: 12px;
            width: 100%;
            margin-bottom: 0;
        }
    }
    .modal-footer a:hover {
        text-decoration: none !important;
    }
}
.care-plan__modal-form::-webkit-scrollbar {
    width: 10px !important;
}
.care-plan__modal-form::-webkit-scrollbar-thumb {
    background-color: #b7d6d1 !important;
    border-radius: 50px !important;
}
.care-plan__modal-form::-webkit-scrollbar-track {
    background-color: #fafafa !important;
}
.mdh-modal--add-new-company{
    max-width: 1105px !important;
}
.mdh-modal--import-companies {
    max-width: 970px !important;

    .import-companies-modal__custom-input-wrap {

        .custom-control-label {
            padding: 12px 10px !important;
        }
        .custom-control-label::after {
            top: 10px !important;
            left: 5px;
        }
        .custom-control-label::before {
            top: 5px !important;
            left: 0;
        }
    }
    .mdh-forms label {
        margin-bottom: 0;
        margin-top: 5px;
    }
}
.import-companies-modal__table-wrap::-webkit-scrollbar {
    width: 10px !important;
}
.import-companies-modal__table-wrap::-webkit-scrollbar-thumb {
    background-color: #b7d6d1 !important;
    border-radius: 50px !important;
}
.import-companies-modal__table-wrap::-webkit-scrollbar-track {
    background-color: #fafafa !important;
}
.mdh-modal--import-companies-success {
    max-width: 745px !important;
}
.mdh-modal--patient-information{
    max-width:753px !important;

    .mdh-forms [class*='col-']{
        padding-left: 0px;
        padding-right: 0px;
    }
    .patient-notification--textarea{
        height: 203px;
    }
}
.mdh-modal--notice{
    max-width:753px !important;
    .notice--textarea{
        height: 203px;
    }
}
.care-plan__modal-care-control.active {
    background: rgba(0, 204, 153, 0.1);
}
.care-plan__modal-form-sub-text {
    font-size: 14px;
    color: rgba(136, 136, 136, 0.8);
    // padding-left: 18px;
    margin-bottom: 0;
}
.care-plan__modal--head-sub-text {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    padding-bottom: 4px;
    margin-bottom: 0;
    border-bottom: 1px solid #E0E0E0;
}
.care-plan__modal-medical-diagnosis-form {
    margin-bottom: 38px;
}
.edit-medicine-modal__custom-input-wrap {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 12px;

    .custom-control-label::before {
        top: 0;
    }
}

/* Add Task Modal */
.mdh-modal--add-task {
    max-width: 744px !important;

    .notice--textarea {
        height: 200px;
    }
}
/* Bulk Assign Care Team Modal */

.mdh-modal--select-bulk-care-team {
    max-width: 1301px;
}

@media (max-width : 1529.98px) {
    .mdh-modal--select-bulk-care-team {
        max-width: 1024px !important;
    }
}
@media (max-width : 991.98px) {
    .mdh-modal--select-bulk-care-team {
        max-width: 700px !important;
    }
}
@media (max-width : 767.98px) {
    .mdh-modal--select-bulk-care-team {
        max-width: 576px !important;
    }
}

/* Add Members Modal */

.mdh-modal--add-members {
    max-width: 1055px;

    .add-members__btn {
        box-shadow: none !important;

        &:hover,&:focus {
            background-color: $mdh-green300 !important;
            border: 1px solid $mdh-green300 !important;
            color: $mdh-white !important;
        }
    }
    .add-members__btn.active {
        background-color: $mdh-green300 !important;
        border: 1px solid $mdh-green300 !important;
        color: $mdh-white !important;
    }
    .table-row-active {
        background-color: #f0fcf9 !important;
    }
}

/* change user modal */
.mdh-modal--change-account {
    max-width: 534px !important;

    .change-account-name {
        font-size: 16px;
        font-weight: 600;
        color: $mdh-green600;
    }
}

.mdh-modal--manage-dependent{
    max-width: 800px;
    min-height: 315px;
}


/* Add New Care Plan Modal */
.mdh-modal--add-care-plan {

    @media(max-width: 575.98px) {
        .modal-content {
            padding: 15px 12px !important;
        }
    }

    &.big {
        max-width: 922px;

        .care-plan__modal-filter-wrap {
            margin-bottom: 31px !important;
        }
        .care-plan__modal-btn-wrap {
            margin-bottom: 18px !important;
        }
        .boxed-custom-input-wrap {
            padding: 9px 15px;
            margin-bottom: 6px !important;

            @media(max-width: 575.98px) {
                flex-direction: column;
                align-items: flex-start;

                &.long {
                    margin-bottom: 30px !important;
                }
                .task-number {
                    margin-left: 0;
                    margin-top: 8px;
                }
            }

            &.large {
                margin-bottom: 18px !important;
            }
        }
        .modal-footer {
            @media(max-width: 575.98px) {
                flex-direction: column;

                .btn {
                    width: 100%;
                }
            }
        }
    }
    &.add-goals {
        max-width: 585px;

        .care-plan__modal-body-wrap {
            padding: 11px 0px 15px !important;

            .care-plan__modal-btn-wrap {
                margin-top: 50px;
            }
        }
        .care-plan__modal--head {
            border-bottom: 1px solid $mdh-grey-300;
            margin-bottom: 14px;

            .care-plan__modal--head-text {
                margin-bottom: 7px !important;
            }
        }
        .care-plan-input-formgroup {
            margin-bottom: 24px;
        }
        .care-plan__modal-filter-wrap {
            margin-top: 4px;
            height: unset !important;
            max-height: 240px;
            margin-bottom: 0 !important;
            padding-right: 0 !important;

            .boxed-custom-input-wrap.lightblue {
                padding: 14px 7px;
                margin-bottom: 10px !important;

                @media(max-width: 575.98px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
            .boxed-custom-input-wrap.white {

                @media(max-width: 575.98px) {

                }
            }
        }
        .icon-btn-wrap {
            display: flex;
            align-items: center;
            white-space: nowrap;

            p {
                margin-right: 12px;

                @media(max-width: 575.98px) {
                    margin-right: auto;
                }
            }

            @media(max-width: 575.98px) {
                width: 100%;
            }
        }
        .bold {
            font-weight: 600;
        }
        .create-care-plan-btn-wrap {
            margin-top: 9px;
            margin-bottom: 17px;
        }
    }

    .modal-content {
        padding-right: 23px;
    }
    .modal-title {
        padding-bottom: 22px;
    }
    .care-plan__modal-body-wrap {
        padding: 22px 0px 15px !important;

        .care-plan__modal--head .care-plan__modal--head-text {
            margin-bottom: 15px;
        }
        .care-plan__modal--head .care-plan__modal--head-text.right {
            font-size: 18px;
            font-weight: 400;
            color:  $mdh-grey;

            &.shaded {
                color: $mdh-grey-200;
            }
        }
        .care-plan__modal-body--input {
            width: 95%;
            margin-left: 28px;
            min-height: 41px;
        }
        .main-custom-radio {
            padding-left: 0;
            margin-bottom: 10px;
        }
        .care-plan__modal-filter-wrap {
            float: left;
            width: 100%;
            height: 240px;
            overflow-y: auto;
            padding-right: 10px;
            margin-bottom: 35px;
        }
        .care-plan__modal-filter-wrap.box {
            height: 300px;

            @media(max-width: 575.98px) {
                height: 260px;
                margin-bottom: 15px !important;
            }
        }
        .care-plan__modal-filter-wrap.create-care-plan {
            height: 600px !important;
            overflow-y: auto;
        }
        .custom-control-label::before {
            top: 1px;
        }
        .custom-control-label::after {
            top: 6.4px;
        }
        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
            top: 6px;
        }
        .create-care-plan  {
            height: unset !important;
            overflow-y: unset;

            .custom-control-label::before {
                top: 4px;
            }
            .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
                top: 9px;
            }
            .react-datepicker-wrapper .react-dob-input {
                height: 40px;
            }
            .react-datepicker-popper {
                left: -64px !important;

                @media(max-width: 767.98px) {
                    left: 0 !important;
                }
            }
            .react-datepicker__triangle {
                left: 82px;

                @media(max-width: 767.98px) {
                    left: 50px !important;
                }
            }
        }
        .care-plan__modal-filter-divider-line {
            float: left;
            width: 100%;
            height: 1px;
            background-color: $mdh-grey100;
            position: relative;

            &::before {
                content: "OR";
                position: absolute;
                top: -15px;
                left: 48%;
                text-align: center;
                font-size: 18px;
                padding: 0 10px;
                color: $mdh-grey-200;
                background-color: $mdh-white;
            }
        }
        .new-empty-care-plan-wrap {
            float: left;
            width: 100%;
            margin-top: 6px;
        }
        .care-plan__modal-btn-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 9px;

            .btn {
                min-width: 159px;
                padding: 7px 49px;
                margin-right: 15px;

                @media(max-width: 575.98px) {
                    min-width: 124px;
                    padding: 7px 25px;
                }
            }
        }
        .select-new-care-plan-wrap {
            float: left;
            width: 100%;
            position: relative;
            margin-bottom: 25px;
        }
        .mediacal-diagnosis-wrap {
            float: left;
            width: 100%;
            position: relative;
        }
        .care-plan__modal-body--input.input--group {
            width: 100%;
            margin-left: 0;
            border-radius: 3px !important;
            z-index: 0;
        }
        .button--group {
            margin: 3px;
            position: absolute;
            top: 0;
            right: 0;
            width: 15px;
            padding: 4px 20px 5px 12px;
            border-radius: 4px !important;
            z-index: 1;
        }
        .form-control.is-invalid, .was-validated .form-control:invalid,
        .form-control.is-valid, .was-validated .form-control:valid {
            background-position: center right calc(2.675em + .1875rem);
        }
    }
    .create-care-plan-btn-wrap {
        float: right;
        width: 100%;
        margin-top: 18px;

        .btn {
            float: right;
            padding: 7px 16px;
        }
    }
}

.care-plan__modal-filter-wrap::-webkit-scrollbar {
    width: 10px;
}

.care-plan__modal-filter-wrap::-webkit-scrollbar-thumb {
    background-color: #cfe4e1;
    border-radius: 50px;
}

.care-plan__modal-filter-wrap::-webkit-scrollbar-track {
    background-color: #fafafa;
}

.boxed-custom-input-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $mdh-grey-400;
    border: 1px solid $mdh-grey-450;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 9px !important;

    .boxed-custom--sub-text {
        font-size: 14px;
        margin-bottom: 0;
        color: $mdh-grey-200;
        line-height: 25px;
        padding-left: 28px;
    }
    .boxed-custom-input--text {
        font-size: 15px;
        font-weight: 400;
        color: $mdh-grey;
        margin-bottom: 0;
        // width: 85%;
        position: relative;

        @media (max-width: 575.98px) {
            width: 100%;
        }

        img {
            margin-right: 5px;
        }
    }
    .custom-input-header-wrap {
        float: left;
        width: 100%;
        margin-bottom: 5px;
    }
    .goals-wrap {
        float: left;
        width: 100%;
        padding: 22px 0 11px;
        border-top: 1px solid $mdh-grey-300;
    }
}
.boxed-custom-input-wrap.lightblue {
    background: $mdh-lightblue40 !important;
    border-color: $mdh-lightblue10 !important;
}
.boxed-custom-input-wrap.white {
    background: $mdh-lightblue50 !important;
    border-color: $mdh-lightblue20 !important;
    padding: 11px 15px !important;

    .boxed-custom-input--text {
        font-size: 18px;

        a {
            padding: 0 6px;
            color: $mdh-green400;
        }
    }
    .task-number {
        font-size: 18px;
        font-weight: 600;
        color: $mdh-grey;
        margin-bottom: 0;
        margin-left: auto;
    }
}
.boxed-custom-input-wrap.box {
    padding: 6px;
    padding-left: 15px;
    margin-bottom: 6px !important;
}
.boxed-custom-input-wrap.long {
    display: block;
    float: left;
    width: 100%;
    height: 300px;
    overflow-y: auto;
    padding: 21px 15px;
    background: $mdh-grey-400;
    border: 1px solid $mdh-grey-450;

    .boxed-custom-input--text {
        padding-left: 22px;
        margin-bottom:  17px;
        line-height: 1.3;

        &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: 0;
            width: 16px;
            height: 16px;
            background-image: url("/images/global/tick-green.svg");
        }
    }
}
.boxed-custom-input-wrap.large {
    display: block !important;
    float: left;
    width: 100%;
    padding: 17px 25px !important;

    &.lightblue {
        .custom-control-label {
            color: $mdh-grey;
        }

        .custom-react-select {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 0;

            @media (max-width: 575.98px) {
                flex-direction: column;
                align-items: flex-start;

                .react-datepicker-wrapper {
                    margin-top: 6px;
                    width: 100% !important;
                }
            }

            h4 {
                font-size: 18px;
                font-weight: 500;
                color: $mdh-grey;
                margin: 0;
            }
            .react-datepicker-wrapper {
                width: 58%;
                margin-left: auto;
            }
        }
        .form-group {
            margin-bottom: 12px;
        }
    }
    .custom-control-label {
        padding-left: 8px;
        font-size: 18px;
        color: $mdh-grey-200;
    }
}

/* QuestionnaireModal Styles */
.mdh-modal--questionnaire {
    max-width: 764px;

    .questionnaire-wrap {
        float: left;
        width: 100%;
        position: relative;
        margin-bottom: 15px;
        border-bottom: 1px solid $mdh-grey-300;

        &.large {
            .custom-control-wrapper {
                height: auto;
                flex-direction: column;
                align-items: flex-start;

                .custom-control {
                    margin-bottom: 8px;
                }
            }
        }
        .custom-control-wrapper  {
            height: auto;
            min-height: 38px;
            padding-left: 15px;
            margin-bottom: 10px;
            flex-wrap: wrap;

            .custom-control {
                .custom-control-label::before {
                    top: 1px;
                }
                .custom-control-label::after {
                    top: 6.4px;
                }
            }
        }
    }
    .questionnaire--text {
        font-size: 16px;
        font-weight: 600;
        color: $mdh-grey;
        position: relative;
        margin-bottom: 14px;

    }
    .modal-body {
        float: left;
        width: 100%;
    }
    .questionnaire-btn-wrap {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 14px;
    }
}

.recurrences-settings-wrap {
    float: left;
    width: 100%;
    padding: 30px 30px 15px 30px;
    margin-bottom: 30px;
    border-radius: 4px;
    background-color: $mdh-green-alpha05;
    border: 1px solid $mdh-green700;

    .custom-react-select {
        margin-bottom: 15px;
    }

    @media(max-width: 575.98px) {
        padding: 20px 12px;

        .react-date-time-input-wrap {
            .react-datepicker-popper {
                left: -40px !important;
                width: 313px;
            }
        }
    }
}

@media(max-width: 575.98px) {
    .react-date-time-input-wrap {
        .react-datepicker-popper {
            left: -40px !important;
        }
    }
}
.event-modal--add {
    .react-date-time-input-wrap {
        .react-datepicker-popper {
            @media(max-width: 575.98px) {
                left: -22px !important;
            }
        }
    }
}
.mdh-modal--call-center-details {
    .react-datepicker__navigation--next {
        // right: 90px !important;
    }
    .react-datepicker__time-container {
        width: 90px;

        @media(max-width: 575.98px) {
            width: 70px;
        }

        .react-datepicker__time {
            .react-datepicker__time-box {
                width: 90px;
                ul.react-datepicker__time-list li.react-datepicker__time-list-item {
                    padding: 5px;
                }
                @media(max-width: 575.98px) {
                    width: 70px;
                }
            }
        }

    }
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
    right: 95px !important;
}
