.user-type{
    position: relative;
    float: left;
    color: #333;
    font-size: 12px;
    margin-left:22px;
    display: flex;
    align-items: center;
    &:first-child{
        margin-left: 0px;
    }
}
.p-badge{
    position: relative;
    width: 24px;
    height: 24px;
    background-color: red;
    border-radius: 50%;
    float: left;
    margin-right:6px;
    line-height: 1;
    text-align: center;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

}
.patient{
    color: #72761F;
    background-color: #DDE63C;
}
.team-member{
    color: #fff;
    background-color: #137878;
}
.task-status{
    width: 16px;
    height: 16px;
    border-radius: 50%;

}
.task-status--green{
    background: #00CC99;
    box-shadow: 0px 0px 15px rgba(0, 204, 153, 0.3);
}
.task-status--grey{
    background: #E1E1E1;
}
.nurse-tasks-table .mdh-table--nurse .actions-wrap {
    display: flex;
    justify-content: center;
}

@media( max-width:1199.98px){
    .mdh-table tr td, .mdh-table tr th{
        white-space: nowrap;
    }
}
