.navbar {
    padding: 17px 26px 17px 16px !important;
    background-color: $mdh-white !important;
}

.nav-link {
    padding: 0px !important;
    margin-left: 16px;
}

.main-header-right li {
    margin-left: 16px;
}

.main-header__notif-icon {
    padding: 7px 0;
}

.navbar__notification-bell {
    padding: 7px 7px !important;
}

.dropdown-toggle-indicator {
    padding: 6px 12px !important;
}

.navbar__link-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: $mdh-green200;
    color: $mdh-black !important;
    color: $mdh-grey !important;
    border-radius: 4px;
    font-size: 16px !important;
}

.navbar__link-btn:after {
    content: "";
    border: 0;
    background-image: url("/images/global/down-sm.svg");
    background-repeat: no-repeat;
    display: inline-block;
    width: 18px;
    height: 10px;
    margin-left: 8px;
    left: 0;
    top: 0;
}

.navbar__notification-bell.navbar__link-btn:after {
    content: "";
    display: none;
}

.navbar__link-btn svg path {
    fill: $mdh-green600;
    ;
}

.navbar__link-btn--label {
    font-size: 15px;
    font-weight: 600;
    padding-left: 4px;
}

.navbar__notification-dropdown {
    width: 270px !important;
    min-height: 200px !important;
    right: -20px;
    padding: .5rem 0 0 0 !important;
}
@media( max-width: 565.98px ){
    .navbar__notification-dropdown{
        right: -148px;
    }
    .navbar__notification-dropdown:before{
        right: 63% !important;
    }
    .navbar__notification-dropdown:after{
        right: 63% !important;
    }
}
.navbar__notification-dropdown-item {
    font-size: 10px;
    font-family: "Open Sans", sans-serif;
    white-space: normal !important;
    display: block;
    width: 100%;
    font-weight: 400;
    color: $mdh-green600;
    cursor: pointer;
}
.navbar__notification-dropdown-item p{
    margin-bottom: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.navbar__notification-dropdown-item span {
    color: $mdh-black;
}

.navbar__notification-dropdown-item:hover span {
    color: #464545;
}

.navbar__notification-dropdown-item:hover,
.navbar__notification-dropdown-item:focus,
.navbar__notification-dropdown-item:active {
    color: $mdh-green600 !important;
    text-decoration: none;
    opacity: .8;
}

.navbar__requestcall-btn,
.navbar__requestcall-btn path {
    background-color: $mdh-green300 !important;
    color: $mdh-white !important;
    fill: $mdh-white;
    font-size: 14px !important;
    border-radius: 4px;
}

.navbar__header-remainder {
    font-size: 15px;
    font-weight: 600;
    display: flex;
    padding-left: 20px;
}

.navbar__header-remainder--date {
    color: $mdh-green400;
    font-weight: 600;
}

.navbar__header-remainder--date:hover,
.navbar__header-remainder--date:focus,
.navbar__header-remainder--date:active {
    text-decoration: none;
    color: $mdh-green300;
}

.navbar__header-remainder-ondashboard {
    display: none;
}

.navbar__notification-dropdown-link {
    padding: 20px 0 15px 0;
}

.navbar__notification-dropdown-link--text {
    font-size: 12px;
    color: $mdh-green600;
    margin: 0 auto;
}

.navbar__notification-dropdown-link--text:hover,
.navbar__notification-dropdown-link--text:focus,
.navbar__notification-dropdown-link--text:active {
    text-decoration: none;
    color: $mdh-green300;
}

.navbar__notification-dropdown-head {
    border-bottom: 1px solid $mdh-grey-250;
}

.navbar__notification-dropdown-head path {
    fill: $mdh-green300;
}

.navbar__notification-dropdown-head--text {
    padding: 10px 19px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.navbar__notification-dropdown-messages {
    position: relative;
    padding: 14px 0;
    margin-left: 0 !important;
    border-bottom: solid 1px $mdh-grey-250;
}



.navbar__notification-dropdown-messages--time {
    font-size: 10px;
    color: $mdh-grey20;
    padding-bottom: 0px;
    margin-bottom: 0;

    .badge-warning {
        background-color: $mdh-yellow;
        font-size: 8px;
        font-weight: 400;
        color: $mdh-black;
        text-transform: uppercase;
        border-radius: 1px;
    }

    &:hover {
        opacity: .8;
    }
}


.navbar__notification-dropdown-messages-area {
    position: relative;
    max-height: 205px;
    width: 86%;
    left: 7%;
    padding: 0;
    margin: 0;
}

.navbar__profile-dropdown-item {
    padding: 0 10px !important;
}

.navbar__notification-bell {
    position: relative;
}

.navbar__profile-dropdown-item--text {
    font-size: 12px;
    padding-left: 10px;
}

.navbar__notification-badge {
    padding: 3px;
    background-color: $mdh-red;
    border-radius: 50%;
    border: 2px solid #dbf4ee;
    position: absolute;
    top: 7px;
    left: 15px;
}

.navbar__requestcall-btn-sm {
    display: none;
}

.nav-link-sm {
    display: none;
}

.dropdown-toggle-indicator-sm {
    display: none;
}

.navbar__profile-dropdown-item-list {
    list-style: none;
    padding: 12px 10px;
    display: block;
}

.navbar__profile-dropdown-item-list li {
    margin-left: 0px !important;
    padding-bottom: 6px;
}

.navbar__profile-dropdown-item-list .nav-link {
    margin-left: 0px;
}

.navbar__profile-dropdown-item-list li a {
    font-weight: 400;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    color: #212529 !important;
    &:hover{
        text-decoration: none;
    }
}

.dropdown-menu {
    margin: .85rem 0 0 !important;
}

.dropdown-menu.show {
    border: solid 1px $mdh-grey10;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.0536402);
}

.dropdown-menu::before {
    content: "";
    border-top: 10px solid $mdh-grey10;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-top-color: $mdh-grey10;
    right: 16%;
    position: absolute;

    top: -7px;
    transform: rotate(45deg);
}

.dropdown-menu::after {
    content: "";
    border-top: 10px solid $mdh-white;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-top-color: $mdh-white;
    right: 16%;
    position: absolute;
    top: -6px;
    transform: rotate(45deg);
}
.hide-lg{
    display: none !important;
}
@media (max-width: 1312.98px) {
    .navbar__header-remainder {
        padding-left: 5px;
    }

    .nav-item {
        padding-right: 8px;
    }

}

@media (max-width: 1189.98px) {
    .navbar__requestcall-btn {
        padding: 7px 15px !important;
    }

    .navbar__header-remainder {
        padding-left: 0px;
        font-size: 13px;
    }

    .nav-item {
        padding-right: 10px;
    }

    // .navbar__requestcall-btn,
    // .navbar__link-btn {
    //     display: none;
    // }

    .collapse .navbar__requestcall-btn-sm,
    .collapse .nav-link-sm,
    .collapse .navbar__notification-bell,
    .collapse .navbar__profile-dropdown .nav-link {
        display: block !important;
    }
}

@media (max-width: 575.98px) {
    .navbar__header-remainder {
        display: none;
    }

    .navbar__header-remainder-ondashboard {
        display: block;
        padding-bottom: 15px;
        padding-left: 0px;
        font-size: 15px;
        text-align: center;
        margin-bottom: 20px;
        border: 1px dashed #44cc98;
    }
}

// Custom Breakpoints Media Query Ends

// Bootstrap breakpoints Media Query Starts

@media (max-width: 991.98px) {
    .navbar {
        padding: 17px 7px 17px 16px !important;
    }
    .dropdown-toggle-indicator {
        padding: 9px 12px !important;
    }
    .navbar__notification-bell {
        padding: 9px 10px !important;
    }
    .hide-lg{
        display: block !important;
    }
    .offcanvas-collapse.open {
        visibility: visible;
        -webkit-transform: translateX(-280px);
        transform: translateX(280px);
    }

    .offcanvas-collapse {
        position: fixed;
        top: 0;
        bottom: 0;
        left: -280px;
        width: 280px;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-y: auto;
        visibility: hidden;
        background-color: $mdh-white;
        transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out, visibility .3s ease-in-out;
        transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
    }

    .navbar__requestcall-btn-sm,
    .dropdown-toggle-indicator-sm {
        display: block !important;
        padding: 10px 12px !important;
    }

}

@media(max-width: 575.98px) {
    .navbar {
        padding: 17px 7px 17px 7px !important;
    }
    .nav-item {
        padding-right: 9px;
    }
}
