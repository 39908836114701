.mdh-accordion-outer{
    width: 100%;
}
.mdh-accordion{
    position: relative;
    width: 100%;
    background-color: $mdh-green100;
    border: solid 1px $mdh-green-alpha1;
    border-radius: 4px;
    padding: 21px 24px;
    margin-bottom: 15px;
}
.mdh-accordion--top{
    position: relative;
    width: 100%;
    cursor: pointer;
}
.mdh-acc-label{
    font-size: 15px;
    color: $mdh-grey-200;
    margin-bottom: 0;
    font-weight: 400;
}
.mdh-acc-text{
    font-size: 16px;
    color: $mdh-black;
    line-height: 25px;
    font-weight: 400;
}
.mdh-acc-arrow{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 47px;
}
.mdh-acc-arrow img{
    transform: rotate(0deg);
    transition: .3s all;
}
.mdh-acc-arrow--down img{
    transform: rotate(90deg);
}
.mdh-acc-collapse{
    margin-top:20px;

}
.mdh-collapse-data-outer{
    width: 100%;
    padding:26px 0;
    border-top: solid 1px  $mdh-green-alpha5;
}
.mdh-acc-douments{
    width: 100%;
    padding:0;
    margin:0;
    li{
        list-style:none;
        margin-top:3px;
        margin-bottom: 3px;
        a{
            color: $mdh-black;
            font-size: 16px;

        }
    }
}
.mdh-acc-douments a:hover{
    text-decoration: none;
    opacity: .8;
}
.mdh-acc-upload-wrap{
    width:100%;
    margin-top:38px;
}


.mdh-accordion--white{
    background-color: $mdh-white;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.0533571);
    .mdh-acc-text{
        font-size: 20px;
        font-weight: 600;
        position: relative;
        float: left;
        @media( max-width:575.98px){
            font-size: 16px;
        }
        span{
            position: relative;
            font-size: 16px;
            float: right;
            @media(max-width:575.98px){
                float: left;
                display: block;
                font-size: 14px;
                margin-right:10px;
            }
        }
    }
}
.ongoing{
    //color: $mdh-green300;
    .plan-status{
        background-color: $mdh-green300;
        box-shadow: 0px 0px 15px rgba(0, 204, 153, 0.3);
    }
}
.completed{
    color: $mdh-grey;
    .plan-status{
        background-color: #ccc;
        box-shadow: 0px 0px 15px rgba(204, 204, 204, 0.514);
    }
}
.cancelled{
    //color: #E63C3C;
    .plan-status{
        background: #E63C3C;
        box-shadow: 0px 0px 15px rgba(230, 60, 60, 0.3);
    }
}
.rescheduled{
    color: #797D22;
    .plan-status{
        background: #DDE63C;
        box-shadow: 0px 0px 15px rgba(221, 230, 60, 0.775476);
    }
}
.plan-status{
    position: relative;
    width: 16px;
    height: 16px;
    top: 5px;
    border-radius: 50%;
    float: left;
    margin:0 13px;
}
.mdh-accordion--white-valign{
    display: flex;
    align-items: center;
}
.mdh-acc-text--edit{
    font-weight: 400;
    color: $mdh-grey;
    display: flex;
    align-items: center;
    padding-left:10px;
    img{
        margin-right: 16px;
    }
}
.mdh-acc-text--date{
    font-weight: 400;
    color: $mdh-grey;
    display: flex;
    align-items: center;
    img{
        margin-right: 16px;
    }
    .mdh-acc-text--date-head {
        font-size: 13px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0;
        color: #888888;

        .mdh-acc-text--date-head-sub-text {
            font-size: 16px;
            font-weight: 600;
        }
    }
    .mdh-acc-text--date-sub-head {
        font-weight: 400;
        color: #333333;
        font-size: 16px;
        word-break: break-word;
        line-height: 1.2;
        &.event-location{
            word-break: break-all;
        }
    }
}
.mdh-acc-collapse-ln {
    border-top: 1px solid #dedede;
}
.mdh-accordion-outer--nurese-dashboard{
    padding:15px 18px;
    .mdh-accordion{
        padding: 11px 24px 11px 10px;
    }
    .care-assistant-img{
        position: relative;
        width: 37px;
        height: 37px;
        border-radius: 50%;
        background-color: $mdh-green220;
        float: left;
    }
    .care-assistant-name{
        font-weight: 600;
        font-size: 16px;
        color: #333;
        margin: 0 0 0 10px;
    }
    .infographics{
        font-size: 14px;
        color: #333;
        display: flex;
        align-items: center;
        opacity: .6;
        span{
            width: 25px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            background: #F4F9F9;
            border-radius: 3px;
            position: relative;
            margin-right:14px;
            float: left;
        }

    }
    .mdh-acc-arrow{
        min-height: 37px;
    }
}
.mdh-accordion-level2{
    position: relative;
    width: 100%;
    cursor: pointer;
    padding:10px;

    .mdh-accordion-level2--head{
        font-family: Raleway;
        font-weight: 600;
        font-size: 18px;
        color: #333;
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        border-bottom: solid 1px #E0E0E0;
        .handle{
            position: relative;
            width: 16px;
            height: 16px;
            float: left;
            margin-right:10px;
        }
    }
    .handle--plus{
        background: transparent url(/images/global/plus.svg) no-repeat center;
    }
    .handle--minus{
        background: transparent url(/images/global/minus.svg) no-repeat center;
    }
    .dashboard__recent--label{
        padding:16px 16px !important;
    }

}
@media( max-width:575.98px){
    .plan-status{
        margin-left:0px;
        margin-right: 5px;
        width: 10px;
        height: 10px;
        top:8px;
        font-size: 14px;
    }
    .mdh-accordion-level2 .mdh-accordion-level2--head{
        font-size: 16px;
    }
    .mdh-accordion-outer--nurese-dashboard .mdh-accordion{
        padding: 11px 0 11px 11px;
    }
    .mdh-accordion-outer--nurese-dashboard .mdh-acc-arrow{
        padding-right:10px;
    }
    .mdh-accordion-outer--nurese-dashboard .infographics{
        margin-top:8px;
    }
}
.mdh-acc-edit-btn {
    margin-top: 38px;
    float: right
}

.mdh-acc-date {
//    margin-top: 1px;
    margin-bottom: 0px;
    font-size: 16px;
}
