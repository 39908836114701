.mdh-tabs--medical-history .nav-item{
    width:33.33%;
    text-align:center;
}
.info-text{
    padding: 0 10px;
}
.medical-history-head-wrap h6{

    font-size: 16px;
    font-weight: 600;
    color: $mdh-grey;

}
.terms-custom-col{
    display: flex;
    align-items: center;
    min-height: 41px;
    padding-left: 5px !important;
    padding-right: 5px !important;

    .custom-datepicker-wrap {
        margin-left: 10px;
        margin-bottom: 5px;
    }
}
.patient-checkbox {
    margin-right: auto;

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
        top: 5px;
    }
}
.patient-checkbox label{
    margin-bottom: 0px !important;
}
.custom-datepicker-wrap{
    position: relative;
    width:144px;
    margin-left: 30%;
    float: left;
    margin-right: 10px;
    .react-datepicker-popper{
        right:0px !important;
        left: auto !important;
    }
    .react-datepicker__triangle{
        left: auto !important;
        right:50px !important;
    }
}
.other-items{
    margin-bottom: 8px !important;
    margin-top: 21px;
    width: 100%;
}
.custom-col-inline{
    padding-left: 5px !important;
    padding-right: 5px !important;

    h5{
        font-size: 15px;
        color: $mdh-black;
        font-weight: 600;
    }
}
.authorize-box{
    width:100%;
    padding:40px 30px;
    background-color: $mdh-yellow-light;
    border: solid 1px $mdh-yellow;
    p{
        font-size: 16px;
        color: $mdh-grey;
        padding-left: 10px;
    }
}
.authorize-box p:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    top: 8px;
    left: 0;
    background-color: #aab12d;
}
.custom-react-select{
    margin-bottom: 30px;
}
@media (max-width: 767.98px) {
    .mdh-tabs--medical-history .nav-item{
        width: 100% !important;
        padding:0px !important;
    }
    .mdh-tabs--medical-history  .nav-item .nav-link{
        padding: 15px 15px !important;
    }
}
.patient-auth-hippa__canvas{
    border: 1px #d5d5d5 solid;
    margin-right: 10px;
}
.patient-auth-hippa__canvas-wrap{
    display: flex;
    align-items: flex-end;
}
.medical-history-members-accordion-wrap {
    padding: 17px 30px;
}