.assigned-members-table thead tr, .assigned-members-table thead tr th{
    border-top: none !important;
}
.assigned-members-table thead th:last-child{
    text-align: right !important;
}
.assigned-members-table tbody td:last-child{
    color:#888;
    text-align: right !important;
}
.member--image{
    border-radius: 50%;
    margin-right:20px;
}
