.notes-outer-wrapper{
    @extend %position-relative;
    height: 40vh;
    overflow-y: auto;
    margin-bottom: 60px;
}
.notes-outer-wrapper::-webkit-scrollbar {
    width: 9px;
    border-radius: 20px;
}

.notes-outer-wrapper::-webkit-scrollbar-track {
    background: $mdh-grey5;
}

.notes-outer-wrapper::-webkit-scrollbar-thumb {
    background: $mdh-green600;
    border-radius: 100px;
}

.notes-outer-wrapper::-webkit-scrollbar-thumb:hover {
    opacity: .9;
}

.notes-outer-wrapper--grid{
    width:100%;
    margin:0;
    padding:0 15px 0 0;

    li{
        border-radius: 4px;
        padding:21px;
        margin-bottom: 15px;

        p{
            font-weight: normal;
            font-size: 16px;
            line-height: 27px;
            margin-bottom: 10px;
        }
        h6{
            font-size: 16px;
            font-style: italic;
            color: $mdh-grey;
            font-weight: normal;
            margin:0;
            padding:0;

            span{
                color:$mdh-green600;
                margin-right:5px;
            }
        }
    }
}
@media( max-width:575.98px){
    .notes-outer-wrapper--grid{
        padding:0;
    }
}
.grey-color{
    background-color: $mdh-grey5;
}
.green-color{
    background-color: $mdh-green250;
}
.btn-wrapper{
    display: flex;
    justify-content: flex-end;
}
.default-cursor{
    cursor: default !important;
}
.mdh-accordion--top .form-group, .mdh-accordion--top .custom-react-select{
    margin-bottom: 0px;
}
.mdh-accordion--top .form-control{
    min-height: 41px !important;
}
