.badge-warning{
    background-color: $mdh-yellow;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 15px;
    letter-spacing: 0.833333px;
    color:$mdh-yellow-dark;
}
.badge {
    padding: 1.5px 3.5px;
}
