.mdh-pagination{
    padding:0 30px 30px 30px;
}
.mdh-pagination-link {
        margin-right: 10px;
        padding: 4px 9.28px;
        border-radius: 3px;
        background: rgba(19, 120, 120, 0.0960428);
        color: #137878;
        border-color: transparent;
    }
    .page-item.active .mdh-pagination-link,
    .mdh-pagination-link:hover {
        background-color:  #137878;
        color: #ffffff;
        border-color: transparent;
    }
