@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:600,700&display=swap);
body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background-color: #F2F2F2;
  font-display: swap; }

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  -webkit-text-fill-color: #888888;
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 50000s ease-in-out 0s; }

.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 15px;
  padding-left: 15px; }

.form-control:focus {
  color: #888888;
  background-color: #ffffff;
  border-color: #dbf4ee;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 204, 153, 0.25); }

.custom-react-datepicker,
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
  position: relative; }

.react-select__control {
  min-height: 41px !important; }

.react-select__control--is-focused {
  border-color: #dbf4ee !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 204, 153, 0.25) !important; }

.react-select__indicator-separator {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.react-select__menu {
  z-index: 6; }

.react-datepicker-wrapper .react-dob-input {
  background-image: url("/images/Forms/date.svg");
  background-repeat: no-repeat;
  background-position: center right 10px;
  cursor: pointer; }

.react-datepicker__input-container:after {
  position: absolute;
  content: '';
  height: 100%;
  width: 1px;
  background-color: #cccccc;
  top: 0;
  right: 40px; }

.mdh-forms .add-new-link {
  margin-top: 10px; }

.mdh-forms.filled label {
  color: #888888; }

.mdh-forms label {
  margin-bottom: 5px;
  font-size: 15px;
  color: #000000; }
  .mdh-forms label .required {
    background: transparent url(/images/Forms/required.svg) no-repeat;
    position: relative;
    width: 15px;
    height: 15px;
    text-indent: -9999999em;
    position: relative;
    display: inline-block;
    top: 6px; }

.auth-required {
  position: relative;
  position: relative;
  display: inline-block;
  top: 6px;
  font-size: 23px;
  color: red; }

.mdh-forms .form-control {
  min-height: 41px !important; }

.mdh-forms .form-group {
  margin-bottom: 30px; }

.mdh-forms .form-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.mdh-forms .readonly-field {
  font-size: 18px;
  color: #000000;
  word-wrap: break-word; }

@media (max-width: 767.98px) {
  .mdh-forms .form-actions {
    display: block; }
  .mdh-forms .form-actions .btn {
    display: block;
    width: 100%;
    margin-bottom: 15px; } }

.is-valid .react-select__control,
.is-valid .react-dob-input {
  border-color: #28a745; }

.is-invalid .react-select__control,
.is-invalid .react-dob-input {
  border-color: #dc3545; }

.is-valid .react-select__control:hover {
  border-color: #28a745 !important; }

.is-invalid .react-select__control:hover {
  border-color: #dc3545 !important; }

.is-invalid-subscription {
  border-color: #dc3545 !important; }
  .is-invalid-subscription h4,
  .is-invalid-subscription strong,
  .is-invalid-subscription .custom-control-label {
    color: #dc3545 !important; }
  .is-invalid-subscription .qty-input {
    border-color: #dc3545 !important; }

.shared-with-outer {
  position: relative;
  width: 100%;
  float: left;
  height: 316px;
  overflow-y: auto; }

.shared-with-outer::-webkit-scrollbar {
  width: 6px; }

.shared-with-outer::-webkit-scrollbar-track {
  background: #f1f1f1; }

.shared-with-outer::-webkit-scrollbar-thumb {
  background: #2a8080; }

.shared-with-outer::-webkit-scrollbar-thumb:hover {
  background: #00cc99; }

.btn {
  font-size: 16px;
  box-shadow: none !important;
  outline: none;
  transition: all 0.3s;
  padding: 5px 16px;
  border-radius: 3px; }

.btn-sm,
.btn-group-sm > .btn {
  padding: 4px 15px; }

.btn-primary {
  background-color: #00cc99 !important;
  border: 1px solid #00cc99 !important;
  color: #ffffff !important;
  font-weight: 500;
  box-shadow: 0px 5px 20px rgba(0, 204, 153, 0.3) !important;
  padding: 5px 49px; }

.btn-primary:hover,
.btn-primary:active {
  opacity: .8; }

.btn-primary.btn-lg, .btn-outline-primary.btn-lg {
  padding: 6px 40px; }

.import-companies__close-btn {
  padding: 4px 36px; }

.btn-secondary {
  background-color: #E6FAF5 !important;
  border: 1px solid #E6FAF5 !important;
  color: #333333 !important;
  font-weight: 500; }

.btn-secondary:hover,
.btn-secondary:active {
  opacity: .8; }

.btn--add-task {
  background-color: #dde63c !important;
  border: 1px solid #dde63c !important;
  color: #333333 !important;
  font-weight: 500; }

.btn--add-task:hover,
.btn--add-task:active {
  opacity: .8; }

.btn--request-call {
  padding: 5px 15px 5px 35px;
  background-image: url("/images/global/buttons/request-call.svg");
  background-position: 8px center;
  background-repeat: no-repeat;
  background-size: 21px; }

.btn--care-team {
  padding: 5px 15px 5px 35px;
  background-image: url("/images/global/buttons/care-team.svg");
  background-position: 8px center;
  background-repeat: no-repeat;
  background-size: 21px; }

.btn--patient-notes {
  padding: 5px 15px 5px 35px;
  background-image: url("/images/global/buttons/patient-notes.svg");
  background-position: 8px center;
  background-repeat: no-repeat;
  background-size: 21px; }

.btn--nutrition {
  padding: 5px 15px 5px 35px !important;
  background-image: url("/images/global/buttons/nutrition.svg");
  background-position: 8px center;
  background-repeat: no-repeat;
  background-size: 21px; }

.btn-outline-primary {
  background-color: transparent !important;
  border: 1px solid #00cc99 !important;
  color: #00cc99 !important;
  font-weight: 500; }

.btn-outline-danger {
  background-color: transparent !important;
  border: 1px solid #F33333 !important;
  color: #F33333 !important;
  font-weight: 500;
  box-shadow: none !important; }

.btn-outline-primary:hover,
.btn-outline-primary:active {
  opacity: .8; }

.btn-outline-secondary {
  background-color: transparent !important;
  border: 1px solid #137878 !important;
  color: #137878 !important;
  font-weight: 500; }

.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  opacity: .8; }

.btn-icon {
  width: 29px;
  height: 29px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 4px;
  padding: 0 !important;
  margin-left: 6px;
  float: left; }
  .btn-icon img {
    max-width: 70%; }

.btn--remove {
  background-color: rgba(230, 60, 60, 0.1) !important; }

.btn--edit {
  background-color: rgba(19, 120, 120, 0.1) !important; }

.btn-icon-large {
  width: 60px; }

.add-new-link-wrap {
  display: -webkit-flex;
  display: flex; }
  @media (max-width: 575.98px) {
    .add-new-link-wrap {
      -webkit-flex-direction: column;
              flex-direction: column; } }

.add-new-link {
  font-size: 13px;
  line-height: 1.2;
  color: #484a1b !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  margin-left: 10px; }

.add-new-link:hover {
  text-decoration: none;
  opacity: .8; }

.add-new-link i {
  margin-right: 5px;
  float: left; }

.add-new-link i img {
  float: left; }

.btn--in-header {
  padding: 5px 15px 5px 15px; }

@media (max-width: 1399px) and (min-width: 1200px) {
  .btn--request-call span, .btn--care-team span, .btn--patient-notes span {
    display: none; }
  .btn--request-call, .btn--care-team, .btn--patient-notes {
    height: 36px;
    width: 36px;
    padding: 0px 15px 0px 20px; } }

@media (max-width: 1199.98px) {
  .btn--request-call, .btn--care-team, .btn--patient-notes {
    height: 40px;
    width: 40px;
    padding: 0px 15px 0px 20px; }
  .dropdown-toggle-indicator {
    height: 40px; }
  .btn--request-call span, .btn--care-team span, .btn--patient-notes span {
    display: none; }
  .main-header-right li {
    margin-left: 6px !important; } }

.action-icon {
  position: relative;
  width: 34px;
  height: 34px;
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 16px;
  border-radius: 3px;
  background-color: #E8F2F2; }

.action-icon:last-child {
  margin-right: 0px; }

.action-icon--remove {
  background-color: #FCEBEB; }

.btn-primary--download {
  background-color: #DDE63C !important;
  border: 1px solid #DDE63C !important;
  color: #000000 !important;
  font-weight: 500;
  height: 100px;
  width: 100px; }

.chat-item.flex-row-reverse .btn-primary--download {
  float: right; }

.btn-primary--download img {
  position: relative;
  top: 3px; }

.btn-primary:hover,
.btn-primary:active {
  opacity: .8; }

.status-change-btn-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%; }
  @media (max-width: 1199.98px) {
    .status-change-btn-wrap {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-start;
              align-items: flex-start; } }
  .status-change-btn-wrap .btn {
    margin-left: auto;
    padding: 2px 20px; }
    @media (max-width: 1199.98px) {
      .status-change-btn-wrap .btn {
        margin-top: 8px;
        margin-left: 0; } }

.dropdown-menu {
  opacity: 0;
  transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out !important;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out !important;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out !important;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  display: block;
  pointer-events: none;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.0592448); }

.dropdown-menu.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: auto; }

.dropdown-menu--admin-care-team,
.dropdown-menu--admin-care-team.show {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  padding: 8px 21px !important; }
  .dropdown-menu--admin-care-team a,
  .dropdown-menu--admin-care-team.show a {
    color: #333333;
    font-size: 13px;
    line-height: 35px; }
    .dropdown-menu--admin-care-team a:hover,
    .dropdown-menu--admin-care-team.show a:hover {
      text-decoration: none; }

.dropdown-menu--admin-care-team.dropdown-menu-right {
  right: -20px; }

.dropdown-menu-members:after, .dropdown-menu-members:before {
  right: 35% !important; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background: #00cc99;
  box-shadow: 0px 0px 10px rgba(0, 204, 153, 0.502576);
  top: 3px; }

.custom-control-input {
  width: 20px !important;
  height: 20px !important;
  top: 0px !important;
  left: 0 !important;
  z-index: 99999 !important; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff; }

.custom-control {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.custom-control-label {
  padding-left: 6px; }

.custom-control-label::before {
  width: 20px;
  height: 20px;
  top: -2px; }

.custom-control-label::after {
  top: 3.4px;
  left: -19px;
  width: 10px;
  height: 10px; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: #00cc99;
  border-radius: 50%; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #C5C5C5;
  background-color: #ffffff; }

.custom-control-wrapper {
  position: relative;
  width: 100%;
  float: left;
  height: 38px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 20px;
  margin-left: 24px; }
  .custom-control-wrapper .custom-control {
    padding-left: 0px;
    display: inline;
    margin-right: 60px; }

.custom-checkbox--hipaa-agree .custom-control-label::after {
  top: 3.4px !important;
  left: -19.9px !important; }

.custom-checkbox--terms-agree .custom-control-label::after {
  top: 3.4px !important;
  left: -18.9px !important; }

.custom-react-select--admin .react-select__indicators {
  background-image: url(/images/Forms/date.svg);
  background-repeat: no-repeat;
  background-position: center right 10px;
  cursor: pointer; }

.custom-react-select--admin .react-select__indicators svg {
  display: none; }

.custom-react-select--admin .react-select__indicators .react-select__indicator-separator {
  margin-right: 23px; }

.menu {
  border: 1px solid #ced4da;
  border-radius: 2px;
  padding: 10px; }
  .menu item {
    padding: 5px; }

.item-highlighted {
  background-color: #E6FAF5;
  color: #137878; }

a {
  transition: all .4s; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

body a {
  transition: all 0.3s; }

.mdh-modal {
  margin-top: 41px;
  max-width: 568px; }

.mdh-modal .modal-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #4E5E6D;
  padding: 8px 0 25px 0; }

.mdh-modal--header {
  padding: 0;
  display: -webkit-flex;
  display: flex; }

.mdh-modal--header .close {
  font-size: 35px;
  font-weight: 400;
  line-height: 1;
  color: #4E5E6D;
  padding: 0;
  margin: 0;
  outline: none !important; }

.mdh-modal .modal-content {
  padding: 15px 26px; }

.request-a-call-modal-head {
  text-align: center;
  padding: 27px;
  border-bottom: 1px solid #e0e0e0; }

.request-a-call-modal-title {
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #000000; }

.requestcall-modal__reasonfrocall-block {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-right: 0; }

.requestcall-modal__available-timeslot-wrapper {
  width: 100%;
  border-bottom: 1px solid #dedede;
  padding-top: 2.5px; }

.requestcall-modal__availability-timeslot {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
  margin-bottom: 21px;
  z-index: 1;
  background-color: #ffffff; }

.requestcall-modal__availability-timeslot--item {
  font-size: 14px;
  background-color: #f7f7f7;
  padding: 0;
  min-height: 120px;
  margin: 0 auto;
  margin-left: 0px;
  border-right: 4px solid #ffffff;
  margin-bottom: 4px; }

.requestcall-modal__availability-timeslot--item-text {
  font-size: 15px !important;
  font-weight: normal;
  color: #333333;
  text-align: center;
  margin-bottom: 0;
  margin-top: 10px; }

.requestcall-modal__availability-timeslot--item-block {
  height: 120px;
  padding-left: 0; }

.requestcall-modal__availability-timeslot--item-block-label {
  width: 100%;
  height: 120px;
  text-align: center; }

.requestcall-modal__availability-timeslot--item-block-label img {
  margin-top: 23px; }

.requestcall-modal__reasonfrocall-item {
  padding: 2px 25px; }

.requestcall-modal__emergency-note {
  font-size: 12px;
  margin: 0;
  color: #cd0000; }

.requestcall-modal__emergency-card {
  background-color: #f3e5e7 !important;
  border-color: #f3e5e7 !important;
  width: 100%;
  padding: 10px;
  margin-bottom: 32px; }

.requestcall-modal__reasonforcall {
  padding-top: 25px;
  padding-bottom: 27px; }

.requestcall-modal__reasonforcall-title {
  font-size: 17px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  margin: 0;
  color: #000000; }

.requestcall-modal__btn {
  padding: 6px 37px !important; }

.requestcall-modal__additional-notes {
  border-radius: 4px;
  border-color: #cdcdcd;
  color: #5a5a5a; }

.requestcall-modal__availability-timeslot--item .custom-checkbox .custom-control-input:checked ~ label.requestcall-modal__availability-timeslot--item-block-label::after {
  background-image: none;
  content: "";
  background-color: #00cc99;
  border-color: #00cc99;
  border: 4px solid #ffffff;
  top: 8px !important;
  left: 8px !important; }

.requestcall-modal__availability-timeslot--item .custom-control-label::after {
  position: absolute;
  top: 5px;
  left: -23px;
  display: block;
  width: 15px;
  height: 15px;
  content: ""; }

.requestcall-modal__availability-timeslot--item .custom-control-label::before {
  position: absolute;
  top: 7px;
  left: 7px;
  display: block;
  width: 18px;
  height: 18px;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #BEC5C3 solid 1px; }

label.requestcall-modal__availability-timeslot--item-block-label::before {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

.requestcall-modal__availability-timeslot--item .custom-checkbox .custom-control-label::before {
  border-radius: 0 !important; }

.requestcall-modal__availability-timeslot--item .custom-control-input:checked ~ label.requestcall-modal__availability-timeslot--item-block-label::before {
  border-color: #333333;
  background-color: #ffffff; }

.requestcall-modal__availability-timeslot--item .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: transparent;
  border-color: #333333; }

.custom-control-input:not(:disabled):focus ~ .custom-control-label::before {
  outline-color: transparent; }

.requestcall-modal__reasonfrocall-block .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #333333 !important;
  background-color: transparent !important; }

.requestcall-modal__reasonfrocall-block .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none !important;
  background-color: #00cc99;
  border-radius: 50%;
  border: 3px solid #ffffff; }

.requestcall-modal__reasonfrocall-block .custom-control-label::after {
  top: 1px !important;
  left: -21px !important;
  width: 14px !important;
  height: 14px !important; }

/* Care Team Modal */
.mdh-modal--care-team {
  max-width: 744px !important; }
  .mdh-modal--care-team .request-a-call-modal-head {
    padding: 21px; }
  .mdh-modal--care-team .careteam-modal__team-wrapper {
    padding: 0;
    margin-bottom: 43px; }
  .mdh-modal--care-team .careteam-modal__team-item {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    border-bottom: 1px solid #dedede;
    padding: 19px 3px; }
  .mdh-modal--care-team .careteam-modal__team-item--image {
    border-radius: 50%; }
  .mdh-modal--care-team .careteam-modal__team-item--text {
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    padding-left: 20px; }

/* Patient Notes Modal */
.mdh-modal--patient-notes {
  max-width: 744px !important; }
  .mdh-modal--patient-notes .notes-outer-wrapper {
    margin-bottom: 0px;
    height: 55vh; }
  .mdh-modal--patient-notes .request-a-call-modal-head {
    padding: 21px; }
  .mdh-modal--patient-notes .patientnote-modal__notes-message--footer {
    width: 100%;
    float: left; }
    .mdh-modal--patient-notes .patientnote-modal__notes-message--footer .form-group {
      margin-bottom: 0px; }
    .mdh-modal--patient-notes .patientnote-modal__notes-message--footer .btn {
      padding: 6px 30px; }
  .mdh-modal--patient-notes .modal-footer {
    border-top: none; }

.mdh-modal--patient-notes .notes-outer-wrapper::-webkit-scrollbar {
  width: 10px; }

.mdh-modal--patient-notes .notes-outer-wrapper::-webkit-scrollbar-thumb {
  background-color: #b7d6d1;
  border-radius: 50px; }

.mdh-modal--patient-notes .notes-outer-wrapper::-webkit-scrollbar-track {
  background-color: #fafafa; }

.mdh-modal--add-new-medicine {
  max-width: 834px !important; }
  .mdh-modal--add-new-medicine .form-group {
    margin-bottom: 18px; }

.mdh-modal--add-new-medicine .modal-body {
  padding: 10px 1px; }

.mdh-modal--nurse-message-center {
  max-width: 945px !important; }

/* Patient Sign-up Modal */
.mdh-modal--patient-auth-hippa {
  max-width: 735px; }
  .mdh-modal--patient-auth-hippa .auth-wrap-actions {
    margin-top: 50px;
    margin-bottom: 16px !important; }

.patient-auth-hippa__discription {
  max-height: 260px;
  overflow-y: scroll;
  margin-top: 3px;
  padding-right: 30px; }
  .patient-auth-hippa__discription p {
    font-size: 16px;
    line-height: 30px; }

.mdh-modal--patient-auth-hippa .patient-auth-hippa__discription::-webkit-scrollbar {
  width: 10px; }

.mdh-modal--patient-auth-hippa .patient-auth-hippa__discription::-webkit-scrollbar-thumb {
  background-color: #b7d6d1;
  border-radius: 50px; }

.mdh-modal--patient-auth-hippa .patient-auth-hippa__discription::-webkit-scrollbar-track {
  background-color: #fafafa; }

.patient-auth-hippa__canvas-wrap {
  display: none;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 0px;
  margin-bottom: 0px; }

.patient-auth-hippa__canvas {
  margin: 0 auto;
  border-radius: 5px;
  border: 1px solid #ffffff; }

.patient-auth-hippa__canvas-note {
  font-size: 15px;
  color: #9d9d9d;
  margin-bottom: 0;
  text-align: center; }

.patient-auth-hippa__canvas-agreement {
  padding-top: 35px;
  padding-bottom: 0; }

@media (max-width: 575.98px) {
  .patient-auth-hippa__canvas {
    width: 240px !important; }
  .patient-auth-hippa__canvas-wrap::before {
    left: 27px;
    top: 283px;
    font-size: 13px; }
  .patient-auth-hippa__canvas-wrap::after {
    border: none; }
  .mdh-modal .modal-content {
    padding: 15px 6px; } }

.mdh-modal--patient-auth-terms {
  max-width: 735px; }

.patient-auth-terms__discription {
  max-height: 568px;
  margin-top: 6px;
  padding-right: 30px;
  overflow-y: scroll; }
  .patient-auth-terms__discription p {
    font-size: 16px;
    line-height: 30px; }

.mdh-modal--patient-auth-terms .patient-auth-terms__discription::-webkit-scrollbar {
  width: 10px; }

.mdh-modal--patient-auth-terms .patient-auth-terms__discription::-webkit-scrollbar-thumb {
  background-color: #b7d6d1;
  border-radius: 50px; }

.mdh-modal--patient-auth-terms .patient-auth-terms__discription::-webkit-scrollbar-track {
  background-color: #fafafa; }

/* HIPAA Agreement Confirm Modal */
.mdh-modal--patient-auth-hippa-confirm {
  max-width: 535px;
  margin-top: 200px; }

.patient-auth-hippa__confirm-btn-group .btn-outline-secondary {
  border: none !important; }

/* Tele Call Proxy Modal */
.mdh-modal--tele-call-proxy {
  max-width: 480px !important; }
  .mdh-modal--tele-call-proxy .tele-call-data {
    position: relative;
    width: 100%;
    height: 20vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .mdh-modal--tele-call-proxy .tele-call-data .top-section {
      position: relative;
      width: 100%;
      float: left; }
    .mdh-modal--tele-call-proxy .tele-call-data .loader-section {
      position: relative;
      width: 100%;
      float: left; }
    .mdh-modal--tele-call-proxy .tele-call-data .tele-error {
      position: relative;
      width: 100%;
      font-size: 18px;
      text-align: center;
      color: #ff0000; }

.loader-section {
  position: relative;
  width: 100%;
  float: left;
  text-align: center; }

/* Call Center Profile Modal */
.mdh-modal--call-center-details {
  max-width: 744px !important; }
  .mdh-modal--call-center-details .notes-outer-wrapper {
    margin-bottom: 0px;
    height: 55vh; }
  .mdh-modal--call-center-details .patientnote-modal__notes-message--footer {
    width: 100%;
    float: left; }
    .mdh-modal--call-center-details .patientnote-modal__notes-message--footer .form-group {
      margin-bottom: 0px; }
    .mdh-modal--call-center-details .patientnote-modal__notes-message--footer .btn {
      padding: 6px 40px !important;
      float: right; }
  .mdh-modal--call-center-details .modal-footer {
    border-top: none; }
  .mdh-modal--call-center-details .notes-outer-wrapper::-webkit-scrollbar {
    width: 9px; }
  .mdh-modal--call-center-details .notes-outer-wrapper::-webkit-scrollbar-thumb {
    background-color: #b7d6d1;
    border-radius: 50px; }
  .mdh-modal--call-center-details .notes-outer-wrapper::-webkit-scrollbar-track {
    background-color: #fafafa; }
  .mdh-modal--call-center-details .call-notes--textarea {
    height: 150px; }
  .mdh-modal--call-center-details .call-notes-save--wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse; }

/* Nurse Care Plan Modal */
.mdh-modal--add-care-plan {
  max-width: 753px; }
  .mdh-modal--add-care-plan .modal-footer {
    border: none;
    padding-top: 0; }
    .mdh-modal--add-care-plan .modal-footer .btn-primary {
      padding: 7.5px 49px; }

.care-plan__modal--head {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  color: #333333; }
  .care-plan__modal--head .care-plan__modal--head-text {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #333333; }
  .care-plan__modal--head .care-plan__modal--head-pagination {
    width: 52px;
    height: 52px;
    background-color: rgba(0, 204, 153, 0.15);
    float: right;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 50%; }

.care-plan__modal-form {
  height: 350px;
  width: 100%;
  padding-right: 25px;
  overflow-x: hidden; }
  .care-plan__modal-form .care-plan__modal-care-control {
    background-color: rgba(19, 120, 120, 0.05);
    padding: 15px 0px 15px 28px;
    width: 100%;
    border-radius: 4px;
    margin-left: 0;
    margin-right: 0px; }
    .care-plan__modal-form .care-plan__modal-care-control label {
      margin-left: 12px;
      width: 100%;
      margin-bottom: 0; }
  .care-plan__modal-form .modal-footer a:hover {
    text-decoration: none !important; }

.care-plan__modal-form::-webkit-scrollbar {
  width: 10px !important; }

.care-plan__modal-form::-webkit-scrollbar-thumb {
  background-color: #b7d6d1 !important;
  border-radius: 50px !important; }

.care-plan__modal-form::-webkit-scrollbar-track {
  background-color: #fafafa !important; }

.mdh-modal--add-new-company {
  max-width: 1105px !important; }

.mdh-modal--import-companies {
  max-width: 970px !important; }
  .mdh-modal--import-companies .import-companies-modal__custom-input-wrap .custom-control-label {
    padding: 12px 10px !important; }
  .mdh-modal--import-companies .import-companies-modal__custom-input-wrap .custom-control-label::after {
    top: 10px !important;
    left: 5px; }
  .mdh-modal--import-companies .import-companies-modal__custom-input-wrap .custom-control-label::before {
    top: 5px !important;
    left: 0; }
  .mdh-modal--import-companies .mdh-forms label {
    margin-bottom: 0;
    margin-top: 5px; }

.import-companies-modal__table-wrap::-webkit-scrollbar {
  width: 10px !important; }

.import-companies-modal__table-wrap::-webkit-scrollbar-thumb {
  background-color: #b7d6d1 !important;
  border-radius: 50px !important; }

.import-companies-modal__table-wrap::-webkit-scrollbar-track {
  background-color: #fafafa !important; }

.mdh-modal--import-companies-success {
  max-width: 745px !important; }

.mdh-modal--patient-information {
  max-width: 753px !important; }
  .mdh-modal--patient-information .mdh-forms [class*='col-'] {
    padding-left: 0px;
    padding-right: 0px; }
  .mdh-modal--patient-information .patient-notification--textarea {
    height: 203px; }

.mdh-modal--notice {
  max-width: 753px !important; }
  .mdh-modal--notice .notice--textarea {
    height: 203px; }

.care-plan__modal-care-control.active {
  background: rgba(0, 204, 153, 0.1); }

.care-plan__modal-form-sub-text {
  font-size: 14px;
  color: rgba(136, 136, 136, 0.8);
  margin-bottom: 0; }

.care-plan__modal--head-sub-text {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  padding-bottom: 4px;
  margin-bottom: 0;
  border-bottom: 1px solid #E0E0E0; }

.care-plan__modal-medical-diagnosis-form {
  margin-bottom: 38px; }

.edit-medicine-modal__custom-input-wrap {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 12px; }
  .edit-medicine-modal__custom-input-wrap .custom-control-label::before {
    top: 0; }

/* Add Task Modal */
.mdh-modal--add-task {
  max-width: 744px !important; }
  .mdh-modal--add-task .notice--textarea {
    height: 200px; }

/* Bulk Assign Care Team Modal */
.mdh-modal--select-bulk-care-team {
  max-width: 1301px; }

@media (max-width: 1529.98px) {
  .mdh-modal--select-bulk-care-team {
    max-width: 1024px !important; } }

@media (max-width: 991.98px) {
  .mdh-modal--select-bulk-care-team {
    max-width: 700px !important; } }

@media (max-width: 767.98px) {
  .mdh-modal--select-bulk-care-team {
    max-width: 576px !important; } }

/* Add Members Modal */
.mdh-modal--add-members {
  max-width: 1055px; }
  .mdh-modal--add-members .add-members__btn {
    box-shadow: none !important; }
    .mdh-modal--add-members .add-members__btn:hover, .mdh-modal--add-members .add-members__btn:focus {
      background-color: #00cc99 !important;
      border: 1px solid #00cc99 !important;
      color: #ffffff !important; }
  .mdh-modal--add-members .add-members__btn.active {
    background-color: #00cc99 !important;
    border: 1px solid #00cc99 !important;
    color: #ffffff !important; }
  .mdh-modal--add-members .table-row-active {
    background-color: #f0fcf9 !important; }

/* change user modal */
.mdh-modal--change-account {
  max-width: 534px !important; }
  .mdh-modal--change-account .change-account-name {
    font-size: 16px;
    font-weight: 600;
    color: #137878; }

.mdh-modal--manage-dependent {
  max-width: 800px;
  min-height: 315px; }

/* Add New Care Plan Modal */
@media (max-width: 575.98px) {
  .mdh-modal--add-care-plan .modal-content {
    padding: 15px 12px !important; } }

.mdh-modal--add-care-plan.big {
  max-width: 922px; }
  .mdh-modal--add-care-plan.big .care-plan__modal-filter-wrap {
    margin-bottom: 31px !important; }
  .mdh-modal--add-care-plan.big .care-plan__modal-btn-wrap {
    margin-bottom: 18px !important; }
  .mdh-modal--add-care-plan.big .boxed-custom-input-wrap {
    padding: 9px 15px;
    margin-bottom: 6px !important; }
    @media (max-width: 575.98px) {
      .mdh-modal--add-care-plan.big .boxed-custom-input-wrap {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start; }
        .mdh-modal--add-care-plan.big .boxed-custom-input-wrap.long {
          margin-bottom: 30px !important; }
        .mdh-modal--add-care-plan.big .boxed-custom-input-wrap .task-number {
          margin-left: 0;
          margin-top: 8px; } }
    .mdh-modal--add-care-plan.big .boxed-custom-input-wrap.large {
      margin-bottom: 18px !important; }
  @media (max-width: 575.98px) {
    .mdh-modal--add-care-plan.big .modal-footer {
      -webkit-flex-direction: column;
              flex-direction: column; }
      .mdh-modal--add-care-plan.big .modal-footer .btn {
        width: 100%; } }

.mdh-modal--add-care-plan.add-goals {
  max-width: 585px; }
  .mdh-modal--add-care-plan.add-goals .care-plan__modal-body-wrap {
    padding: 11px 0px 15px !important; }
    .mdh-modal--add-care-plan.add-goals .care-plan__modal-body-wrap .care-plan__modal-btn-wrap {
      margin-top: 50px; }
  .mdh-modal--add-care-plan.add-goals .care-plan__modal--head {
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 14px; }
    .mdh-modal--add-care-plan.add-goals .care-plan__modal--head .care-plan__modal--head-text {
      margin-bottom: 7px !important; }
  .mdh-modal--add-care-plan.add-goals .care-plan-input-formgroup {
    margin-bottom: 24px; }
  .mdh-modal--add-care-plan.add-goals .care-plan__modal-filter-wrap {
    margin-top: 4px;
    height: unset !important;
    max-height: 240px;
    margin-bottom: 0 !important;
    padding-right: 0 !important; }
    .mdh-modal--add-care-plan.add-goals .care-plan__modal-filter-wrap .boxed-custom-input-wrap.lightblue {
      padding: 14px 7px;
      margin-bottom: 10px !important; }
      @media (max-width: 575.98px) {
        .mdh-modal--add-care-plan.add-goals .care-plan__modal-filter-wrap .boxed-custom-input-wrap.lightblue {
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-align-items: flex-start;
                  align-items: flex-start; } }
  .mdh-modal--add-care-plan.add-goals .icon-btn-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    white-space: nowrap; }
    .mdh-modal--add-care-plan.add-goals .icon-btn-wrap p {
      margin-right: 12px; }
      @media (max-width: 575.98px) {
        .mdh-modal--add-care-plan.add-goals .icon-btn-wrap p {
          margin-right: auto; } }
    @media (max-width: 575.98px) {
      .mdh-modal--add-care-plan.add-goals .icon-btn-wrap {
        width: 100%; } }
  .mdh-modal--add-care-plan.add-goals .bold {
    font-weight: 600; }
  .mdh-modal--add-care-plan.add-goals .create-care-plan-btn-wrap {
    margin-top: 9px;
    margin-bottom: 17px; }

.mdh-modal--add-care-plan .modal-content {
  padding-right: 23px; }

.mdh-modal--add-care-plan .modal-title {
  padding-bottom: 22px; }

.mdh-modal--add-care-plan .care-plan__modal-body-wrap {
  padding: 22px 0px 15px !important; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal--head .care-plan__modal--head-text {
    margin-bottom: 15px; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal--head .care-plan__modal--head-text.right {
    font-size: 18px;
    font-weight: 400;
    color: #333333; }
    .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal--head .care-plan__modal--head-text.right.shaded {
      color: #888888; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal-body--input {
    width: 95%;
    margin-left: 28px;
    min-height: 41px; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .main-custom-radio {
    padding-left: 0;
    margin-bottom: 10px; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal-filter-wrap {
    float: left;
    width: 100%;
    height: 240px;
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 35px; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal-filter-wrap.box {
    height: 300px; }
    @media (max-width: 575.98px) {
      .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal-filter-wrap.box {
        height: 260px;
        margin-bottom: 15px !important; } }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal-filter-wrap.create-care-plan {
    height: 600px !important;
    overflow-y: auto; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .custom-control-label::before {
    top: 1px; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .custom-control-label::after {
    top: 6.4px; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    top: 6px; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .create-care-plan {
    height: unset !important;
    overflow-y: unset; }
    .mdh-modal--add-care-plan .care-plan__modal-body-wrap .create-care-plan .custom-control-label::before {
      top: 4px; }
    .mdh-modal--add-care-plan .care-plan__modal-body-wrap .create-care-plan .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
      top: 9px; }
    .mdh-modal--add-care-plan .care-plan__modal-body-wrap .create-care-plan .react-datepicker-wrapper .react-dob-input {
      height: 40px; }
    .mdh-modal--add-care-plan .care-plan__modal-body-wrap .create-care-plan .react-datepicker-popper {
      left: -64px !important; }
      @media (max-width: 767.98px) {
        .mdh-modal--add-care-plan .care-plan__modal-body-wrap .create-care-plan .react-datepicker-popper {
          left: 0 !important; } }
    .mdh-modal--add-care-plan .care-plan__modal-body-wrap .create-care-plan .react-datepicker__triangle {
      left: 82px; }
      @media (max-width: 767.98px) {
        .mdh-modal--add-care-plan .care-plan__modal-body-wrap .create-care-plan .react-datepicker__triangle {
          left: 50px !important; } }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal-filter-divider-line {
    float: left;
    width: 100%;
    height: 1px;
    background-color: #D8D8D8;
    position: relative; }
    .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal-filter-divider-line::before {
      content: "OR";
      position: absolute;
      top: -15px;
      left: 48%;
      text-align: center;
      font-size: 18px;
      padding: 0 10px;
      color: #888888;
      background-color: #ffffff; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .new-empty-care-plan-wrap {
    float: left;
    width: 100%;
    margin-top: 6px; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal-btn-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    margin-bottom: 9px; }
    .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal-btn-wrap .btn {
      min-width: 159px;
      padding: 7px 49px;
      margin-right: 15px; }
      @media (max-width: 575.98px) {
        .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal-btn-wrap .btn {
          min-width: 124px;
          padding: 7px 25px; } }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .select-new-care-plan-wrap {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 25px; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .mediacal-diagnosis-wrap {
    float: left;
    width: 100%;
    position: relative; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .care-plan__modal-body--input.input--group {
    width: 100%;
    margin-left: 0;
    border-radius: 3px !important;
    z-index: 0; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .button--group {
    margin: 3px;
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    padding: 4px 20px 5px 12px;
    border-radius: 4px !important;
    z-index: 1; }
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .form-control.is-invalid, .mdh-modal--add-care-plan .care-plan__modal-body-wrap .was-validated .form-control:invalid,
  .mdh-modal--add-care-plan .care-plan__modal-body-wrap .form-control.is-valid, .mdh-modal--add-care-plan .care-plan__modal-body-wrap .was-validated .form-control:valid {
    background-position: center right calc(2.675em + .1875rem); }

.mdh-modal--add-care-plan .create-care-plan-btn-wrap {
  float: right;
  width: 100%;
  margin-top: 18px; }
  .mdh-modal--add-care-plan .create-care-plan-btn-wrap .btn {
    float: right;
    padding: 7px 16px; }

.care-plan__modal-filter-wrap::-webkit-scrollbar {
  width: 10px; }

.care-plan__modal-filter-wrap::-webkit-scrollbar-thumb {
  background-color: #cfe4e1;
  border-radius: 50px; }

.care-plan__modal-filter-wrap::-webkit-scrollbar-track {
  background-color: #fafafa; }

.boxed-custom-input-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: #f5f5f5;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 9px !important; }
  .boxed-custom-input-wrap .boxed-custom--sub-text {
    font-size: 14px;
    margin-bottom: 0;
    color: #888888;
    line-height: 25px;
    padding-left: 28px; }
  .boxed-custom-input-wrap .boxed-custom-input--text {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 0;
    position: relative; }
    @media (max-width: 575.98px) {
      .boxed-custom-input-wrap .boxed-custom-input--text {
        width: 100%; } }
    .boxed-custom-input-wrap .boxed-custom-input--text img {
      margin-right: 5px; }
  .boxed-custom-input-wrap .custom-input-header-wrap {
    float: left;
    width: 100%;
    margin-bottom: 5px; }
  .boxed-custom-input-wrap .goals-wrap {
    float: left;
    width: 100%;
    padding: 22px 0 11px;
    border-top: 1px solid #E0E0E0; }

.boxed-custom-input-wrap.lightblue {
  background: #eff9f7 !important;
  border-color: #dcf5ef !important; }

.boxed-custom-input-wrap.white {
  background: #f8fdfc !important;
  border-color: #e0ebe9 !important;
  padding: 11px 15px !important; }
  .boxed-custom-input-wrap.white .boxed-custom-input--text {
    font-size: 18px; }
    .boxed-custom-input-wrap.white .boxed-custom-input--text a {
      padding: 0 6px;
      color: #2a8080; }
  .boxed-custom-input-wrap.white .task-number {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 0;
    margin-left: auto; }

.boxed-custom-input-wrap.box {
  padding: 6px;
  padding-left: 15px;
  margin-bottom: 6px !important; }

.boxed-custom-input-wrap.long {
  display: block;
  float: left;
  width: 100%;
  height: 300px;
  overflow-y: auto;
  padding: 21px 15px;
  background: #f5f5f5;
  border: 1px solid #eeeeee; }
  .boxed-custom-input-wrap.long .boxed-custom-input--text {
    padding-left: 22px;
    margin-bottom: 17px;
    line-height: 1.3; }
    .boxed-custom-input-wrap.long .boxed-custom-input--text::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: 16px;
      height: 16px;
      background-image: url("/images/global/tick-green.svg"); }

.boxed-custom-input-wrap.large {
  display: block !important;
  float: left;
  width: 100%;
  padding: 17px 25px !important; }
  .boxed-custom-input-wrap.large.lightblue .custom-control-label {
    color: #333333; }
  .boxed-custom-input-wrap.large.lightblue .custom-react-select {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    margin-bottom: 0; }
    @media (max-width: 575.98px) {
      .boxed-custom-input-wrap.large.lightblue .custom-react-select {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start; }
        .boxed-custom-input-wrap.large.lightblue .custom-react-select .react-datepicker-wrapper {
          margin-top: 6px;
          width: 100% !important; } }
    .boxed-custom-input-wrap.large.lightblue .custom-react-select h4 {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin: 0; }
    .boxed-custom-input-wrap.large.lightblue .custom-react-select .react-datepicker-wrapper {
      width: 58%;
      margin-left: auto; }
  .boxed-custom-input-wrap.large.lightblue .form-group {
    margin-bottom: 12px; }
  .boxed-custom-input-wrap.large .custom-control-label {
    padding-left: 8px;
    font-size: 18px;
    color: #888888; }

/* QuestionnaireModal Styles */
.mdh-modal--questionnaire {
  max-width: 764px; }
  .mdh-modal--questionnaire .questionnaire-wrap {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    border-bottom: 1px solid #E0E0E0; }
    .mdh-modal--questionnaire .questionnaire-wrap.large .custom-control-wrapper {
      height: auto;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-start;
              align-items: flex-start; }
      .mdh-modal--questionnaire .questionnaire-wrap.large .custom-control-wrapper .custom-control {
        margin-bottom: 8px; }
    .mdh-modal--questionnaire .questionnaire-wrap .custom-control-wrapper {
      height: auto;
      min-height: 38px;
      padding-left: 15px;
      margin-bottom: 10px;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .mdh-modal--questionnaire .questionnaire-wrap .custom-control-wrapper .custom-control .custom-control-label::before {
        top: 1px; }
      .mdh-modal--questionnaire .questionnaire-wrap .custom-control-wrapper .custom-control .custom-control-label::after {
        top: 6.4px; }
  .mdh-modal--questionnaire .questionnaire--text {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    position: relative;
    margin-bottom: 14px; }
  .mdh-modal--questionnaire .modal-body {
    float: left;
    width: 100%; }
  .mdh-modal--questionnaire .questionnaire-btn-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    margin-top: 14px; }

.recurrences-settings-wrap {
  float: left;
  width: 100%;
  padding: 30px 30px 15px 30px;
  margin-bottom: 30px;
  border-radius: 4px;
  background-color: rgba(19, 120, 120, 0.05);
  border: 1px solid #81adad; }
  .recurrences-settings-wrap .custom-react-select {
    margin-bottom: 15px; }
  @media (max-width: 575.98px) {
    .recurrences-settings-wrap {
      padding: 20px 12px; }
      .recurrences-settings-wrap .react-date-time-input-wrap .react-datepicker-popper {
        left: -40px !important;
        width: 313px; } }

@media (max-width: 575.98px) {
  .react-date-time-input-wrap .react-datepicker-popper {
    left: -40px !important; } }

@media (max-width: 575.98px) {
  .event-modal--add .react-date-time-input-wrap .react-datepicker-popper {
    left: -22px !important; } }

.mdh-modal--call-center-details .react-datepicker__time-container {
  width: 90px; }
  @media (max-width: 575.98px) {
    .mdh-modal--call-center-details .react-datepicker__time-container {
      width: 70px; } }
  .mdh-modal--call-center-details .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 90px; }
    .mdh-modal--call-center-details .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
      padding: 5px; }
    @media (max-width: 575.98px) {
      .mdh-modal--call-center-details .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width: 70px; } }

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px !important; }

.mdh-inline-calender .react-calendar:before {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  position: absolute;
  top: 51px;
  left: 0;
  z-index: 1; }

.mdh-inline-calender {
  width: 100% !important;
  border: none !important; }

.mdh-inline-calender .react-calendar__navigation {
  border-bottom: solid 1px #e0e0e0; }

.mdh-inline-calender .react-calendar__navigation__label {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000; }
  .mdh-inline-calender .react-calendar__navigation__label:hover {
    background-color: transparent !important; }

.mdh-inline-calender:before {
  top: 44px; }

.mdh-inline-calender .react-calendar__navigation__prev-button, .mdh-inline-calender .react-calendar__navigation__next-button {
  position: relative;
  text-indent: -9999999em; }

.mdh-inline-calender .react-calendar__navigation__prev-button:before {
  position: absolute;
  content: '';
  width: 12px;
  height: 17px;
  top: 13px;
  left: 15px;
  background-image: url("/images/global/in-calender-prev.svg");
  background-repeat: no-repeat; }

.mdh-inline-calender .react-calendar__navigation__next-button:before {
  position: absolute;
  content: '';
  width: 12px;
  height: 17px;
  top: 13px;
  left: 15px;
  background-image: url("/images/global/in-calender-next.svg");
  background-repeat: no-repeat; }

.mdh-inline-calender .react-calendar__month-view__weekdays__weekday {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #848B88;
  mix-blend-mode: normal;
  opacity: 0.6; }

.mdh-inline-calender abbr[data-original-title], .mdh-inline-calender abbr[title] {
  text-decoration: none; }

.mdh-inline-calender .react-calendar__navigation__prev2-button,
.mdh-inline-calender .react-calendar__navigation__next2-button {
  display: none; }

.mdh-inline-calender .react-calendar__tile {
  padding: 3px 0 !important; }

.mdh-inline-calender .react-calendar__tile:enabled:hover,
.mdh-inline-calender .react-calendar__tile:enabled:focus,
.mdh-inline-calender .react-calendar__tile--active {
  background-color: transparent; }

.mdh-inline-calender .react-calendar__tile:enabled:hover abbr,
.mdh-inline-calender .react-calendar__tile:enabled:focus abbr {
  background-color: #DDE63C; }

.mdh-inline-calender .react-calendar__tile abbr {
  height: 38px !important;
  width: 38px !important;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: all .4s; }

.mdh-inline-calender .react-calendar__tile--active abbr {
  height: 38px !important;
  width: 38px !important;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  background-color: #DDE63C;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #000000; }

.mdh-inline-calender .tileClass {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 15px; }

/*Date Picker Dropdown */
.react-datepicker {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.0533571); }

.react-datepicker__header {
  background-color: #ffffff;
  border-color: #dedede; }

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected {
  background-color: #DDE63C !important;
  color: #000000;
  border-radius: 50%; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover {
  background-color: #DDE63C !important;
  color: #000000; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover {
  border-radius: 50% !important; }

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  border-radius: 50% !important; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #ccc !important; }

.react-datepicker {
  border: 1px solid #eaeaea; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #ffffff; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #dadada; }

.mdh-tabs .nav-item .nav-link {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: 600;
  padding: 15px 12px !important;
  cursor: pointer;
  color: #C5C5C5;
  transition: .4s all; }

@media (max-width: 1199.98px) {
  .mdh-tabs .nav-item {
    padding-right: 2px; }
  .mdh-tabs .nav-item .nav-link {
    padding: 15px 4px !important; } }

.mdh-tabs .nav-item .nav-link:first-child {
  margin-left: 0px; }

.mdh-tabs .nav-item.show .nav-link, .mdh-tabs .nav-link.active {
  position: relative;
  border-color: transparent transparent transparent transparent;
  color: #00cc99; }

.mdh-tabs .nav-item.show .nav-link, .mdh-tabs .nav-link.active:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  bottom: -1px;
  left: 0;
  background-color: #00cc99;
  box-shadow: 0px 0px 20px 1px #00cc99; }

.mdh-tabs .nav-link:focus, .mdh-tabs .nav-link:hover {
  border-color: transparent transparent transparent transparent;
  color: #00cc99 !important; }

.tab-content > .active {
  -webkit-animation: fadein 0.3s ease-in-out;
  animation: fadein 0.3s ease-in-out; }

.tab-content > .tab-pane {
  padding: 30px; }

.form-filled .badge {
  display: none !important; }

.navigation-tab-scrollor {
  max-width: 100%;
  height: 55px;
  position: relative;
  overflow: hidden;
  margin-top: 15px;
  border-bottom: 1px solid #dee2e6; }

.navigation-tab-scrollor .mdh-tabs {
  padding-bottom: 2rem;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  margin-top: 0 !important;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap; }

.navigation-tab-scrollor .nav-link.active:after {
  bottom: -1px; }

@media (max-width: 767.98px) {
  .tab-content > .tab-pane {
    padding: 20px; }
  .nav-tabs-scroll {
    max-width: 100%;
    height: 53px;
    position: relative;
    overflow: hidden;
    margin-top: 15px;
    border-bottom: 1px solid #dee2e6; }
  .nav-tabs-scroll .mdh-tabs {
    padding-bottom: 2rem;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    margin-top: 0 !important;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
  .nav-tabs-scroll .nav-link.active:after {
    bottom: 1px; } }

@media (max-width: 565.98px) {
  .tab-content > .tab-pane {
    padding: 14px 10px !important; } }

.rc-tabs-top {
  display: grid;
  -ms-display: grid;
  border-bottom: none; }

.mdh-rc-tab .rc-tabs-bar {
  border-bottom: none; }

.mdh-rc-tab .rc-tabs-tab {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 30px !important;
  cursor: pointer;
  color: #C5C5C5;
  transition: .4s all;
  margin-right: 0; }

.mdh-rc-tab .rc-tabs-ink-bar {
  background-color: transparent; }

.mdh-rc-tab .rc-tabs-ink-bar:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  bottom: 0px;
  left: 0;
  background-color: #00cc99;
  box-shadow: 0px 0px 20px 1px #00cc99; }

.mdh-rc-tab .rc-tabs-tab-prev-icon, .mdh-rc-tab .rc-tabs-tab-next-icon {
  padding: 9px 0; }

.mdh-rc-tab .rc-tabs-tab-prev-icon:before {
  content: '' !important;
  background: transparent url(/images/global/previous.svg) no-repeat;
  width: 9px;
  height: 18px;
  position: absolute;
  top: 14px;
  left: -6px; }

.mdh-rc-tab .rc-tabs-tab-next-icon:before {
  content: '' !important;
  background: transparent url(/images/global/previous.svg) no-repeat;
  width: 9px;
  height: 18px;
  position: absolute;
  top: 14px;
  right: -6px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.mdh-rc-tab .rc-tabs-content .rc-tabs-tabpane {
  overflow: unset; }

.mdh-rc-tab .rc-tabs-inner {
  padding: 30px 30px 60px 30px; }

@media (max-width: 575.98px) {
  .mdh-rc-tab .rc-tabs-inner {
    padding: 20px 0px 20px 0px; }
  .sm-accordion-btn-wrap .btn {
    width: 100%;
    margin: 7px 11px 4px 11px !important;
    padding: 6px 10px; } }

.rc-tabs-tab:hover {
  color: #00cc99 !important; }

.cp-tab-pane .goals-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .cp-tab-pane .goals-wrap .boxed-custom-input-wrap.white {
    padding: 10px 15px !important; }
    @media (max-width: 575.98px) {
      .cp-tab-pane .goals-wrap .boxed-custom-input-wrap.white {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start; }
        .cp-tab-pane .goals-wrap .boxed-custom-input-wrap.white .task-number {
          font-size: 16px;
          margin-left: 0;
          margin-top: 6px; }
        .cp-tab-pane .goals-wrap .boxed-custom-input-wrap.white .boxed-custom-input--text {
          font-size: 15px; } }

.cp-tab-pane .create-care-plan-btn-wrap {
  float: right;
  width: 100%;
  text-align: right;
  margin-top: 16px; }
  @media (max-width: 575.98px) {
    .cp-tab-pane .create-care-plan-btn-wrap .btn {
      width: 100%;
      padding: 6px 10px; } }

.badge-warning {
  background-color: #DDE63C;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 15px;
  letter-spacing: 0.833333px;
  color: #787D21; }

.badge {
  padding: 1.5px 3.5px; }

.mdh-accordion-outer {
  width: 100%; }

.mdh-accordion {
  position: relative;
  width: 100%;
  background-color: #ebfbf7;
  border: solid 1px rgba(19, 120, 120, 0.1);
  border-radius: 4px;
  padding: 21px 24px;
  margin-bottom: 15px; }

.mdh-accordion--top {
  position: relative;
  width: 100%;
  cursor: pointer; }

.mdh-acc-label {
  font-size: 15px;
  color: #888888;
  margin-bottom: 0;
  font-weight: 400; }

.mdh-acc-text {
  font-size: 16px;
  color: #000000;
  line-height: 25px;
  font-weight: 400; }

.mdh-acc-arrow {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  min-height: 47px; }

.mdh-acc-arrow img {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: .3s all; }

.mdh-acc-arrow--down img {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.mdh-acc-collapse {
  margin-top: 20px; }

.mdh-collapse-data-outer {
  width: 100%;
  padding: 26px 0;
  border-top: solid 1px rgba(19, 120, 120, 0.7); }

.mdh-acc-douments {
  width: 100%;
  padding: 0;
  margin: 0; }
  .mdh-acc-douments li {
    list-style: none;
    margin-top: 3px;
    margin-bottom: 3px; }
    .mdh-acc-douments li a {
      color: #000000;
      font-size: 16px; }

.mdh-acc-douments a:hover {
  text-decoration: none;
  opacity: .8; }

.mdh-acc-upload-wrap {
  width: 100%;
  margin-top: 38px; }

.mdh-accordion--white {
  background-color: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.0533571); }
  .mdh-accordion--white .mdh-acc-text {
    font-size: 20px;
    font-weight: 600;
    position: relative;
    float: left; }
    @media (max-width: 575.98px) {
      .mdh-accordion--white .mdh-acc-text {
        font-size: 16px; } }
    .mdh-accordion--white .mdh-acc-text span {
      position: relative;
      font-size: 16px;
      float: right; }
      @media (max-width: 575.98px) {
        .mdh-accordion--white .mdh-acc-text span {
          float: left;
          display: block;
          font-size: 14px;
          margin-right: 10px; } }

.ongoing .plan-status {
  background-color: #00cc99;
  box-shadow: 0px 0px 15px rgba(0, 204, 153, 0.3); }

.completed {
  color: #333333; }
  .completed .plan-status {
    background-color: #ccc;
    box-shadow: 0px 0px 15px rgba(204, 204, 204, 0.514); }

.cancelled .plan-status {
  background: #E63C3C;
  box-shadow: 0px 0px 15px rgba(230, 60, 60, 0.3); }

.rescheduled {
  color: #797D22; }
  .rescheduled .plan-status {
    background: #DDE63C;
    box-shadow: 0px 0px 15px rgba(221, 230, 60, 0.775476); }

.plan-status {
  position: relative;
  width: 16px;
  height: 16px;
  top: 5px;
  border-radius: 50%;
  float: left;
  margin: 0 13px; }

.mdh-accordion--white-valign {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.mdh-acc-text--edit {
  font-weight: 400;
  color: #333333;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 10px; }
  .mdh-acc-text--edit img {
    margin-right: 16px; }

.mdh-acc-text--date {
  font-weight: 400;
  color: #333333;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .mdh-acc-text--date img {
    margin-right: 16px; }
  .mdh-acc-text--date .mdh-acc-text--date-head {
    font-size: 13px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0;
    color: #888888; }
    .mdh-acc-text--date .mdh-acc-text--date-head .mdh-acc-text--date-head-sub-text {
      font-size: 16px;
      font-weight: 600; }
  .mdh-acc-text--date .mdh-acc-text--date-sub-head {
    font-weight: 400;
    color: #333333;
    font-size: 16px;
    word-break: break-word;
    line-height: 1.2; }
    .mdh-acc-text--date .mdh-acc-text--date-sub-head.event-location {
      word-break: break-all; }

.mdh-acc-collapse-ln {
  border-top: 1px solid #dedede; }

.mdh-accordion-outer--nurese-dashboard {
  padding: 15px 18px; }
  .mdh-accordion-outer--nurese-dashboard .mdh-accordion {
    padding: 11px 24px 11px 10px; }
  .mdh-accordion-outer--nurese-dashboard .care-assistant-img {
    position: relative;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #E5F9F4;
    float: left; }
  .mdh-accordion-outer--nurese-dashboard .care-assistant-name {
    font-weight: 600;
    font-size: 16px;
    color: #333;
    margin: 0 0 0 10px; }
  .mdh-accordion-outer--nurese-dashboard .infographics {
    font-size: 14px;
    color: #333;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    opacity: .6; }
    .mdh-accordion-outer--nurese-dashboard .infographics span {
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      background: #F4F9F9;
      border-radius: 3px;
      position: relative;
      margin-right: 14px;
      float: left; }
  .mdh-accordion-outer--nurese-dashboard .mdh-acc-arrow {
    min-height: 37px; }

.mdh-accordion-level2 {
  position: relative;
  width: 100%;
  cursor: pointer;
  padding: 10px; }
  .mdh-accordion-level2 .mdh-accordion-level2--head {
    font-family: Raleway;
    font-weight: 600;
    font-size: 18px;
    color: #333;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 14px;
    border-bottom: solid 1px #E0E0E0; }
    .mdh-accordion-level2 .mdh-accordion-level2--head .handle {
      position: relative;
      width: 16px;
      height: 16px;
      float: left;
      margin-right: 10px; }
  .mdh-accordion-level2 .handle--plus {
    background: transparent url(/images/global/plus.svg) no-repeat center; }
  .mdh-accordion-level2 .handle--minus {
    background: transparent url(/images/global/minus.svg) no-repeat center; }
  .mdh-accordion-level2 .dashboard__recent--label {
    padding: 16px 16px !important; }

@media (max-width: 575.98px) {
  .plan-status {
    margin-left: 0px;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    top: 8px;
    font-size: 14px; }
  .mdh-accordion-level2 .mdh-accordion-level2--head {
    font-size: 16px; }
  .mdh-accordion-outer--nurese-dashboard .mdh-accordion {
    padding: 11px 0 11px 11px; }
  .mdh-accordion-outer--nurese-dashboard .mdh-acc-arrow {
    padding-right: 10px; }
  .mdh-accordion-outer--nurese-dashboard .infographics {
    margin-top: 8px; } }

.mdh-acc-edit-btn {
  margin-top: 38px;
  float: right; }

.mdh-acc-date {
  margin-bottom: 0px;
  font-size: 16px; }

.has-table-dd .table-responsive {
  overflow-x: unset; }

@media (max-width: 1199.98px) {
  .has-table-dd .table-responsive {
    overflow-x: auto; } }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .table-responsive-sm > .table-bordered {
    border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .table-responsive-md > .table-bordered {
    border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .table-responsive-lg > .table-bordered {
    border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .table-responsive-xl > .table-bordered {
    border: 0; } }

.mdh-table {
  margin-bottom: 0px; }

.mdh-table thead tr th {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #333333;
  padding: 16px 0 16px 19px;
  text-align: center;
  border-bottom-width: 1px; }

.mdh-table thead tr th:first-child, .mdh-table thead tr th:last-child {
  text-align: left; }

.mdh-table tbody tr td, .mdh-table tbody tr th {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #888888;
  vertical-align: middle;
  padding: 0px 0  0px 19px;
  height: 100px; }

.mdh-table tbody tr td {
  text-align: center; }
  .mdh-table tbody tr td .btn-icon {
    -webkit-align-items: center;
            align-items: center; }

.mdh-table tbody tr td:last-child {
  text-align: left; }

.mdh-table tbody tr th {
  color: #333333;
  text-align: left; }

.mdh-table .info {
  margin-left: 4px; }

.mdh-table .actions-wrap {
  min-width: 85px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

@media (max-width: 767.98px) {
  .mdh-table thead tr th, .mdh-table tbody tr th, .mdh-table tbody tr td {
    white-space: nowrap; } }

.mdh-table--admin th {
  border-top: none;
  white-space: nowrap;
  text-align: center;
  padding: 18px 13px;
  border-top: 1px solid #dee2e6 !important; }
  .mdh-table--admin th:first-child {
    text-align: left !important; }

.mdh-table--admin tr {
  border-top: 1px solid #dee2e6 !important; }
  .mdh-table--admin tr:first-child {
    border-top: none; }

.mdh-table--admin td {
  padding: 13px 13px;
  color: #888888;
  border: none;
  text-align: center;
  vertical-align: middle; }
  @media (max-width: 991.98px) {
    .mdh-table--admin td {
      white-space: nowrap; } }
  .mdh-table--admin td p {
    margin: 0;
    padding: 0; }
  .mdh-table--admin td h4 {
    margin: 0; }
  .mdh-table--admin td:first-child {
    text-align: left;
    color: #333333; }
    .mdh-table--admin td:first-child span {
      position: relative;
      width: 24px;
      height: 24px;
      background-color: #E7F1F1;
      float: left;
      margin-right: 10px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      border-radius: 50%; }
  .mdh-table--admin td:last-child {
    text-align: center !important; }
  .mdh-table--admin td .company-name {
    font-weight: 600;
    color: #137878;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

.mdh-table--admin .actions-wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  vertical-align: middle;
  -webkit-justify-content: center;
          justify-content: center; }

.mdh-table--admin-upload tbody {
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  display: inline-block; }

.mdh-table--admin-upload td:first-child {
  text-align: left;
  color: #333333; }

.mdh-table--admin-upload tr {
  height: 40px;
  width: 100%;
  display: inline-table; }

.mdh-table--admin-upload td {
  color: #888888;
  border: none;
  text-align: center;
  vertical-align: middle;
  padding: 0; }

.mdh-table--admin-upload td:last-child {
  padding-right: 10px; }

.mdh-table--admin-upload tbody::-webkit-scrollbar {
  width: 10px; }

.mdh-table--admin-upload tbody::-webkit-scrollbar-thumb {
  background-color: #137878;
  border-radius: 50px; }

.mdh-table--admin-upload tbody::-webkit-scrollbar-track {
  background-color: #fafafa; }

@media (max-width: 1199.98px) {
  .mdh-table--admin th, .mdh-table--admin td {
    white-space: nowrap; } }

.mdh-table--nurse.task-table th {
  text-align: left; }
  .mdh-table--nurse.task-table th:last-child {
    text-align: center !important; }

.mdh-table--nurse.task-table td {
  text-align: left; }
  .mdh-table--nurse.task-table td:last-child {
    text-align: center !important; }

.mdh-table--nurse.task-table .assigned-to {
  width: 18%; }
  @media (max-width: 767.98px) {
    .mdh-table--nurse.task-table .assigned-to {
      width: 100%; } }

.mdh-table--nurse.task-table .p-badge {
  float: right;
  margin-left: 8px; }

@media (max-width: 1199.98px) {
  .mdh-table--nurse.task-table .action-icon {
    width: 28px;
    height: 28px; } }

.mdh-table--nurse.task-table .tasks-checkbox--item-strike {
  text-decoration: line-through; }

.mdh-table--nurse.task-table .rain-toggle-drop-menu.action-icon .rain-toggle.action-icon--remove {
  background-color: #FCEBEB !important; }

.mdh-table--nurse.task-table .rain-toggle-drop-menu.action-icon .rain-toggle {
  background-color: #E8F2F2 !important; }

.resources-top-section .user-type-col {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  @media (max-width: 767.98px) {
    .resources-top-section .user-type-col {
      -webkit-justify-content: flex-start;
              justify-content: flex-start; } }

.mdh-table--nurse th {
  border-top: none;
  white-space: nowrap;
  text-align: center;
  padding: 18px 13px;
  border-top: 1px solid #dee2e6 !important; }
  .mdh-table--nurse th:first-child {
    text-align: left !important; }

.mdh-table--nurse tr {
  border-top: 1px solid #dee2e6 !important; }
  .mdh-table--nurse tr:first-child {
    border-top: none; }

.mdh-table--nurse td {
  padding: 13px 13px;
  color: #888888;
  border: none;
  text-align: center;
  vertical-align: middle; }
  .mdh-table--nurse td p {
    margin: 0;
    padding: 0; }
  .mdh-table--nurse td h4 {
    margin: 0; }
  .mdh-table--nurse td:first-child {
    text-align: left;
    color: #333333; }
    .mdh-table--nurse td:first-child span {
      position: relative;
      width: 24px;
      height: 24px;
      background-color: #E7F1F1;
      float: left;
      margin-right: 10px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      border-radius: 50%; }
  .mdh-table--nurse td:last-child {
    text-align: center !important; }
  .mdh-table--nurse td .company-name {
    font-weight: 600;
    color: #137878;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

.mdh-table--nurse .actions-wrap {
  max-width: 135px;
  min-width: 90px;
  text-align: center;
  display: inline-block;
  vertical-align: middle; }

@media (max-width: 1199.98px) {
  .mdh-table--nurse td {
    white-space: nowrap; }
  .bulk-assign-care-team-table tr th, .bulk-assign-care-team-table tr td {
    white-space: nowrap; } }

.solo-icon {
  width: 36px;
  height: 36px;
  background-color: #F5F9F9 !important;
  padding: 0 !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.solo-icon-outer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.add-members-table-wrap,
.bulk-care-team-table-wrap {
  border: 1px solid #dedede;
  box-shadow: 0px 0px 3px 0px #dedede;
  margin-bottom: 25px; }

.start-chat-table thead th:first-child {
  width: 65%; }

.start-chat-table thead th:last-child {
  text-align: center; }

.drop-menu-in-table .task-table .import-companies-modal__custom-input-wrap .custom-checkbox .custom-control-label::before {
  top: -10px !important; }

.drop-menu-in-table .task-table .import-companies-modal__custom-input-wrap .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  top: -5px !important; }

.mdh-table--nurse.task-table .tasks-checkbox--item-strike {
  margin-bottom: 0 !important; }

.notification-table .notification-btn-link {
  color: #46A7FA !important;
  cursor: pointer; }

.custom-react-time {
  margin-bottom: 30px; }

.react-time-select {
  width: 100%; }
  .react-time-select .rc-time-picker-icon {
    background-image: url(/images/Forms/date.svg);
    background-repeat: no-repeat;
    background-position: center right 10px;
    cursor: pointer;
    width: 100%;
    height: 40px;
    float: left;
    position: absolute;
    top: 0;
    left: 0; }
    .react-time-select .rc-time-picker-icon::after {
      position: absolute;
      content: '';
      height: 100%;
      width: 1px;
      background-color: #cccccc;
      top: 0;
      right: 40px; }

.react-time-select .rc-time-picker-input {
  height: 41px;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057; }

.rc-time-picker-panel {
  width: 95% !important;
  outline: none !important; }
  @media (max-width: 767.98px) {
    .rc-time-picker-panel {
      width: 60% !important; } }

.rc-time-picker-panel-inner {
  width: 100%;
  top: 3px;
  min-width: 200px; }

.rc-time-picker-panel-input-wrap {
  padding: 9px 6px; }

.rc-time-picker-panel-select {
  width: 33.33% !important; }

.rc-time-picker-panel-select::-webkit-scrollbar {
  width: 10px; }

.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
  background-color: #b7d6d1;
  border-radius: 50px; }

.rc-time-picker-panel-select::-webkit-scrollbar-track {
  background-color: #fafafa; }

.rc-time-picker-panel-select li {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px; }

.rc-time-picker-clear-icon {
  display: none; }

.rc-time-picker-panel-input {
  font-size: 16px; }

.mdh-reactable th {
  padding: 18px 12px;
  border-bottom: none !important;
  text-align: center; }
  .mdh-reactable th:first-child {
    text-align: left; }

.mdh-reactable td {
  padding: 28px 12px;
  color: #888888;
  vertical-align: middle;
  text-align: center; }
  .mdh-reactable td:first-child {
    text-align: left; }
  .mdh-reactable td .actions-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }

.mdh-reactable .reactable-column-header th {
  font-size: 16px;
  color: #333333; }

.resource-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 16px;
  cursor: default;
  color: #333333; }
  .resource-icon:hover {
    text-decoration: none;
    color: #333333; }

.sortable:after {
  position: relative;
  display: inline-block;
  content: '';
  width: 15px;
  height: 14px;
  top: 1px;
  left: 6px;
  background: transparent url("/images/global/up-and-down.svg") no-repeat; }

@media (max-width: 767.98px) {
  .mdh-reactable th, .mdh-reactable td {
    white-space: nowrap; } }

.rbc-calendar {
  height: calc(100vh - 185px) !important; }

.rbc-toolbar {
  margin-bottom: 0 !important;
  background-color: #f3f8f8;
  padding: 13px 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }
  .rbc-toolbar .rbc-btn-group button {
    margin-right: 8px;
    margin-left: 8px;
    padding: 3px 10px;
    border-radius: 3px !important;
    color: #137878;
    border: solid 1px #137878;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px; }
  .rbc-toolbar .rbc-toolbar-label {
    font-size: 18px;
    text-align: center;
    letter-spacing: 3px;
    color: #137878;
    text-transform: uppercase; }

.rbc-toolbar .rbc-btn-group button:hover,
.rbc-toolbar .rbc-btn-group button:focus,
.rbc-toolbar .rbc-btn-group button:active,
.rbc-toolbar .rbc-btn-group button.rbc-active,
.rbc-toolbar .rbc-btn-group button.rbc-active:hover {
  background-color: #137878;
  box-shadow: none;
  color: #fff;
  font-weight: 400;
  border: solid 1px #137878; }

.rbc-header {
  padding: 20px 3px !important;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #888 !important;
  letter-spacing: 2px; }

.rbc-header + .rbc-header {
  border-left: none !important; }

.rbc-off-range-bg {
  background: #fbfafb; }

.rbc-date-cell {
  padding-right: 20px !important;
  padding-top: 20px; }

.rbc-event {
  border-radius: 0px !important;
  text-align: center !important;
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important; }

.rbc-event-label {
  color: #000000; }

.rbc-today {
  background-color: #fff !important; }

.rbc-current a {
  width: 30px;
  height: 30px;
  background-color: #137878;
  position: relative;
  color: #fff !important;
  font-weight: 400;
  float: right;
  text-align: center;
  line-height: 30px;
  border-radius: 50%; }

.rbc-event--task {
  background-color: #46A7FA !important;
  margin: 0px !important;
  height: 100%;
  min-height: 10px;
  color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.rbc-event--appointment {
  background-color: #00cc99 !important;
  margin: 0px !important;
  height: 100%;
  min-height: 10px;
  color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.mdh-pagination {
  padding: 0 30px 30px 30px; }

.mdh-pagination-link {
  margin-right: 10px;
  padding: 4px 9.28px;
  border-radius: 3px;
  background: rgba(19, 120, 120, 0.0960428);
  color: #137878;
  border-color: transparent; }

.page-item.active .mdh-pagination-link,
.mdh-pagination-link:hover {
  background-color: #137878;
  color: #ffffff;
  border-color: transparent; }

.rain-toggle-drop-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .rain-toggle-drop-menu .rain-toggle {
    width: 36px;
    height: 36px;
    background-color: #F5F9F9 !important;
    padding: 0 !important; }
  .rain-toggle-drop-menu .rain-toggle::after {
    content: "";
    display: none; }
  .rain-toggle-drop-menu .dropdown-menu {
    width: 175px;
    padding: 12px 21px !important;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    top: 50%; }
    .rain-toggle-drop-menu .dropdown-menu::after, .rain-toggle-drop-menu .dropdown-menu::before {
      right: 29%; }
  .rain-toggle-drop-menu .dropdown-menu--rain-drop a {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    line-height: 35px;
    color: #2F2F2F;
    display: block; }
    .rain-toggle-drop-menu .dropdown-menu--rain-drop a:hover, .rain-toggle-drop-menu .dropdown-menu--rain-drop a:focus, .rain-toggle-drop-menu .dropdown-menu--rain-drop a:active {
      text-decoration: none; }
  .rain-toggle-drop-menu .dropdown-menu--task {
    width: 175px;
    padding: 12px 21px !important;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    top: 50%; }
    .rain-toggle-drop-menu .dropdown-menu--task::after, .rain-toggle-drop-menu .dropdown-menu--task::before {
      right: 29%; }
  .rain-toggle-drop-menu .dropdown-menu--rain-drop--task a {
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    line-height: 35px;
    color: #2F2F2F;
    display: block; }
    .rain-toggle-drop-menu .dropdown-menu--rain-drop--task a:hover, .rain-toggle-drop-menu .dropdown-menu--rain-drop--task a:focus, .rain-toggle-drop-menu .dropdown-menu--rain-drop--task a:active {
      text-decoration: none; }

.react-date-range-pick {
  width: 100%; }
  .react-date-range-pick .react-daterange-picker__wrapper {
    border: thin solid #cccccc;
    padding: 8px;
    width: 100%;
    border-radius: 4px; }
  .react-date-range-pick .react-daterange-picker__calendar-button {
    background-image: url(/images/Forms/date.svg);
    background-repeat: no-repeat;
    background-position: center right 0px;
    width: 32px;
    outline: none !important; }
    .react-date-range-pick .react-daterange-picker__calendar-button svg {
      display: none; }
  .react-date-range-pick .react-daterange-picker__clear-button svg {
    display: none;
    outline: none; }
  .react-date-range-pick .react-calendar__tile--hasActive:enabled:hover, .react-date-range-pick .react-calendar__tile--hasActive:enabled:focus {
    background: #00cc99;
    color: #fff;
    border-radius: 4px; }
  .react-date-range-pick .react-calendar__tile--hasActive {
    background: #00cc99;
    color: #fff;
    border-radius: 4px; }
  .react-date-range-pick .react-calendar__tile:enabled:hover, .react-date-range-pick .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
    border-radius: 4px; }

.duo-outer {
  position: relative;
  width: 100%;
  float: left;
  border: none;
  border-style: none;
  min-height: 370px; }

.navbar {
  padding: 17px 26px 17px 16px !important;
  background-color: #ffffff !important; }

.nav-link {
  padding: 0px !important;
  margin-left: 16px; }

.main-header-right li {
  margin-left: 16px; }

.main-header__notif-icon {
  padding: 7px 0; }

.navbar__notification-bell {
  padding: 7px 7px !important; }

.dropdown-toggle-indicator {
  padding: 6px 12px !important; }

.navbar__link-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #dbf4ee;
  color: #000000 !important;
  color: #333333 !important;
  border-radius: 4px;
  font-size: 16px !important; }

.navbar__link-btn:after {
  content: "";
  border: 0;
  background-image: url("/images/global/down-sm.svg");
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 10px;
  margin-left: 8px;
  left: 0;
  top: 0; }

.navbar__notification-bell.navbar__link-btn:after {
  content: "";
  display: none; }

.navbar__link-btn svg path {
  fill: #137878; }

.navbar__link-btn--label {
  font-size: 15px;
  font-weight: 600;
  padding-left: 4px; }

.navbar__notification-dropdown {
  width: 270px !important;
  min-height: 200px !important;
  right: -20px;
  padding: .5rem 0 0 0 !important; }

@media (max-width: 565.98px) {
  .navbar__notification-dropdown {
    right: -148px; }
  .navbar__notification-dropdown:before {
    right: 63% !important; }
  .navbar__notification-dropdown:after {
    right: 63% !important; } }

.navbar__notification-dropdown-item {
  font-size: 10px;
  font-family: "Open Sans", sans-serif;
  white-space: normal !important;
  display: block;
  width: 100%;
  font-weight: 400;
  color: #137878;
  cursor: pointer; }

.navbar__notification-dropdown-item p {
  margin-bottom: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.navbar__notification-dropdown-item span {
  color: #000000; }

.navbar__notification-dropdown-item:hover span {
  color: #464545; }

.navbar__notification-dropdown-item:hover,
.navbar__notification-dropdown-item:focus,
.navbar__notification-dropdown-item:active {
  color: #137878 !important;
  text-decoration: none;
  opacity: .8; }

.navbar__requestcall-btn,
.navbar__requestcall-btn path {
  background-color: #00cc99 !important;
  color: #ffffff !important;
  fill: #ffffff;
  font-size: 14px !important;
  border-radius: 4px; }

.navbar__header-remainder {
  font-size: 15px;
  font-weight: 600;
  display: -webkit-flex;
  display: flex;
  padding-left: 20px; }

.navbar__header-remainder--date {
  color: #2a8080;
  font-weight: 600; }

.navbar__header-remainder--date:hover,
.navbar__header-remainder--date:focus,
.navbar__header-remainder--date:active {
  text-decoration: none;
  color: #00cc99; }

.navbar__header-remainder-ondashboard {
  display: none; }

.navbar__notification-dropdown-link {
  padding: 20px 0 15px 0; }

.navbar__notification-dropdown-link--text {
  font-size: 12px;
  color: #137878;
  margin: 0 auto; }

.navbar__notification-dropdown-link--text:hover,
.navbar__notification-dropdown-link--text:focus,
.navbar__notification-dropdown-link--text:active {
  text-decoration: none;
  color: #00cc99; }

.navbar__notification-dropdown-head {
  border-bottom: 1px solid #E5E7E9; }

.navbar__notification-dropdown-head path {
  fill: #00cc99; }

.navbar__notification-dropdown-head--text {
  padding: 10px 19px;
  font-family: Raleway;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase; }

.navbar__notification-dropdown-messages {
  position: relative;
  padding: 14px 0;
  margin-left: 0 !important;
  border-bottom: solid 1px #E5E7E9; }

.navbar__notification-dropdown-messages--time {
  font-size: 10px;
  color: #4E5E6D;
  padding-bottom: 0px;
  margin-bottom: 0; }
  .navbar__notification-dropdown-messages--time .badge-warning {
    background-color: #DDE63C;
    font-size: 8px;
    font-weight: 400;
    color: #000000;
    text-transform: uppercase;
    border-radius: 1px; }
  .navbar__notification-dropdown-messages--time:hover {
    opacity: .8; }

.navbar__notification-dropdown-messages-area {
  position: relative;
  max-height: 205px;
  width: 86%;
  left: 7%;
  padding: 0;
  margin: 0; }

.navbar__profile-dropdown-item {
  padding: 0 10px !important; }

.navbar__notification-bell {
  position: relative; }

.navbar__profile-dropdown-item--text {
  font-size: 12px;
  padding-left: 10px; }

.navbar__notification-badge {
  padding: 3px;
  background-color: #F33333;
  border-radius: 50%;
  border: 2px solid #dbf4ee;
  position: absolute;
  top: 7px;
  left: 15px; }

.navbar__requestcall-btn-sm {
  display: none; }

.nav-link-sm {
  display: none; }

.dropdown-toggle-indicator-sm {
  display: none; }

.navbar__profile-dropdown-item-list {
  list-style: none;
  padding: 12px 10px;
  display: block; }

.navbar__profile-dropdown-item-list li {
  margin-left: 0px !important;
  padding-bottom: 6px; }

.navbar__profile-dropdown-item-list .nav-link {
  margin-left: 0px; }

.navbar__profile-dropdown-item-list li a {
  font-weight: 400;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  color: #212529 !important; }
  .navbar__profile-dropdown-item-list li a:hover {
    text-decoration: none; }

.dropdown-menu {
  margin: .85rem 0 0 !important; }

.dropdown-menu.show {
  border: solid 1px #D1D1D1;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.0536402); }

.dropdown-menu::before {
  content: "";
  border-top: 10px solid #D1D1D1;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-top-color: #D1D1D1;
  right: 16%;
  position: absolute;
  top: -7px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.dropdown-menu::after {
  content: "";
  border-top: 10px solid #ffffff;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-top-color: #ffffff;
  right: 16%;
  position: absolute;
  top: -6px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.hide-lg {
  display: none !important; }

@media (max-width: 1312.98px) {
  .navbar__header-remainder {
    padding-left: 5px; }
  .nav-item {
    padding-right: 8px; } }

@media (max-width: 1189.98px) {
  .navbar__requestcall-btn {
    padding: 7px 15px !important; }
  .navbar__header-remainder {
    padding-left: 0px;
    font-size: 13px; }
  .nav-item {
    padding-right: 10px; }
  .collapse .navbar__requestcall-btn-sm,
  .collapse .nav-link-sm,
  .collapse .navbar__notification-bell,
  .collapse .navbar__profile-dropdown .nav-link {
    display: block !important; } }

@media (max-width: 575.98px) {
  .navbar__header-remainder {
    display: none; }
  .navbar__header-remainder-ondashboard {
    display: block;
    padding-bottom: 15px;
    padding-left: 0px;
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
    border: 1px dashed #44cc98; } }

@media (max-width: 991.98px) {
  .navbar {
    padding: 17px 7px 17px 16px !important; }
  .dropdown-toggle-indicator {
    padding: 9px 12px !important; }
  .navbar__notification-bell {
    padding: 9px 10px !important; }
  .hide-lg {
    display: block !important; }
  .offcanvas-collapse.open {
    visibility: visible;
    -webkit-transform: translateX(-280px);
    transform: translateX(280px); }
  .offcanvas-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -280px;
    width: 280px;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #ffffff;
    transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out; }
  .navbar__requestcall-btn-sm,
  .dropdown-toggle-indicator-sm {
    display: block !important;
    padding: 10px 12px !important; } }

@media (max-width: 575.98px) {
  .navbar {
    padding: 17px 7px 17px 7px !important; }
  .nav-item {
    padding-right: 9px; } }

.generic-page-header {
  margin-bottom: 7px;
  min-height: 36px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.generic-page-header .back-link {
  white-space: nowrap; }

.generic-page-header.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  background-color: #f2f2f2;
  z-index: 2;
  padding: 10px 0; }
  @media (max-width: 575.98px) {
    .generic-page-header.sticky {
      position: relative;
      position: -webkit-relative;
      top: 0;
      z-index: unset; } }

.page-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.page-head {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.1;
  color: #2F2F2F;
  margin: 8px 0;
  padding-top: 7px;
  padding-bottom: 7px; }

.generic-header--select {
  position: relative;
  float: left;
  margin-right: 10px;
  width: 200px; }
  .generic-header--select .react-select__control {
    min-height: 38px !important; }
  @media (max-width: 767.98px) {
    .generic-header--select {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 20px; } }

.base-container {
  width: 100%;
  float: left; }

.global-content {
  width: calc(100% - 280px);
  position: relative;
  float: left;
  z-index: 99;
  left: 280px; }

.global-datawrap {
  padding-top: 0px;
  position: relative;
  max-width: 100%; }

.global-datawrap__inner {
  padding: 25px 33px; }

a:hover {
  text-decoration: none; }

@media (max-width: 991.98px) {
  .global-content {
    width: calc(100% - 0px);
    left: 0; }
  .global-datawrap__inner {
    padding: 25px 15px; } }

@media (max-width: 767.98px) {
  .generic-page-header .btn-outline-secondary {
    margin-bottom: 10px; }
  .generic-page-header .btn-primary {
    display: block;
    width: 100%; }
  .generic-page-header.events-header {
    display: block; } }

@media (max-width: 575.98px) {
  .generic-page-header.sticky {
    display: -webkit-flex;
    display: flex; }
  .generic-page-header {
    display: block; }
    .generic-page-header .btn-outline-secondary {
      margin-bottom: 10px;
      width: 100%; }
    .generic-page-header .btn-primary {
      display: block;
      width: 100%; }
    .generic-page-header .btn--add-task {
      display: block;
      width: 100%;
      margin-bottom: 8px; } }

.badge {
  border-radius: 0px;
  position: relative;
  top: -1px; }

.badge-warning {
  background-color: #DDE63C; }

.mdh-sm-box {
  padding: 5px;
  background-color: #DDE63C; }

.section-heading {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  font-family: "Raleway", sans-serif;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: solid 1px #C5C5C5; }

.global-settings-wrap {
  min-height: 400px; }
  .global-settings-wrap .global-settings {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 8px; }
  .global-settings-wrap .mdh-accordion-level2 {
    cursor: default; }
  .global-settings-wrap .task-data--label {
    font-size: 13px;
    color: #000000;
    padding-bottom: 0px;
    margin-bottom: 0; }
  .global-settings-wrap .dashboard__recent--label, .global-settings-wrap form {
    width: 94%; }
    @media (max-width: 767.98px) {
      .global-settings-wrap .dashboard__recent--label, .global-settings-wrap form {
        width: 90%; } }
    @media (max-width: 575.98px) {
      .global-settings-wrap .dashboard__recent--label, .global-settings-wrap form {
        width: 86%; } }
  .global-settings-wrap .mdh-accordion-level2 .dashboard__recent--label {
    padding: 12px !important; }
  .global-settings-wrap .mdh-accordion-level2 .mdh-accordion-level2--head {
    padding-bottom: 9px;
    margin-bottom: 19px; }
  .global-settings-wrap .square-btn {
    width: 6%;
    float: left;
    padding: 2px;
    line-height: 1;
    margin-left: 10px;
    border: 1px solid #eeeeee !important;
    border-radius: 3px;
    background-color: transparent !important;
    height: 36px; }
    @media (max-width: 767.98px) {
      .global-settings-wrap .square-btn {
        width: 10%; } }
    @media (max-width: 575.98px) {
      .global-settings-wrap .square-btn {
        width: 14%; } }
  .global-settings-wrap .square-btn.remove {
    border-color: #F33333 !important;
    color: #F33333 !important; }
  .global-settings-wrap .square-btn.add {
    border-color: #00cc99 !important;
    background-color: #00cc99 !important;
    color: #ffffff !important; }
  .global-settings-wrap .medical-input-settings {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 8px; }
  .global-settings-wrap .settings-btn-outer {
    width: 6%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    @media (max-width: 991.98px) {
      .global-settings-wrap .settings-btn-outer {
        width: auto !important; } }
    @media (min-width: 1199.98px) and (max-width: 1560px) {
      .global-settings-wrap .settings-btn-outer {
        width: 12%; } }
    .global-settings-wrap .settings-btn-outer .square-btn {
      width: 32px !important;
      height: 38px;
      padding: 2px 6px !important;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .global-settings-wrap .settings-btn-outer .square-btn .close {
        width: 12px; }

.mdh-card--disabled {
  opacity: .5; }

.mdh-card--disabled btn {
  opacity: 1; }

.mdh-card {
  background-color: #ffffff;
  padding: 0;
  border-radius: 4px;
  margin-top: 11px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  transition: height 200ms ease;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.0533571); }

.mdh-card > div {
  -webkit-animation: fadein 0.3s ease-in-out;
  animation: fadein 0.3s ease-in-out; }

.mdh-card__head {
  border-bottom: 2px solid #ededed;
  padding: 20px 25px 10px 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.mdh-card__head--label {
  font-size: 17px;
  font-family: "Raleway", sans-serif;
  color: #333333;
  margin: 0; }
  .mdh-card__head--label i {
    margin-right: 10px;
    top: -2px;
    position: relative; }

.mdh-global-card-light-blue {
  background: rgba(0, 204, 153, 0.0282495);
  border: 1px solid rgba(19, 120, 120, 0.153759);
  box-sizing: border-box;
  border-radius: 5px; }

.mdh-global-card-light-blue-head {
  padding: 20px 25px 22px 25px; }
  .mdh-global-card-light-blue-head h6 {
    font-size: 14px;
    margin-bottom: 0;
    color: #888888;
    line-height: 25px; }
  .mdh-global-card-light-blue-head h4 {
    font-size: 16px;
    margin-bottom: 0;
    color: #000000; }

.mdh-global-card-light-blue-head-Social {
  padding: 17px 25px; }
  .mdh-global-card-light-blue-head-Social h6 {
    font-size: 14px;
    margin-bottom: 0;
    color: #888888; }
  .mdh-global-card-light-blue-head-Social h4 {
    font-size: 16px;
    margin-bottom: 0;
    color: #000000; }

.mdh-global-card-light-blue-head-Social--btn {
  padding-right: 25px; }

.custom-checkbox--fav {
  float: right;
  top: 4px; }
  .custom-checkbox--fav .custom-control-label::before {
    top: 1px; }
  .custom-checkbox--fav .custom-control-label::after {
    top: 6.4px !important;
    left: -19.9px !important; }

@media (max-width: 767.98px) {
  .mdh-modal--select-bulk-care-team .custom-checkbox--fav {
    float: left; } }

/* Signature Pad Style*/
.mdh-signature-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .mdh-signature-wrap .mdh-signature__pad-wrap {
    margin-top: 20px;
    margin-bottom: 40px; }
  .mdh-signature-wrap .mdh-signature__pad {
    background-color: #dffcf5;
    border-radius: 5px;
    padding: 18px 15px;
    width: 250px;
    height: 76px; }
  .mdh-signature-wrap .mdh-signature__pad-text {
    font-size: 18px;
    text-transform: uppercase;
    color: #228180;
    margin-bottom: 0;
    font-weight: 600; }
  .mdh-signature-wrap .mdh-signature__pad-sub-text {
    color: #228180;
    font-size: 14px; }
  .mdh-signature-wrap .mdh-signature__pad-sub-text-link {
    color: #228180;
    font-size: 14px; }
    .mdh-signature-wrap .mdh-signature__pad-sub-text-link:hover {
      text-decoration: none;
      color: #228180; }

.mdh-signature__pad-wrap::after {
  content: "";
  height: 2px;
  width: 100%;
  top: 10px;
  background-color: #828282;
  position: relative;
  display: block; }

.my-patients-tabs .nav-item .nav-link {
  padding: 15px 30px !important; }

.attachments-text {
  color: #137878;
  font-size: 18px;
  cursor: pointer; }

.task-modal-attachment {
  width: 100%;
  margin-bottom: 38px; }

.nurse-task-view-modal--edit {
  width: 150px; }

.nurse-task-view-modal--edit .react-select__control {
  min-height: 36px !important; }

.profile-details__tab-content {
  max-height: calc(100vh - 326px);
  overflow-y: auto;
  padding-right: 10px; }
  @media (max-width: 767.98px) {
    .profile-details__tab-content {
      max-height: calc(100vh - 386px); } }
  @media (max-width: 575.98px) {
    .profile-details__tab-content {
      max-height: calc(100vh - 438px); } }

.tab-content-admin {
  max-height: calc(108vh - 326px) !important; }

.profile-details__tab-content::-webkit-scrollbar {
  width: 10px; }

.profile-details__tab-content::-webkit-scrollbar-thumb {
  background-color: #b7d6d1;
  border-radius: 50px; }

.profile-details__tab-content::-webkit-scrollbar-runnable-track {
  background-color: #fafafa; }

.error-list {
  list-style: none;
  padding: 20px 10px; }
  .error-list li {
    color: #F33333;
    font-size: 17px; }

.global-sidebar {
  width: 280px;
  height: 100vh;
  padding: 0;
  position: fixed;
  z-index: 100;
  background-color: #fff;
  max-height: 100%;
  overflow-y: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }

.global-sidebar::-webkit-scrollbar {
  width: 6px; }

.global-sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; }

.global-sidebar::-webkit-scrollbar-thumb {
  background: #2a8080; }

.global-sidebar::-webkit-scrollbar-thumb:hover {
  background: #00cc99; }

.sidebar-wrapper {
  min-height: 100vh;
  background-color: #FFFFFF;
  width: 100%;
  max-width: 280px;
  padding: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

@media (max-width: 991.98px) {
  .sidebar-wrapper {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); } }

.sidebar {
  list-style: none;
  padding: 10px 23px; }

.sidebar__portal-text {
  position: relative;
  text-align: center;
  color: #00cc99;
  margin-bottom: 0;
  padding: 6px 0 14px 0;
  font-size: 14px; }

.sidebar__portal-text:after {
  content: "";
  height: 2px;
  width: 100%;
  left: 0;
  bottom: -15px;
  background-color: #D8D8D8;
  opacity: .6;
  position: absolute; }

.sidebar__list--logo {
  text-align: center;
  padding: 22px 4px;
  position: relative; }

.sidebar__list {
  position: relative;
  margin-top: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.sidebar__list--text {
  font-size: 15px;
  color: #888888 !important;
  cursor: pointer;
  width: 100%;
  display: block;
  padding: 9px 0;
  transition: all 0.3s; }
  .sidebar__list--text i {
    margin-right: 15px; }

.sidebar__list--text:hover,
.sidebar__list--text:focus,
.sidebar__list--text:active,
.sidebar__list--text:hover path,
.sidebar__list--text:focus path,
.sidebar__list--text:hover rect,
.sidebar__list--text:focus rect,
.sidebar .active,
.sidebar .active path,
.sidebar .active rect {
  text-decoration: none;
  color: #2a8080 !important;
  fill: #00cc99 !important; }

.sidebar__list--text:focus,
.sidebar__list--text:active,
.sidebar .active {
  font-weight: 600; }

.sidebar__footer {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: auto; }

.sidebar__footer-text {
  font-size: 13px;
  color: #919293;
  padding-bottom: 8px; }

.sidebar-wrapper:last-child {
  padding: 0; }

.sidebar__footer--color {
  padding: 4px 0;
  background-color: #00cc99; }

.color-yellow {
  color: #DDE63C; }

.bg-yellow {
  background-color: #DDE63C; }

.color-dark-green {
  color: #137878; }

.bg-dark-green {
  background-color: #137878; }

.hr {
  margin: 20px 0 40px 0; }

.hr-small {
  margin: 7px 0 22px 0; }

.hr-small2 {
  margin: 0px 0 32px 0; }

.p-30 {
  padding: 40px 30px; }

.pt-13 {
  padding-top: 13px !important; }

.px--5 {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.w-15 {
  width: 15% !important; }

.round {
  border-radius: 50%; }

.mt-3-1 {
  margin-top: 17px !important; }

.mb-1-6 {
  margin-bottom: 6px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-40 {
  margin-bottom: 40px; }

.height205 {
  height: 205px !important; }

.bg-admin-green {
  background-color: #137878 !important; }

/***Extended ***/
.dashboard__task-item--label, .dashboard__task-item--sub-label, .notes-outer-wrapper, .messagecenter-box, .contact-list, .chat-box--top, .chat-box-user-details, .chat-box--middle, .chat-box--bottom, .files-shared-wrapper .files-wrapper {
  position: relative;
  width: 100%;
  float: left; }

.contact-list li, .chat-box--top, .chat-box-user-details, .chat-box--bottom .send-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.contact-list .contact-list--img, .contact-list .contact-list--badge, .chat-box--top .contact--img, .chat-box--bottom .attachment-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.light-yellow {
  background-color: #DDE63C !important; }

.dark-yellow {
  background-color: #B9BB98 !important; }

.light-green {
  background-color: #00CC99 !important; }

.dark-green {
  background-color: #979797 !important; }

.remove-bg-color {
  background-color: transparent !important; }

.no-border {
  border: none !important; }

.color-black {
  color: #000000 !important; }

.ft-size-20 {
  font-size: 20px !important; }

/***Extended ***/
.dashboard__task-item--label, .dashboard__task-item--sub-label, .notes-outer-wrapper, .messagecenter-box, .contact-list, .chat-box--top, .chat-box-user-details, .chat-box--middle, .chat-box--bottom, .files-shared-wrapper .files-wrapper {
  position: relative;
  width: 100%;
  float: left; }

.contact-list li, .chat-box--top, .chat-box-user-details, .chat-box--bottom .send-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.contact-list .contact-list--img, .contact-list .contact-list--badge, .chat-box--top .contact--img, .chat-box--bottom .attachment-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.mb-16 {
  margin-bottom: 16px !important; }

.mb-28 {
  margin-bottom: 28px; }

.initial-loader {
  position: relative;
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.initial-loader--inner {
  position: relative;
  width: 100%;
  height: 70vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

@media (max-width: 767.98px) {
  .mtp-15 {
    margin-top: 8px; } }

.resources-top-section .btn-primary {
  padding: 3px 16px;
  width: 100%; }

.fh-auth-wrap {
  min-height: 100vh;
  width: 100%;
  background-color: #dbf4ee;
  padding: 15px;
  -webkit-animation: fadein 0.3s ease-in-out;
  animation: fadein 0.3s ease-in-out; }

.fh-auth-wrap__data {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  width: 956px;
  max-width: 100%;
  position: relative;
  border-radius: 4px;
  -webkit-animation: fadein 0.3s ease-in-out;
  animation: fadein 0.3s ease-in-out; }

.auth-wrap {
  float: left;
  width: 100%; }

.auth-wrap__col--left .auth-wrap__col--left-inner {
  background: transparent url("/images/auth/auth-bg-full-grn.png") no-repeat center;
  background-size: cover;
  height: 100%;
  width: 92%;
  padding: 44px 44px 37px 44px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  position: relative; }

.auth-wrap-left-data {
  width: 100%;
  text-align: left;
  z-index: 11; }
  .auth-wrap-left-data .auth-wrap-left-data__h2 {
    font-size: 28px;
    line-height: 36px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 14px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-weight: 600; }
    .auth-wrap-left-data .auth-wrap-left-data__h2 img {
      margin-right: 12px; }
      @media (max-width: 450px) {
        .auth-wrap-left-data .auth-wrap-left-data__h2 img {
          margin-top: 10px; } }
    @media (max-width: 450px) {
      .auth-wrap-left-data .auth-wrap-left-data__h2 {
        -webkit-align-items: flex-start;
                align-items: flex-start; } }
  .auth-wrap-left-data .auth-wrap-left-data__hr {
    width: 60%;
    height: 3.5px;
    background-color: #DDE63C;
    text-align: left;
    margin: 13px 0; }
  .auth-wrap-left-data .auth-wrap-left-data__h6 {
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
    font-weight: normal; }

.auth-wrap-right-data {
  height: 100%;
  padding: 38px 38px 38px 13px;
  width: 100%; }
  .auth-wrap-right-data .auth-wrap-right-data__logo {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 45px; }

.auth-wrap-right-data__form {
  width: 100%;
  float: left; }

.auth-wrap__form .form-control {
  height: 41px; }

.auth-wrap__form .form-group {
  margin-bottom: 14px; }

.auth-wrap__form label {
  font-size: 15px;
  line-height: 1;
  color: #333333;
  margin-bottom: 8px; }

.auth-wrap__form_input_number {
  /*Chrome, Safari, Edge, Opera */
  /* Firefox */ }
  .auth-wrap__form_input_number input::-webkit-outer-spin-button,
  .auth-wrap__form_input_number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .auth-wrap__form_input_number input[type=number] {
    -moz-appearance: textfield; }

.auth-wrap-right-data__inner {
  width: 100%;
  padding: 18px 0 36px 0;
  float: left; }
  .auth-wrap-right-data__inner .auth-wrap-right-data__subheader {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 1.71429px;
    color: #333333;
    text-transform: uppercase;
    margin-bottom: 34px; }

.auth-wrap-actions {
  margin-top: 29px;
  margin-bottom: 0px !important; }
  .auth-wrap-actions .auth-wrap-actions__have-account {
    margin-top: 6px;
    font-size: 17px;
    color: #333333; }
    .auth-wrap-actions .auth-wrap-actions__have-account .auth-wrap-actions__have-account-link {
      color: #137878; }

.auth-wrap__form--subscription {
  width: 100%;
  border: solid 1px #D6D6D6;
  background-color: #FCFCFC;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 17px;
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .auth-wrap__form--subscription .subscription__box {
    width: 50%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    float: left; }
    .auth-wrap__form--subscription .subscription__box .subscription__box-header {
      font-size: 20px;
      color: #333333;
      margin-bottom: 0; }
      .auth-wrap__form--subscription .subscription__box .subscription__box-header strong {
        font-weight: 600; }
      .auth-wrap__form--subscription .subscription__box .subscription__box-header a {
        position: relative;
        top: -2px;
        margin-left: 6px; }
        .auth-wrap__form--subscription .subscription__box .subscription__box-header a:hover {
          opacity: .8; }

.auth-wrap__form--subscription.active {
  background: rgba(0, 204, 153, 0.12612);
  border: 1px solid #00cc99;
  box-sizing: border-box;
  box-shadow: 0px 5px 15px rgba(0, 204, 153, 0.200311);
  border-radius: 3px; }

@media (max-width: 767.98px) {
  .auth-wrap__col--left .auth-wrap__col--left-inner {
    width: 100%;
    padding: 30px 30px 30px 30px; }
  .auth-wrap-right-data {
    padding: 38px 13px 38px 13px; }
  .auth-wrap__col--left:after {
    width: 100%; }
  .auth-wrap__form--subscription .subscription__box {
    width: 100%; } }

@media (max-width: 575.98px) {
  .auth-wrap-right-data .auth-wrap-right-data__logo {
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
  .auth-wrap-right-data .auth-wrap-right-data__logo img:first-child {
    margin-bottom: 20px; } }

.qty-input {
  height: 21px !important;
  width: 47px;
  font-size: 15px;
  padding: 10px 4px; }

.auth-wrap__form--admin [class*='col-'] {
  padding-left: 0;
  padding-right: 0; }

.verification-head {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1.71429px;
  text-transform: uppercase;
  color: #333333;
  margin: 25px 0; }

.auth-wrap__form--duo [class*='col-'] {
  padding-left: 5px;
  padding-right: 5px; }

.auth-wrap__form--duo .btn-primary {
  padding: 5px 15px; }

.terms-agree__link {
  color: #00cc99;
  text-decoration: underline; }
  .terms-agree__link:hover {
    color: #00cc99; }

.boxed-custom-text-wrap {
  float: left;
  width: 100%;
  margin-top: 24px; }
  @media (max-width: 575.98px) {
    .boxed-custom-text-wrap {
      margin-top: 0; } }
  .boxed-custom-text-wrap .boxed-custom-input-wrap.lightblue {
    background: #eff9f7 !important;
    border-color: #dcf5ef !important;
    position: relative; }
    .boxed-custom-text-wrap .boxed-custom-input-wrap.lightblue::before {
      background-image: url(/images/global/tick-green-1.svg);
      background-repeat: no-repeat; }
    .boxed-custom-text-wrap .boxed-custom-input-wrap.lightblue span {
      font-weight: 600;
      margin-right: 6px; }
  .boxed-custom-text-wrap .boxed-custom-input-wrap.danger {
    background: #fdefef !important;
    border-color: #efe1e1 !important;
    position: relative; }
    .boxed-custom-text-wrap .boxed-custom-input-wrap.danger::before {
      background-image: url(/images/Signup/close.svg);
      background-repeat: no-repeat;
      top: 15px; }
  .boxed-custom-text-wrap .boxed-custom-input-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    background: #f5f5f5;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 25px 10px 35px;
    margin-bottom: 9px !important; }
    .boxed-custom-text-wrap .boxed-custom-input-wrap::before {
      content: "";
      position: absolute;
      top: 13px;
      left: 12px;
      width: 16px;
      height: 16px; }

.auth-wrap-right-data__form .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  top: 3px; }

.import-companies-modal__custom-input-wrap .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  top: 3px; }

.import-companies-modal__custom-input-wrap.dashboard__alertbox {
  background-color: #fcfdeb;
  border: 1px solid #e8ee81;
  border-radius: 3px;
  padding: 15px; }

.dashboard__alertbox {
  font-size: 12px;
  color: #333333 !important;
  background-color: #F7F8E7 !important;
  border-color: #DDE63C !important; }

.dashboard__alertbox--head {
  font-size: 12px; }

.dashboard__caution-icon {
  padding: 2px 8px;
  border-radius: 50%;
  background-color: #DDE63C;
  color: #ffffff;
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
  margin-right: 2px; }

.dashboard__alertbox--head {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: 600;
  padding: 7px 0;
  margin: 0; }

.dashboard__alertbox--sub {
  line-height: 19px;
  margin: 0;
  padding-bottom: 6px;
  padding-left: 25px;
  font-size: 13px; }

.dashboard__task {
  padding: 0px 24px 23px 24px;
  padding-bottom: 0;
  list-style: none;
  margin: 0; }

.dashboard__task-item {
  padding: 24px 0 0 0;
  font-weight: 600;
  float: left;
  border-top: solid 1px #D8D8D8; }
  .dashboard__task-item:first-child {
    border-top: none; }

.dashboard__task-item--label {
  font-size: 15px;
  font-weight: 600;
  padding-left: 24px; }

.dashboard__task-item-block--label.custom-control-label::after {
  top: 1.4px;
  left: -22.9px;
  width: 18px;
  height: 18px; }

.dashboard__task-item-block--label:before {
  top: 0px;
  color: #ffffff;
  border-color: #333333;
  background-color: #ffffff; }

.custom-checkbox .custom-control-input:checked ~ .dashboard__task-item-block--label::after {
  width: 34px;
  height: 43px;
  background-image: url(/images/dashboard/tick.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  box-shadow: none;
  top: 2px;
  left: -16px; }

.dashboard__task-item--sub-label {
  padding-top: 9px;
  padding-bottom: 5px;
  font-size: 12px; }

.dashboard__task-item--label-color em {
  font-weight: normal;
  color: #2a8080;
  font-size: 13px;
  font-style: normal;
  font-weight: 600; }

.dashboard__task-item--label-text {
  padding-left: 25px; }

.dashboard__task-item--label-text i {
  margin-right: 5px; }

.dashboard__task-item--label-color {
  color: #888888; }

.dashboard__recent-msg-wrapper,
.dashboard__resources-wrapper {
  margin-left: 15px; }

.dashboard__recent {
  padding: 10px 11px;
  max-height: 235px;
  overflow-x: auto;
  margin: 0;
  list-style: none; }

.alert-border {
  padding-bottom: 10px;
  border-bottom: 1px solid #ececec; }

.dashboard__recent--label {
  margin: 0;
  padding: 12px 16px;
  margin-bottom: 5px;
  width: 100%;
  background-color: rgba(237, 246, 245, 0.870588);
  border-radius: 4px; }

.dashboard__recent--label-text {
  font-size: 15px;
  font-weight: 600;
  color: #333333;
  cursor: pointer; }

.dashboard__recent--label-text:hover,
.dashboard__recent--label-text:focus,
.dashboard__recent--label-text:active {
  text-decoration: none;
  color: #2a8080 !important; }

.dashboard__recent li:last-child a {
  font-weight: 400; }
  .dashboard__recent li:last-child a span {
    display: none; }

.dashboard__resources {
  padding: 10px 11px;
  max-height: 270px;
  overflow-x: auto;
  margin: 0;
  list-style: none; }

.dashboard__resources--label {
  margin: 0;
  padding: 12px 16px;
  margin-bottom: 5px;
  width: 100%;
  background-color: rgba(237, 246, 245, 0.870588);
  border-radius: 4px; }

.dashboard__resources--label-text {
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 0px; }

.navbar__notification-dropdown-messages--time {
  font-size: 13px; }

input[type=checkbox]:checked + label.dashboard__task-item-block--label {
  text-decoration: line-through; }

@media (max-width: 767.98px) {
  .dashboard__task-wrapper,
  .dashboard__recent-msg-wrapper,
  .dashboard__resources-wrapper {
    margin: 12px 0; }
  .dashboard__task-item--sub-label div {
    display: block !important; }
  .dashboard__task-item--label-text {
    font-size: 11px;
    padding-right: 0 !important;
    padding-left: 25px !important;
    padding-top: 10px; }
  .dashboard__task {
    padding: 0px 15px 23px 15px; }
  .dashboard__task-item-block--label:before {
    top: -2px; }
  .dashboard__task-item--label {
    line-height: 1.1; } }

.mdh-tabs--demographics .nav-item {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center; }

.mdh-tabs .nav-item .nav-link .badge {
  display: inline-block;
  border-radius: 0px;
  font-size: 15px;
  background-color: #C5C5C5; }

.mdh-tabs .nav-item.show .nav-link .badge, .mdh-tabs .nav-link.active .badge {
  background-color: #00cc99; }

.mdh-tabs .nav-link:hover .badge {
  background-color: #00cc99; }

.patient-checkbox .custom-control-label:before {
  top: 0px; }

@media (max-width: 767.98px) {
  .mdh-tabs--demographics .nav-item {
    width: 100% !important;
    padding: 0px !important; }
  .mdh-tabs--demographics .nav-item .nav-link {
    padding: 15px 15px !important; } }

.mdh-tabs--medical-history .nav-item {
  width: 33.33%;
  text-align: center; }

.info-text {
  padding: 0 10px; }

.medical-history-head-wrap h6 {
  font-size: 16px;
  font-weight: 600;
  color: #333333; }

.terms-custom-col {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: 41px;
  padding-left: 5px !important;
  padding-right: 5px !important; }
  .terms-custom-col .custom-datepicker-wrap {
    margin-left: 10px;
    margin-bottom: 5px; }

.patient-checkbox {
  margin-right: auto; }
  .patient-checkbox .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    top: 5px; }

.patient-checkbox label {
  margin-bottom: 0px !important; }

.custom-datepicker-wrap {
  position: relative;
  width: 144px;
  margin-left: 30%;
  float: left;
  margin-right: 10px; }
  .custom-datepicker-wrap .react-datepicker-popper {
    right: 0px !important;
    left: auto !important; }
  .custom-datepicker-wrap .react-datepicker__triangle {
    left: auto !important;
    right: 50px !important; }

.other-items {
  margin-bottom: 8px !important;
  margin-top: 21px;
  width: 100%; }

.custom-col-inline {
  padding-left: 5px !important;
  padding-right: 5px !important; }
  .custom-col-inline h5 {
    font-size: 15px;
    color: #000000;
    font-weight: 600; }

.authorize-box {
  width: 100%;
  padding: 40px 30px;
  background-color: #F7F8E7;
  border: solid 1px #DDE63C; }
  .authorize-box p {
    font-size: 16px;
    color: #333333;
    padding-left: 10px; }

.authorize-box p:after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  top: 8px;
  left: 0;
  background-color: #aab12d; }

.custom-react-select {
  margin-bottom: 30px; }

@media (max-width: 767.98px) {
  .mdh-tabs--medical-history .nav-item {
    width: 100% !important;
    padding: 0px !important; }
  .mdh-tabs--medical-history .nav-item .nav-link {
    padding: 15px 15px !important; } }

.patient-auth-hippa__canvas {
  border: 1px #d5d5d5 solid;
  margin-right: 10px; }

.patient-auth-hippa__canvas-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end; }

.medical-history-members-accordion-wrap {
  padding: 17px 30px; }

.notes-outer-wrapper {
  height: 40vh;
  overflow-y: auto;
  margin-bottom: 60px; }

.notes-outer-wrapper::-webkit-scrollbar {
  width: 9px;
  border-radius: 20px; }

.notes-outer-wrapper::-webkit-scrollbar-track {
  background: #f8f8f8; }

.notes-outer-wrapper::-webkit-scrollbar-thumb {
  background: #137878;
  border-radius: 100px; }

.notes-outer-wrapper::-webkit-scrollbar-thumb:hover {
  opacity: .9; }

.notes-outer-wrapper--grid {
  width: 100%;
  margin: 0;
  padding: 0 15px 0 0; }
  .notes-outer-wrapper--grid li {
    border-radius: 4px;
    padding: 21px;
    margin-bottom: 15px; }
    .notes-outer-wrapper--grid li p {
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      margin-bottom: 10px; }
    .notes-outer-wrapper--grid li h6 {
      font-size: 16px;
      font-style: italic;
      color: #333333;
      font-weight: normal;
      margin: 0;
      padding: 0; }
      .notes-outer-wrapper--grid li h6 span {
        color: #137878;
        margin-right: 5px; }

@media (max-width: 575.98px) {
  .notes-outer-wrapper--grid {
    padding: 0; } }

.grey-color {
  background-color: #f8f8f8; }

.green-color {
  background-color: #E6FAF5; }

.btn-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.default-cursor {
  cursor: default !important; }

.mdh-accordion--top .form-group, .mdh-accordion--top .custom-react-select {
  margin-bottom: 0px; }

.mdh-accordion--top .form-control {
  min-height: 41px !important; }

.known-allergies {
  width: 100%;
  background: rgba(230, 60, 60, 0.06696);
  border-radius: 3px;
  padding: 19px 22px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 22px;
  margin-bottom: 38px; }
  .known-allergies h4 {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #E63C3C;
    margin: 0;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }

.known-allergies--list {
  width: 80%;
  margin: 0;
  padding: 0; }
  .known-allergies--list li {
    list-style: none;
    float: left;
    margin: 10px 0;
    margin-left: 14px; }
    .known-allergies--list li a {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #333333;
      background-color: #ffffff;
      padding: 6px 14px;
      border-radius: 3px; }
      .known-allergies--list li a:hover {
        text-decoration: none;
        opacity: .8; }

@media (max-width: 767.98px) {
  .known-allergies {
    display: block;
    width: 100%;
    float: left; }
    .known-allergies h4 {
      text-align: center;
      margin-bottom: 20px; }
    .known-allergies .known-allergies--list {
      width: auto;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: center;
              justify-content: center; }
    .known-allergies .known-allergies--list li {
      margin-left: 10px; } }

.medicine-cabinet .section-heading {
  color: #888888;
  border: none;
  margin-bottom: 0;
  padding-bottom: 0; }

.medicine-cabinet .section-heading--green {
  color: #00cc99; }

@media (max-width: 767.98px) {
  .known-allergies--list li {
    margin-bottom: 15px;
    margin-left: 0px; }
  .known-allergies h4 {
    width: 100%; } }

/* New UI */
.active-medication-wrap {
  float: left;
  width: 100%;
  margin-bottom: 17px; }
  .active-medication-wrap .mdh-card {
    position: relative;
    margin-bottom: 19px; }

.active-medication-head {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #46A7FA;
  margin-bottom: 10px; }

.active-medication-head.completed {
  color: #00cc99 !important; }

.active-medication-head.stopped {
  color: #E63C3C !important; }

.inactive .active-medication-head {
  color: #888888 !important; }

.inactive .mdh-card {
  -webkit-filter: opacity(0.7);
  filter: opacity(0.7); }

.active-medication-name-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  word-break: break-word;
  padding: 20px 0 20px 23px;
  min-height: 203px;
  height: 100%; }
  .active-medication-name-wrap::before {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #E0E0E0;
    position: absolute;
    top: 0;
    right: 0; }
  .active-medication-name-wrap p {
    color: #888888; }
  .active-medication-name-wrap a {
    padding: 3px 0 !important; }

.active-medication-name {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #333333;
  margin-bottom: 0; }
  .active-medication-name a img {
    margin-left: 8px;
    width: 17px;
    height: 17px; }

.active-medication-name-wrap .btn-edit-medicine {
  width: 40px;
  height: 40px;
  float: right;
  position: absolute;
  top: 6px;
  right: 20px;
  z-index: 1; }

.active-medication-details-wrap {
  float: left;
  width: 100%;
  padding: 26px;
  padding-bottom: 0;
  min-height: 203px;
  height: 100%;
  position: relative; }
  .active-medication-details-wrap .btn--edit {
    width: 40px;
    height: 40px;
    float: right;
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1; }

.active-medication-details {
  float: left;
  width: 100%;
  padding-bottom: 28px; }
  .active-medication-details label {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: 0; }
  .active-medication-details p {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #888888;
    margin-bottom: 0; }

.active-medication-details-status {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #333333; }
  .active-medication-details-status .plan-status {
    margin: 0;
    margin-right: 5px; }
  .active-medication-details-status .active .plan-status {
    background-color: #46A7FA !important;
    box-shadow: 0px 0px 15px rgba(70, 167, 250, 0.5) !important; }
  .active-medication-details-status a {
    text-decoration: none;
    color: #E63C3C; }
    .active-medication-details-status a:hover {
      color: #E63C3C; }

.stopping-reason-select-wrap {
  display: none; }

@media (max-width: 1199.98px) {
  .active-medication-name {
    font-size: 15px; }
  .active-medication-details-head {
    font-size: 13px; }
  .active-medication-details-sub {
    font-size: 12px; } }

@media (max-width: 767.98px) {
  .active-medication-name-wrap {
    min-height: 75px; }
  .active-medication-name-wrap::before {
    width: 100%;
    height: 1px;
    top: 100%; }
  .active-medication-details {
    padding: 20px 22px; } }

@media (min-width: 575.98px) {
  .active-medication-name-wrap .btn--edit {
    display: none; } }

@media (max-width: 575.98px) {
  .active-medication-name-wrap {
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .active-medication-name-wrap::before {
      display: none; }
    .active-medication-name-wrap .active-medication-name {
      font-size: 20px;
      font-weight: 600;
      color: #137878; }
  .active-medication-details-wrap .btn--edit {
    display: none; }
  .active-medication-details-wrap .active-medication-details {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .active-medication-details-wrap .active-medication-details-head,
  .active-medication-details-wrap .active-medication-details-sub {
    font-size: 17px;
    font-weight: normal;
    color: #333333; }
  .active-medication-details-wrap .active-medication-details-sub {
    text-align: right; }
  .active-medication-wrap .mdh-card {
    border-radius: 8px; }
  .active-medication-details-wrap {
    padding: 0; } }

.messagecenter-box {
  height: 619px;
  background: #FFFFFF;
  border: 1px solid rgba(204, 204, 204, 0.6);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.0533571);
  border-radius: 3px;
  margin-top: 10px;
  overflow: auto; }

@media (max-width: 767.98px) {
  .messagecenter-box {
    height: auto; } }

.messagecenter-box::-webkit-scrollbar {
  width: 10px; }

.messagecenter-box::-webkit-scrollbar-thumb {
  background-color: #b7d6d1;
  border-radius: 50px; }

.messagecenter-box::-webkit-scrollbar-track {
  background-color: #fafafa; }

.contact-list {
  margin: 0;
  padding: 0; }
  .contact-list li {
    list-style: none;
    height: 64px;
    padding: 12px 13px;
    border-bottom: solid 1px rgba(204, 204, 204, 0.6);
    cursor: pointer; }
  .contact-list .selected {
    background-color: #ebfbf7; }
  .contact-list .contact-list--img {
    width: 40px;
    height: 40px;
    background-color: #00CC99;
    border-radius: 50%;
    float: left;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    overflow: hidden; }
    .contact-list .contact-list--img img {
      width: 40px;
      height: 40px; }
  .contact-list .contact-list--name {
    width: calc(100% - 63px);
    padding: 0 24px; }
    .contact-list .contact-list--name h4 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      padding: 0; }
    .contact-list .contact-list--name h6 {
      font-size: 13px;
      color: #333;
      opacity: .65;
      margin: 0; }
  .contact-list .contact-list--badge {
    position: relative;
    width: 23px;
    height: 23px;
    background-color: #F1FCF9;
    color: #137878;
    border-radius: 50%;
    float: left;
    font-size: 12px;
    font-weight: 400; }

.chat-box--top {
  padding: 12px 15px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.6); }
  .chat-box--top .contact--img {
    width: 40px;
    height: 40px;
    background-color: #00CC99;
    border-radius: 50%;
    float: left;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    overflow: hidden;
    position: relative; }
    .chat-box--top .contact--img img {
      width: 40px;
      height: 40px; }
  .chat-box--top .contact--name {
    width: calc(100% - 64px);
    padding: 0 10px; }
    .chat-box--top .contact--name h4 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      padding: 0; }
    .chat-box--top .contact--name h6 {
      font-size: 13px;
      color: #333;
      opacity: .65;
      margin: 0; }
  .chat-box--top .chat-box-profile-search {
    min-width: 20%; }
    @media (max-width: 1199.98px) {
      .chat-box--top .chat-box-profile-search {
        min-width: 33%; } }
    @media (max-width: 767.98px) {
      .chat-box--top .chat-box-profile-search {
        min-width: 25%; } }
    .chat-box--top .chat-box-profile-search .profile-link {
      font-size: 13px;
      font-weight: 600;
      color: #137878;
      margin: 0 9px; }
      .chat-box--top .chat-box-profile-search .profile-link:last-child {
        margin-right: 0px !important; }

.chat-box-user-details .badge {
  width: 12px;
  height: 12px;
  z-index: 11;
  padding: 3px;
  background-color: #00cc99;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: absolute;
  top: 0px;
  left: 30px;
  display: block; }

.chat-box--middle {
  height: 505px;
  overflow-y: auto;
  background-color: #fff;
  overflow-y: auto; }

.chat-box--middle::-webkit-scrollbar {
  width: 10px; }

.chat-box--middle::-webkit-scrollbar-thumb {
  background-color: #b7d6d1;
  border-radius: 50px; }

.chat-box--middle::-webkit-scrollbar-track {
  background-color: #fafafa; }

.chat-box--bottom {
  height: 47px;
  border-top: 1px solid rgba(204, 204, 204, 0.6); }
  @media (max-width: 1199.98px) {
    .chat-box--bottom {
      height: 44px; } }
  .chat-box--bottom .attachment-wrap {
    width: 46px;
    height: 100%;
    border-right: 1px solid rgba(204, 204, 204, 0.6);
    float: left; }
  .chat-box--bottom .type-data {
    width: calc(100% - 146px);
    height: 100%;
    float: left; }
    .chat-box--bottom .type-data .form-control {
      height: 100%;
      border-radius: 0;
      line-height: 1;
      border: none;
      resize: none; }
      .chat-box--bottom .type-data .form-control:focus {
        border: none;
        box-shadow: none; }
  .chat-box--bottom .send-btn {
    width: 100px;
    height: 100%;
    float: right;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-right: 10px; }
    .chat-box--bottom .send-btn .btn {
      font-size: 13px;
      padding: 3px 16px; }

.chat-main-content {
  padding: 20px 0; }

.chat-item {
  padding: 10px 15px;
  font-size: 10px;
  margin-bottom: 10px; }

.chat-item.flex-row-reverse .bubble {
  margin-right: 16px;
  margin-left: 0; }

.chat-item.flex-row-reverse .bubble .bubble-inner {
  background-color: #edfbf8;
  color: #333;
  border-radius: 5px; }

.chat-item .bubble {
  position: relative;
  margin-left: 16px;
  max-width: 590px; }
  @media (max-width: 1199.98px) {
    .chat-item .bubble {
      max-width: 350px; } }
  @media (max-width: 767.98px) {
    .chat-item .bubble {
      max-width: 100%;
      padding-right: 10px;
      margin-left: 15px; } }

.chat-item .bubble .bubble-inner {
  background-color: #f8f8f8;
  padding: 8px;
  border-radius: 5px;
  word-wrap: break-word; }
  .chat-item .bubble .bubble-inner img {
    max-width: 100%; }

.chat-item .sender {
  line-height: 1;
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0 8px 0; }

.chat-item .message {
  line-height: 1.4;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 8px; }

.chat-item .message .img-wrap {
  max-width: 100%;
  height: 320px;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 10px;
  -webkit-align-items: flex-start;
          align-items: flex-start; }

.chat-item .message a {
  color: #fff;
  font-weight: 600; }

.chat-item .message img {
  margin-bottom: 10px; }

.chat-item.flex-row-reverse .time {
  text-align: right;
  color: #333;
  font-size: 10px;
  margin-bottom: 5px; }

.chat-item .time {
  margin-bottom: 5px;
  font-size: 10px;
  color: #9a9a9a;
  font-style: italic; }

@media (max-width: 767.98px) {
  .messagecenter-box {
    height: auto; }
  .chat-item.flex-row-reverse .bubble {
    margin-right: 0px; } }

/* Nurse Information Modal */
.nurse-info-wrapper {
  padding: 20px 0px; }
  .nurse-info-wrapper h4 {
    font-size: 22px;
    margin: 17px 0 4px 0; }
  .nurse-info-wrapper h6 {
    font-size: 16px;
    color: #888;
    margin-bottom: 15px; }
  .nurse-info-wrapper p {
    font-size: 14px;
    color: #333; }
  .nurse-info-wrapper .section-heading {
    font-size: 18px;
    margin-top: 55px; }
  .nurse-info-wrapper ul {
    margin: 19px 0 0 0;
    padding: 0; }
    .nurse-info-wrapper ul li {
      list-style: none;
      margin-bottom: 7px;
      position: relative;
      padding-left: 20px; }
      .nurse-info-wrapper ul li:after {
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        top: 7px;
        left: 0;
        background-color: #DDE63C; }

.nurse-info--img {
  width: 114px;
  height: 114px;
  border-radius: 50%;
  background-color: #00CC99;
  float: left;
  overflow: hidden; }
  .nurse-info--img img {
    height: 114px;
    object-fit: fill; }

.files-shared-wrapper {
  padding: 20px 0px; }
  .files-shared-wrapper .files-wrapper {
    margin: 0;
    padding: 0; }
    .files-shared-wrapper .files-wrapper li {
      list-style: none;
      border-radius: 4px;
      background-color: #edfbf8;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      padding: 14px;
      margin-bottom: 10px; }
      .files-shared-wrapper .files-wrapper li h5 {
        margin: 0;
        padding: 0;
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        color: #333; }
        .files-shared-wrapper .files-wrapper li h5 img {
          margin-right: 10px; }
      .files-shared-wrapper .files-wrapper li h6 {
        margin: 0;
        padding: 0 15px 0 0;
        font-size: 16px;
        color: #888; }

.start-conversation {
  padding: 5px 30px; }

.message-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .message-row .btn-primary {
    padding: 5px 30px !important; }

.cp-tab-pane {
  padding: 44px 10px !important; }

.cp-list {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative; }
  .cp-list li {
    list-style: none;
    position: relative;
    padding-left: 25px;
    margin-bottom: 20px; }
    .cp-list li:after {
      position: absolute;
      content: '';
      width: 8.54px;
      height: 8.54px;
      top: 5px;
      left: 0;
      background-color: #DDE63C; }

.cp-date {
  font-size: 15px;
  font-weight: 400;
  color: #888888;
  margin-bottom: 8px; }

.cp-text {
  font-size: 18px;
  font-weight: 400;
  color: #000000; }

.task-item--row {
  border-top: solid 1px #D6D6D6;
  padding: 17px 0 8px 0; }
  .task-item--row:first-child {
    border-top: none; }

.custom-checkbox .custom-control-input:checked ~ .tasks-checkbox--item::after {
  width: 18px;
  height: 16px;
  background-image: url(/images/dashboard/tick.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  box-shadow: none;
  top: 2px;
  left: 6px; }

.tasks-checkbox--item {
  padding-left: 26px;
  font-weight: 600; }

.tasks-checkbox--item:before {
  top: 0px;
  left: 0px;
  color: #ffffff;
  border-color: #333333;
  background-color: #ffffff; }

input[type=checkbox]:checked + label.tasks-checkbox--item {
  text-decoration: line-through; }

@media (max-width: 767.98px) {
  .tasks-checkbox--item:before {
    top: 3px; } }

.resources-top-section {
  width: 100%;
  float: left;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: rgba(19, 120, 120, 0.05);
  padding: 11px; }
  .resources-top-section .form-group {
    margin-bottom: 0px; }
    .resources-top-section .form-group .form-control {
      height: 32px; }

.custom-checkbox--resources {
  float: left; }
  .custom-checkbox--resources .custom-control-label::before, .custom-checkbox--resources .custom-control-input:checked ~ .custom-control-label::before {
    top: 1px; }
  .custom-checkbox--resources .custom-control-label::after {
    top: 6px !important;
    left: -19.9px !important; }

.custom-react-select--resources {
  margin-bottom: 0px;
  width: 40% !important; }
  .custom-react-select--resources .react-select__control {
    min-height: 32px !important; }
  .custom-react-select--resources .react-select__value-container {
    padding: 0 8px; }
    .custom-react-select--resources .react-select__value-container .css-1wa3eu0-placeholder {
      line-height: 1; }
  .custom-react-select--resources .react-select__indicator {
    padding: 4px; }

.resources-input {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.btn__add-resources.btn-outline-secondary {
  background-color: transparent !important;
  box-shadow: none !important; }

.mdh-reactable .resources-table__link-icon {
  padding: 15.5px 10.5px;
  background: rgba(19, 120, 120, 0.0960428);
  border-radius: 3px; }

.mdh-reactable .resources-table__link-icon.active,
.mdh-reactable .resources-table__link-icon.active path {
  fill: #ff0000;
  background-color: rgba(230, 60, 60, 0.1) !important; }

.resource-span-icon {
  position: relative;
  width: 43px;
  height: 43px;
  background-color: #E5F9F4;
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 20px; }

.resources-upload .resources-upload--name {
  font-size: 16px;
  color: #137878;
  margin-bottom: 0;
  width: 100%; }

.resources-upload .resources-upload--delete-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.resources-upload.selected {
  background-color: #ffeaec; }
  .resources-upload.selected .resources-upload--name {
    color: #ff0000; }
    .resources-upload.selected .resources-upload--name svg path {
      fill: #ff0000; }

@media (max-width: 575.98px) {
  .resources-upload--name {
    font-size: 15px; } }

@media (max-width: 767.98px) {
  .resources-input .custom-checkbox--resources {
    width: 65%; } }

@media (max-width: 767.98px) {
  .resources-input {
    margin-top: 8px; }
  .resources-input .custom-react-select--resources {
    width: 70% !important; }
  .resources-input .custom-checkbox--resources {
    margin-right: auto; }
  .resources-top-section .form-group .form-control {
    width: 100%; } }

.appointment-wrap .mdh-accordion {
  margin-bottom: 27px;
  padding: 19px 24px; }

.appointments__accordion-address {
  padding: 16px 10px;
  padding-left: 80px;
  display: -webkit-flex;
  display: flex; }
  .appointments__accordion-address img {
    padding-right: 12px;
    padding-top: 5px; }

.appointments__accordion-address-text {
  font-size: 16px;
  padding-top: 4px;
  padding-bottom: 5px;
  margin-bottom: 0; }
  .appointments__accordion-address-text p {
    margin-bottom: 0;
    line-height: 1;
    color: #333333; }

.appointments__accrodion-address-link {
  color: #00cc99; }

.appointments__accrodion-address-link:hover,
.appointments__accrodion-address-link:focus,
.appointments__accrodion-address-link:active {
  text-decoration: none;
  color: #00cc99; }

.appointments__accordion-btn-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-top: 12px; }
  .appointments__accordion-btn-wrap .btn {
    margin-left: 15px;
    margin-top: 7px;
    margin-bottom: 4px; }

.appointment-wrap .mdh-acc-text--date .mdh-acc-text--date-head {
  padding-top: 5px;
  line-height: 18px; }

@media (max-width: 767.98px) {
  .mdh-accordion-outer .mdh-accordion--white-valign:first-child {
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .mdh-accordion-outer .mdh-accordion--white-valign:first-child .mdh-acc-text--date {
      padding-left: 0; }
  .mdh-accordion--white-valign {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
    .mdh-accordion--white-valign .mdh-acc-text--date {
      padding-left: 80px;
      margin-top: 10px; } }
    @media (max-width: 767.98px) and (max-width: 576.98px) {
      .mdh-accordion--white-valign .mdh-acc-text--date {
        padding-left: 0px;
        margin-top: 0px; } }

@media (max-width: 767.98px) {
      .mdh-accordion--white-valign .mdh-acc-text--date .plan-status {
        margin-left: 0 !important; }
  .appointments__accordion-address {
    padding-left: 10px; }
  .appointments__accordion {
    max-width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 15px; } }

.member-call-center-wrap .call-center-profile__call-request-list {
  float: left;
  width: 100%; }

.member-call-center-wrap .call-center-profile-wrap .call-center-profile__call-request-link {
  font-size: 16px; }

.member-call-center-wrap .call-center-profile-wrap .call-center-profile__call-request .call-center-profile__call-request-head {
  margin-bottom: 0; }

.member-call-center-wrap .call-center-profile-wrap .call-center-profile__call-request-item h5.call-center-profile__call-request-item-text {
  font-size: 15px;
  font-weight: 600;
  margin-top: 7px;
  padding-bottom: 6px; }
  @media (max-width: 575.98px) {
    .member-call-center-wrap .call-center-profile-wrap .call-center-profile__call-request-item h5.call-center-profile__call-request-item-text {
      padding-left: 10px; } }

.member-call-center-wrap .call-center-profile-wrap .call-center-profile__call-request-item p.call-center-profile__call-request-item-text {
  font-size: 14px;
  padding-bottom: 10px; }
  .member-call-center-wrap .call-center-profile-wrap .call-center-profile__call-request-item p.call-center-profile__call-request-item-text span {
    padding-left: 5px;
    font-weight: normal; }
  @media (max-width: 575.98px) {
    .member-call-center-wrap .call-center-profile-wrap .call-center-profile__call-request-item p.call-center-profile__call-request-item-text {
      padding-left: 10px; } }

.member-call-center-wrap .call-center-profile-wrap .call-center-profile__call-request-item {
  position: relative;
  float: left;
  width: 100%;
  border-bottom: 1px solid #E0E0E0; }

.member-call-center-wrap .mdh-pagination {
  float: left;
  width: 100%;
  padding: 30px 0; }
  .member-call-center-wrap .mdh-pagination .pagination {
    margin-bottom: 0; }

.nurse-auth-wrap {
  background-image: url("/images/auth/auth-bg-full.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #00CC99;
  width: 100%; }

.nurse-auth-data-wrap {
  width: 444px;
  max-width: 100%; }

.nurse-auth-data {
  height: 100%;
  padding: 38px;
  width: 100%; }
  .nurse-auth-data .nurse-auth-data__logo {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-top: 6px;
    padding-bottom: 10px; }
  .nurse-auth-data .auth-wrap-right-data__text {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 2.82353px;
    text-transform: uppercase;
    color: #137878;
    margin-top: 9.3px;
    margin-bottom: 0; }

.nurse-auth-data__inner {
  width: 100%;
  padding: 22px 0 30px 0; }
  .nurse-auth-data__inner .auth-wrap-actions__have-account {
    margin-top: 8px; }
  .nurse-auth-data__inner .auth-wrap-actions__have-account.mt-4 {
    margin-top: 21.6px !important; }
  .nurse-auth-data__inner .auth-wrap-actions {
    margin-top: 24px; }

.mdh-card--nurse .mdh-card__head {
  padding: 25px 12px 25px 25px; }

.mdh-card--nurse .mdh-card__head--label {
  font-size: 20px; }
  @media (max-width: 1199.98px) {
    .mdh-card--nurse .mdh-card__head--label {
      margin-bottom: 10px; } }

.mdh-card--nurse .dashboard__recent--label {
  padding: 8px 16px; }

.mdh-card--nurse .dashboard__recent {
  max-height: none;
  overflow: unset; }

.mdh-card--nurse .mdh-accordion-outer {
  padding: 19px 29px; }
  .mdh-card--nurse .mdh-accordion-outer .btn--add-task {
    min-width: 186px; }
    @media (max-width: 575.98px) {
      .mdh-card--nurse .mdh-accordion-outer .btn--add-task {
        width: 100%; } }

.mdh-card--nurse .mdh-accordion {
  padding: 14px 0; }

.mdh-card--nurse .mdh-accordion--top {
  padding: 0 24px; }

.mdh-card--nurse .appointments__accordion {
  padding: 0 24px; }

.mdh-card--nurse .mdh-accordion--white-left-date {
  padding-left: 0; }

.mdh-card--nurse .mdh-acc-text--date-show {
  float: left;
  font-size: 23px;
  font-weight: 600;
  color: #b2b2b2;
  text-align: center;
  margin-bottom: 0;
  margin-right: 27px;
  padding-right: 18px;
  line-height: 1; }
  .mdh-card--nurse .mdh-acc-text--date-show::before {
    content: "";
    position: absolute;
    top: 0;
    left: -12px;
    width: 6px;
    height: 100%;
    border-radius: 30px; }
  .mdh-card--nurse .mdh-acc-text--date-show::after {
    content: "";
    position: absolute;
    top: 8px;
    left: 63px;
    width: 1px;
    height: 75%;
    background-color: #D1D1D1; }
    @media (max-width: 575.98px) {
      .mdh-card--nurse .mdh-acc-text--date-show::after {
        top: 0;
        height: 55%; } }
  .mdh-card--nurse .mdh-acc-text--date-show span {
    position: relative;
    top: -5px;
    font-size: 18px;
    font-weight: 500;
    color: #b2b2b2; }

.mdh-card--nurse .mdh-acc-text--date-show.task {
  color: #46A7FA; }

.mdh-card--nurse .mdh-acc-text--date-show.event {
  color: #00cc99; }

.mdh-card--nurse .mdh-acc-text--date-show.requests {
  color: #dde63c; }

.mdh-card--nurse .mdh-acc-text--date-show.task::before {
  background-color: #46A7FA; }

.mdh-card--nurse .mdh-acc-text--date-show.event::before {
  background-color: #00cc99; }

.mdh-card--nurse .mdh-acc-text--date-show.requests::before {
  background-color: #dde63c; }

.mdh-card--nurse .appointments__accordion-address {
  padding-left: 0; }
  .mdh-card--nurse .appointments__accordion-address .appointments__accordion-address--text {
    color: #888888;
    line-height: 1.2;
    word-break: break-word; }

.mdh-card--nurse .mdh-acc-text--date-sub-head.main-head {
  font-size: 19px; }
  @media (max-width: 991.98px) {
    .mdh-card--nurse .mdh-acc-text--date-sub-head.main-head {
      font-size: 17px; } }
  @media (max-width: 575.98px) {
    .mdh-card--nurse .mdh-acc-text--date-sub-head.main-head {
      font-size: 18px; } }

.mdh-card--nurse .mdh-acc-text--date-sub-head {
  margin-bottom: 0; }
  .mdh-card--nurse .mdh-acc-text--date-sub-head a {
    color: #00cc99; }

.mdh-card--nurse .appointments__accordion-btn-wrap .btn {
  min-width: 110px; }

.mdh-card--nurse .mdh-acc-text--date {
  width: 100%; }
  @media (max-width: 575.98px) {
    .mdh-card--nurse .mdh-acc-text--date {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      margin-bottom: 8px; } }

@media (max-width: 575.98px) {
  .mdh-card--nurse .appointments__accordion-btn-wrap {
    -webkit-flex-direction: column;
            flex-direction: column; }
    .mdh-card--nurse .appointments__accordion-btn-wrap .btn {
      width: 100%;
      margin-left: 0; } }

.nurse-dashboard-filter {
  width: 15%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap; }
  .nurse-dashboard-filter .custom-react-select {
    width: 100%;
    margin-bottom: 0; }
  .nurse-dashboard-filter .react-select__control {
    min-height: 35px !important; }
    @media (max-width: 767.98px) {
      .nurse-dashboard-filter .react-select__control {
        margin-bottom: 10px; } }

.navbar__notification-dropdown-messages--time {
  line-height: 1; }
  .navbar__notification-dropdown-messages--time a {
    color: #137878; }

.dashboard-task-img {
  position: relative;
  margin-right: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  float: left; }

.dashboard-task-data {
  position: relative;
  float: left; }

.time-stamp p {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 13px;
  color: #333333; }

.time-stamp small {
  font-size: 12px;
  color: #888888;
  line-height: 1; }

.event-task-indicator {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%;
  padding: 0 5px;
  position: relative;
  height: 40px; }
  .event-task-indicator .custom-control {
    display: block !important;
    margin-top: 10px; }
    @media (max-width: 575.98px) {
      .event-task-indicator .custom-control {
        margin-top: 0; } }
  .event-task-indicator .event-task-indicator--item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%; }
  .event-task-indicator .indicator {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    padding-left: 12px;
    margin-right: 15px;
    margin-left: 5px;
    position: relative; }
    .event-task-indicator .indicator::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      border-radius: 30px; }
    @media (max-width: 575.98px) {
      .event-task-indicator .indicator {
        margin-right: 25px;
        margin-bottom: 10px; } }
  .event-task-indicator .indicator.task::before {
    background-color: #46A7FA; }
  .event-task-indicator .indicator.event::before {
    background-color: #00cc99; }
  @media (max-width: 1199.98px) {
    .event-task-indicator {
      margin-bottom: 10px; } }
  @media (max-width: 575.98px) {
    .event-task-indicator {
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      -webkit-flex-direction: column;
              flex-direction: column;
      height: unset; } }

@media (min-width: 767.98px) and (max-width: 991.98px) {
  .mdh-card--nurse span.mdh-acc-text--date-sub-head {
    font-size: 13px; } }

.care-team-wrap .care-team__dropdown-toggle {
  margin: 0 auto;
  background-color: transparent !important;
  border: none !important;
  color: #44cc98 !important; }
  .care-team-wrap .care-team__dropdown-toggle:after {
    content: "";
    display: none; }

.care-team-wrap .dropdown-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 129px;
  min-width: 233px;
  padding: 10px !important;
  right: -15px;
  top: 32px; }
  .care-team-wrap .dropdown-menu:after, .care-team-wrap .dropdown-menu::before {
    right: 50% !important; }

.care-team-wrap .dropdown-menu.show {
  right: -15px;
  top: 32px; }

.care-team-wrap .care-team__dropdown-item {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: 13px;
  color: #888888; }
  .care-team-wrap .care-team__dropdown-item a {
    color: #44cc98 !important; }
    .care-team-wrap .care-team__dropdown-item a:hover {
      text-decoration: none; }

.care-team-wrap .mdh-reactable tbody tr:last-child td:first-child {
  padding-bottom: 0 !important; }

.care-team-wrap .resources-table__link-icon {
  margin-left: 10px; }

.care-team-wrap .mdh-reactable td {
  padding: 15px 32px; }

.careteam-modal__team-item {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%; }

.careteam-modal__team-item--text {
  font-size: 15px;
  font-weight: 600;
  color: #333333;
  padding-left: 20px; }

@media (max-width: 1198.98px) {
  .care-team-wrap .resources-table__link-icon {
    margin-top: 6px; }
  .care-team-wrap .dropdown-menu.show,
  .care-team-wrap .dropdown-menu {
    right: -70px; } }

@media (max-width: 991.98px) {
  .care-team-wrap .dropdown-menu.show,
  .care-team-wrap .dropdown-menu {
    right: -70px; } }

@media (max-width: 767.98px) {
  .care-team-wrap .table-responsive {
    overflow-x: auto; }
  .care-team-wrap .dropdown-menu.show,
  .care-team-wrap .dropdown-menu {
    right: -70px; } }

.care-team-wrap .dropdown-menu {
  display: none; }

.care-team-wrap .dropdown-menu.show {
  display: -webkit-flex;
  display: flex; }

.call-center-wrap .mdh-reactable tbody tr:last-child td:first-child {
  padding-bottom: 2px;
  padding-top: 24px; }

.call-center-wrap .mdh-reactable td {
  padding: 16px 32px; }

.call-center-wrap .careteam-modal__team-item--text:hover,
.call-center-wrap .careteam-modal__team-item--text:focus,
.call-center-wrap .careteam-modal__team-item--text:active {
  text-decoration: none;
  color: #000000; }

.call-center-profile__head-btn .btn-primary,
.call-center-profile__head-btn .btn-outline-primary {
  margin: 8px 13px; }

.call-center-profile-wrap {
  padding: 10px 33px; }
  .call-center-profile-wrap .careteam-modal__team-item {
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
  .call-center-profile-wrap .mdh-forms {
    padding: 15px; }
  .call-center-profile-wrap .call-center-profile__head {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 38px 0;
    border-bottom: solid 1px #E0E0E0; }
    .call-center-profile-wrap .call-center-profile__head .careteam-modal__team-item--text {
      font-size: 24px; }
  .call-center-profile-wrap .call-center-profile__call-request .call-center-profile__call-request-head,
  .call-center-profile-wrap .call-center-profile__call-request .call-center-profile__call-details-head,
  .call-center-profile-wrap .call-center-profile__call-details .call-center-profile__call-request-head,
  .call-center-profile-wrap .call-center-profile__call-details .call-center-profile__call-details-head {
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    padding: 10px;
    border-bottom: 1px solid #E0E0E0; }
  .call-center-profile-wrap .call-center-profile__call-request-item {
    padding: 17px 0; }
    .call-center-profile-wrap .call-center-profile__call-request-item .call-center-profile__call-request-item-text {
      padding-left: 60px;
      color: #333333;
      font-size: 15px;
      margin-bottom: 0;
      padding-bottom: 16px; }
    .call-center-profile-wrap .call-center-profile__call-request-item:last-child {
      padding-bottom: 21px;
      border-bottom: 1px solid #E0E0E0; }
  .call-center-profile-wrap .call-center-profile__call-request-link {
    font-weight: 600;
    font-size: 15px;
    color: #137878;
    padding-left: 25px; }
  .call-center-profile-wrap .call-center-profile__call-request-link:hover,
  .call-center-profile-wrap .call-center-profile__call-request-link:focus,
  .call-center-profile-wrap .call-center-profile__call-request-link:active {
    text-decoration: none;
    color: #137878; }
  .call-center-profile-wrap .call-center-profile__call-details-item {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 43px; }
    .call-center-profile-wrap .call-center-profile__call-details-item .call-center-profile__call-details-item-head {
      padding-left: 10px;
      font-weight: 600;
      font-size: 16px;
      color: #333333; }
  .call-center-profile-wrap .call-center-profile__call-details-sub-item {
    border-top: 1px solid #e0e0e0;
    padding-top: 15px; }
    .call-center-profile-wrap .call-center-profile__call-details-sub-item td {
      padding: 10px; }

@media (max-width: 767.98px) {
  .call-center-profile-wrap .careteam-modal__team-item {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding-left: 20px; } }

.custom-react-select--resources {
  width: 100% !important; }

@media (max-width: 767.98px) {
  .mdh-reactable .resources-table__link-icon {
    padding: 9.5px 10.5px; } }

.careteam-modal__team-item--text {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  padding-left: 5px;
  cursor: pointer; }
  .careteam-modal__team-item--text:hover, .careteam-modal__team-item--text:focus, .careteam-modal__team-item--text:active {
    text-decoration: none; }

@media (max-width: 767.98px) {
  .custom-react-select--resources {
    margin-left: 0;
    margin-top: 8px; }
  .resources-top-section form {
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .careteam-modal__team-item--text {
    padding-left: 8px; }
  .call-center-profile__head-btn .btn-primary,
  .call-center-profile__head-btn .btn-outline-primary {
    margin: 8px 13px 8px 0; } }

.drop-menu-in-table {
  width: 100%;
  display: block; }
  .drop-menu-in-table .table-responsive {
    overflow-x: unset; }
    @media (max-width: 1199.98px) {
      .drop-menu-in-table .table-responsive {
        overflow-x: auto; } }

.rain-toggle-drop-menu .dropdown-menu {
  display: none; }

.rain-toggle-drop-menu .dropdown-menu.show {
  display: -webkit-flex;
  display: flex; }

.patients-dependents-list {
  position: relative;
  width: 100%;
  background-color: #ebfbf7;
  border: solid 1px rgba(19, 120, 120, 0.1);
  border-radius: 4px;
  padding: 8px 8px;
  margin-bottom: 15px; }
  .patients-dependents-list .has-access {
    float: right; }
  .patients-dependents-list .dependent-remove-text {
    color: red;
    padding-left: 5px;
    font-weight: 600; }
  .patients-dependents-list .dependent-name-text {
    font-weight: 600; }

.import-companies-modal__custom-input-wrap.dependent-alert-box {
  font-size: 15px;
  color: #333333 !important;
  background-color: #F7F8E7 !important;
  border-color: #DDE63C !important;
  border-radius: 3px;
  padding: 15px; }

.profile-details-wrap .profile-details__head {
  padding-top: 7px; }
  .profile-details-wrap .profile-details__head .profile-details__head-text {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.95px;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 0; }
  .profile-details-wrap .profile-details__head .profile-details__head-pam-score {
    font-size: 15px;
    line-height: 25px;
    color: #137878;
    background: rgba(0, 204, 153, 0.063937);
    width: 118px;
    height: 24.5px;
    padding: 2px;
    margin-left: 14px;
    vertical-align: text-bottom; }
  .profile-details-wrap .profile-details__head .profile-details__head-last-updated .profile-details__head-last-updated--text {
    font-size: 15px;
    line-height: 25px;
    color: #333333;
    margin-bottom: 0; }
    .profile-details-wrap .profile-details__head .profile-details__head-last-updated .profile-details__head-last-updated--text span {
      font-weight: 600; }
  .profile-details-wrap .profile-details__head .profile-details__head-item .profile-details__head-item-links {
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    color: #00cc99;
    padding-right: 7px; }
    .profile-details-wrap .profile-details__head .profile-details__head-item .profile-details__head-item-links:hover, .profile-details-wrap .profile-details__head .profile-details__head-item .profile-details__head-item-links:focus, .profile-details-wrap .profile-details__head .profile-details__head-item .profile-details__head-item-links:active {
      text-decoration: none; }
  .profile-details-wrap .profile-details__head .profile-details__head-item .profile-details__head-item-dashes {
    font-size: 15px;
    line-height: 25px;
    color: #888888; }

.profile-details-wrap .mdh-tabs--demographics .nav-item {
  width: 11%; }

.profile-details-wrap .mdh-tabs .nav-item .nav-link {
  padding: 15px;
  font-size: 15px; }

.profile-details-wrap .navigation-tab-scrollor {
  margin-top: 0; }

.profile-details-wrap .mdh-acc-collapse {
  margin-top: 15px; }

.profile-details-wrap .mdh-accordion-level2 .mdh-accordion-level2--head {
  padding-bottom: 8px; }

.profile-details-tabs__demographics-wrap .profile-details-tabs__demographics-footer {
  margin-top: 17px;
  margin-bottom: 9px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .profile-details-tabs__demographics-wrap .profile-details-tabs__demographics-footer .profile-details-tabs__demographics-footer-hipaa-text {
    font-family: 'OpenSans', sans-serif;
    font-size: 12px;
    line-height: 14px;
    padding: 11px;
    padding-top: 14px;
    padding-left: 0;
    margin-bottom: 21px; }
    .profile-details-tabs__demographics-wrap .profile-details-tabs__demographics-footer .profile-details-tabs__demographics-footer-hipaa-text .profile-details-tabs__demographics-footer-hipaa-link {
      color: #137878; }
      .profile-details-tabs__demographics-wrap .profile-details-tabs__demographics-footer .profile-details-tabs__demographics-footer-hipaa-text .profile-details-tabs__demographics-footer-hipaa-link:hover, .profile-details-tabs__demographics-wrap .profile-details-tabs__demographics-footer .profile-details-tabs__demographics-footer-hipaa-text .profile-details-tabs__demographics-footer-hipaa-link:focus, .profile-details-tabs__demographics-wrap .profile-details-tabs__demographics-footer .profile-details-tabs__demographics-footer-hipaa-text .profile-details-tabs__demographics-footer-hipaa-link:active {
        text-decoration: none; }

.profile-details-tabs__medical-history-wrap .profile-details-tabs__medical-history-allergies-wrap {
  padding-top: 3px;
  padding-bottom: 54px; }
  .profile-details-tabs__medical-history-wrap .profile-details-tabs__medical-history-allergies-wrap .profile-details-tabs__medical-history-allergies {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .profile-details-tabs__medical-history-wrap .profile-details-tabs__medical-history-allergies-wrap .profile-details-tabs__medical-history-allergies .profile-details-tabs__medical-history-allergies-text {
      font-size: 18px;
      line-height: 40px;
      color: #000000;
      margin-bottom: 0;
      padding-left: 16px; }

.profile-details-tabs__medical-history-wrap .profile-details-tabs__medical-history-guarantor-wrap {
  padding-bottom: 9px;
  padding-top: 15px; }

.profile-details-tabs__medical-history-wrap .btn-wrapper {
  -webkit-align-items: center;
          align-items: center; }

.profile-details-tabs__medical-history-wrap .assessment-summary--textarea {
  height: 150px; }

.care-plan-wrap .care-plan__section-heading {
  font-size: 16px;
  line-height: 20px;
  color: #000000 !important;
  padding-top: 25px; }

.care-plan-wrap .mdh-tabs--demographics {
  white-space: nowrap;
  overflow-y: auto;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap; }

.care-plan-wrap .mdh-tabs--demographics .nav-item {
  width: 20% !important;
  margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .care-plan-wrap .mdh-tabs--demographics .nav-item {
      width: auto !important; } }

.care-plan-wrap .cp-date {
  margin-bottom: 16px; }

.care-plan-wrap .completed .plan-status {
  background-color: #ccc;
  box-shadow: 0px 0px 15px rgba(204, 204, 204, 0.514); }

.care-plan-wrap .mdh-acc-arrow {
  width: 100%; }

.tab-content > .tab-pane {
  padding: 14px 30px; }

.mdh-modal--add-care-plan .custom-control-wrapper {
  margin-left: 0;
  height: auto; }

.btn-outline-secondary.btn-primary {
  box-shadow: none !important; }

.mdh-modal--add-care-plan .custom-control-label {
  margin-left: 22px; }

.medical-diagnosis-form {
  margin-top: 23px; }
  .medical-diagnosis-form .form-control {
    min-height: 42px !important; }
  .medical-diagnosis-form .mdh-acc-collapse {
    margin-top: 22px; }
    .medical-diagnosis-form .mdh-acc-collapse .form-group {
      margin-bottom: 24px; }

.medical-diagnosis-form-group {
  margin-bottom: 14px !important; }
  .medical-diagnosis-form-group .medical-diagnosis-card-wrap {
    background-color: #F4F9F9;
    margin: 1px !important;
    padding: 10px; }
  .medical-diagnosis-form-group .medical-diagnosis-card-delete {
    border: 1px solid #E63C3C;
    color: #E63C3C;
    border-radius: 3px;
    width: 41px;
    height: 41px;
    margin-bottom: 0; }

.care-plan__modal-add-btn {
  padding: 8.5px 19.5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 0 !important;
  border-radius: 3px;
  width: 100%; }
  @media (max-width: 767.98px) {
    .care-plan__modal-add-btn {
      padding: 0 19.5px;
      font-size: 30px; } }

.profile-details-head-wrap {
  float: left;
  width: 100%; }

.profile-details-tabs__medical-providers-wrap {
  margin-top: 0 !important; }
  .profile-details-tabs__medical-providers-wrap .profile-details-tabs__medical-providers-guarantor-wrap {
    padding-bottom: 22px;
    padding-top: 0; }

.profile-details-tabs__progress-note-wrap .profile-details-tabs__progress-note--head, .profile-details-tabs__visits-wrap .profile-details-tabs__progress-note--head {
  padding-top: 7px;
  border-bottom: 2px solid #ededed;
  margin-bottom: 23px; }
  .profile-details-tabs__progress-note-wrap .profile-details-tabs__progress-note--head .profile-details-tabs__progress-note--head-text, .profile-details-tabs__visits-wrap .profile-details-tabs__progress-note--head .profile-details-tabs__progress-note--head-text {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    margin-bottom: 5px; }

.profile-details-tabs__progress-note-wrap .profile-details-tabs__progress-note-viewed, .profile-details-tabs__visits-wrap .profile-details-tabs__progress-note-viewed {
  font-weight: 600;
  font-size: 12px;
  color: #FFFFFF;
  background-color: #00cc99;
  text-transform: uppercase;
  padding: 0 4px;
  margin-left: auto;
  margin-right: 18px;
  margin-bottom: 0; }

.profile-details-tabs__progress-note-wrap .profile-details-tabs__progress-note-not-viewed, .profile-details-tabs__visits-wrap .profile-details-tabs__progress-note-not-viewed {
  font-weight: 600;
  font-size: 12px;
  color: #ACACAC;
  background-color: #E8E8E8;
  text-transform: uppercase;
  padding: 0 4px;
  margin-left: auto;
  margin-right: 18px;
  margin-bottom: 0; }

.profile-details-tabs__progress-note-wrap .btn-icon, .profile-details-tabs__visits-wrap .btn-icon {
  width: 23.63px;
  height: 23.63px; }

.profile-details-tabs__progress-note-wrap .notes-outer-wrapper::-webkit-scrollbar-thumb, .profile-details-tabs__visits-wrap .notes-outer-wrapper::-webkit-scrollbar-thumb {
  background: rgba(19, 120, 120, 0.23); }

.profile-details-tabs__progress-note-wrap .notes-outer-wrapper--grid li, .profile-details-tabs__visits-wrap .notes-outer-wrapper--grid li {
  padding: 15px 21px;
  padding-bottom: 8px; }

.profile-details-tabs__progress-note-wrap .notes-outer-wrapper, .profile-details-tabs__visits-wrap .notes-outer-wrapper {
  height: auto;
  margin-bottom: 4px; }

.mdh-modal--add-progress-notes {
  margin-top: 18%;
  max-width: 753px; }
  .mdh-modal--add-progress-notes .modal-header, .mdh-modal--add-progress-notes .modal-footer {
    border: none; }
  .mdh-modal--add-progress-notes .mdh-modal--add-progress-notes-input {
    width: 100%;
    height: 213px;
    border: 1px solid #C5C5C5;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 15px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    color: #333333; }

.mdh-accordion-level2--head.disabled {
  color: #acacac; }
  .mdh-accordion-level2--head.disabled .handle--plus {
    background: transparent url("/images/global/plus-disabled.svg") no-repeat center; }
  .mdh-accordion-level2--head.disabled .handle--minus {
    background: transparent url("/images/global/minus-disabled.svg") no-repeat center; }

.profile-details__head-red-link-wrap {
  float: left;
  width: 100%; }
  .profile-details__head-red-link-wrap .known-allergies--list li {
    margin: 10px 14px 10px 0; }
    .profile-details__head-red-link-wrap .known-allergies--list li a {
      background-color: rgba(230, 60, 60, 0.06696);
      color: #E63C3C;
      padding: 4px 14px; }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .care-plan-wrap .mdh-tabs--demographics .nav-item {
    width: 25% !important; } }

.patients-profile-dependents {
  position: relative;
  width: 100%;
  background-color: #fafafa;
  border: solid 1px rgba(19, 120, 120, 0.1);
  border-radius: 4px;
  padding: 8px 8px;
  margin-bottom: 15px; }
  .patients-profile-dependents .has-access {
    float: right; }
  .patients-profile-dependents .dependent-remove-text {
    color: red;
    padding-left: 5px;
    font-weight: 600; }
  .patients-profile-dependents .dependent-name-text {
    font-weight: 600;
    padding-left: 20px; }

.has-access-wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 20px; }
  @media (max-width: 575.98px) {
    .has-access-wrapper {
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      margin-top: 5px; } }

.user-type {
  position: relative;
  float: left;
  color: #333;
  font-size: 12px;
  margin-left: 22px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .user-type:first-child {
    margin-left: 0px; }

.p-badge {
  position: relative;
  width: 24px;
  height: 24px;
  background-color: red;
  border-radius: 50%;
  float: left;
  margin-right: 6px;
  line-height: 1;
  text-align: center;
  font-size: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-transform: uppercase; }

.patient {
  color: #72761F;
  background-color: #DDE63C; }

.team-member {
  color: #fff;
  background-color: #137878; }

.task-status {
  width: 16px;
  height: 16px;
  border-radius: 50%; }

.task-status--green {
  background: #00CC99;
  box-shadow: 0px 0px 15px rgba(0, 204, 153, 0.3); }

.task-status--grey {
  background: #E1E1E1; }

.nurse-tasks-table .mdh-table--nurse .actions-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

@media (max-width: 1199.98px) {
  .mdh-table tr td, .mdh-table tr th {
    white-space: nowrap; } }

.mdh-card--admin .mdh-card__head {
  padding: 10px 12px 10px 25px; }

.mdh-card--admin .dashboard__recent--label {
  padding: 8px 16px; }

.mdh-card--admin .dashboard__recent {
  max-height: none;
  overflow: unset; }

.admin-graph-widgets {
  position: relative;
  width: 100%;
  padding: 20px;
  float: left;
  border-bottom: solid 1px #e6e6e6; }

.widget-item {
  height: 99px;
  border-right: solid 1px #e6e6e6;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.no-border {
  border: none !important; }

.graph-wrap {
  width: 71px;
  height: 71px;
  float: left; }

.graph-value {
  margin-left: 4%; }
  .graph-value h3 {
    font-weight: 600;
    font-size: 20px;
    color: #333333;
    margin-bottom: 16px; }
  .graph-value ul {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0; }
    .graph-value ul li {
      list-style: none;
      float: left;
      margin-right: 20px; }
      .graph-value ul li span {
        position: relative;
        width: 12px;
        height: 12px;
        background-color: #ccc;
        float: left;
        top: 5px;
        border-radius: 50%;
        margin-right: 5px; }

.admin-line-chart-wrap {
  width: 100%;
  padding: 24px 40px;
  float: left; }

.dummy-height {
  height: 300px; }

.admin-dashboard-table-outer {
  width: 100%;
  padding: 0 10px 10px 10px; }

@media (max-width: 767.98px) {
  .widget-item {
    border-right: none;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; } }

.custom-react-select--admin-top-section {
  margin-bottom: 0px; }
  .custom-react-select--admin-top-section .react-select__control {
    min-height: 32px !important; }
  .custom-react-select--admin-top-section .react-select__value-container {
    padding: 0 8px; }
  .custom-react-select--admin-top-section .react-select__indicator {
    padding: 4px; }

@media (max-width: 1199.98px) {
  .resources-top-section .form-group {
    margin: 5px 0; } }

.generic-file-upload {
  position: relative;
  width: 100%;
  border: 1px dashed #979797;
  border-radius: 4px;
  text-align: center;
  padding: 0;
  float: left;
  height: 300px; }
  .generic-file-upload .filepond--drop-label {
    height: 298px !important;
    background: #fff; }
  .generic-file-upload p {
    margin: 10px 0 0 0;
    font-size: 16px;
    color: #888; }
    .generic-file-upload p a {
      color: #00CC99; }
    .generic-file-upload p span {
      color: #00CC99;
      cursor: pointer; }

.file-upload__head--text {
  margin-bottom: 12px !important;
  margin-top: 6px !important;
  font-size: 22px !important; }

.import-companies-success__head-text {
  width: 100%;
  text-align: center;
  margin-top: 36px;
  margin-bottom: 0 !important;
  font-size: 28px !important; }

.import-companies-success__sub-text {
  color: #888888;
  font-size: 20px; }

.import-companies-modal__table-wrap {
  border: 1px solid #ededed;
  height: 310px !important;
  overflow: auto; }

.import-companies-modal__success-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 30px;
  padding-bottom: 40px; }

.mdh-global-card-ligth-green {
  background: rgba(0, 204, 153, 0.103283);
  border: 1px solid #00CC99;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(0, 204, 153, 0.148492);
  border-radius: 8px; }

.assigned-members-table thead tr, .assigned-members-table thead tr th {
  border-top: none !important; }

.assigned-members-table thead th:last-child {
  text-align: right !important; }

.assigned-members-table tbody td:last-child {
  color: #888;
  text-align: right !important; }

.member--image {
  border-radius: 50%;
  margin-right: 20px; }

.members__care-team-head-wrap {
  width: 100%;
  padding: 31px; }

.members__care-team-head-sm-card-wrap .mdh-card {
  border-radius: 8px;
  padding: 5px 15px;
  padding-bottom: 14px;
  margin-left: 16px;
  width: 180px; }
  @media (max-width: 574.98px) {
    .members__care-team-head-sm-card-wrap .mdh-card {
      margin-left: 0px; } }

.members__care-team-head-sm-card-wrap .profile-details__head-last-updated--text {
  padding: 12px 6px; }

.members__care-team-head-sm-card-wrap h4.text-center {
  font-size: 18px; }

.members__care-team-head-sm-card-head--text {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: 0.7125px;
  color: #333333;
  margin-bottom: 0;
  position: relative; }

.members__care-team-head-sm-card-head--subtext {
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 0.6175px;
  color: #333333;
  margin-bottom: 0;
  position: relative; }

.admin__members-care-team-wrap .custom-checkbox--fav .custom-control-label::after {
  top: 7px !important;
  left: -18.9px !important; }

.members__care-team-head-card-wrap {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.members__care-team-head-sm-card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center; }

@media (max-width: 575.98px) {
  .members__care-team-head-sm-card .mdh-card {
    width: 100% !important; }
  .members__care-team-head-sm-card-wrap {
    width: 100%; } }

@media (max-width: 767.98px) {
  .admin__members-care-team-wrap .custom-checkbox--fav {
    float: left; } }

.members__care-team-steps {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px 0; }

.members__care-team-steps-text {
  font-size: 20px; }
  .members__care-team-steps-text span {
    font-weight: 600; }

.members__care-team-steps-select-nurse--btn {
  padding: 5px 15px !important; }

@media (max-width: 767.98px) {
  .members__care-team-steps {
    display: block; }
  .members__care-team-steps-text {
    margin-bottom: 10px !important; } }

.members-profile-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden; }
  .members-profile-image img {
    width: 100%;
    height: 100%; }

.loading .members-profile-image {
  background-color: #F2F2F2; }

.loading .members-profile-image img {
  display: none; }

.loading .members__care-team-head-sm-card-head--text {
  background-color: #F2F2F2;
  text-indent: -99999999em;
  margin-bottom: 5px; }

.loading .members__care-team-head-sm-card-head--subtext {
  background-color: #F2F2F2;
  text-indent: -99999999em; }

