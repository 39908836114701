.mdh-tabs .nav-item .nav-link{
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    font-weight: 600;
    padding:15px 12px !important;
    cursor: pointer;
    color: $mdh-grey150;
    transition: .4s all;
}
@media( max-width:1199.98px){
    .mdh-tabs .nav-item{
        padding-right:2px;
    }
    .mdh-tabs .nav-item .nav-link{
        padding:15px 4px !important;
    }
}
.mdh-tabs .nav-item .nav-link:first-child{
    margin-left: 0px;
}

// .mdh-tabs .nav-item .nav-link .badge{
//     display: none !important;
// }

.mdh-tabs .nav-item.show .nav-link, .mdh-tabs  .nav-link.active{
    position: relative;
    border-color: transparent transparent transparent transparent;
    color: $mdh-green300;
}
.mdh-tabs .nav-item.show .nav-link, .mdh-tabs  .nav-link.active:after{
    position: absolute;
    content:'';
    width:100%;
    height:1px;
    bottom:-1px;
    left:0;
    background-color:$mdh-green300;
    box-shadow: 0px 0px 20px 1px $mdh-green300;
}
.mdh-tabs .nav-link:focus, .mdh-tabs .nav-link:hover{
    border-color: transparent transparent transparent transparent;
    color: $mdh-green300 !important;
}
.tab-content > .active {
    -webkit-animation: fadein 0.3s ease-in-out;
    animation: fadein 0.3s ease-in-out;
}
.tab-content>.tab-pane{
    padding:30px;
}
.form-filled .badge{
    display: none !important;
}


.navigation-tab-scrollor {
    max-width: 100%;
    height: 55px;
    position: relative;
    overflow: hidden;
    margin-top: 15px;
    border-bottom: 1px solid #dee2e6;
}

.navigation-tab-scrollor .mdh-tabs {
    padding-bottom: 2rem;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    margin-top: 0 !important;
    flex-wrap:nowrap;
}

.navigation-tab-scrollor .nav-link.active:after {
    bottom: -1px;
}

@media (max-width: 767.98px) {
    .tab-content > .tab-pane{
        padding:20px;
    }
    .nav-tabs-scroll {
        max-width: 100%;
        height: 53px;
        position: relative;
        overflow: hidden;
        margin-top: 15px;
        border-bottom: 1px solid #dee2e6;
    }

    .nav-tabs-scroll .mdh-tabs {
        padding-bottom: 2rem;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        margin-top: 0 !important;
        flex-wrap:nowrap;
    }

    .nav-tabs-scroll .nav-link.active:after {
        bottom: 1px;
    }
}
@media ( max-width:565.98px){
    .tab-content > .tab-pane {
        padding: 14px 10px !important;
    }
}
