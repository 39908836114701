.has-table-dd .table-responsive{
    overflow-x: unset;
}
@media( max-width:1199.98px){
    .has-table-dd .table-responsive{
        overflow-x: auto;
    }
}


@media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered {
      border: 0;
    }
  }

  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }

  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }

  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered {
      border: 0;
    }
  }
.mdh-table{
    margin-bottom: 0px;
}
.mdh-table thead tr th {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: $mdh-grey;
    padding: 16px 0 16px 19px;
    text-align:center;
    border-bottom-width: 1px;
}
.mdh-table thead tr th:first-child, .mdh-table thead tr th:last-child{
    text-align: left;
}
.mdh-table tbody tr td, .mdh-table tbody tr th  {
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: $mdh-grey-200;
    vertical-align: middle;
    padding: 0px 0  0px 19px;
    height: 100px;
}
.mdh-table tbody tr td{
    text-align: center;

    .btn-icon {
        align-items: center;
    }
}
.mdh-table tbody tr td:last-child{
    text-align: left;
}
.mdh-table tbody tr th{
    color: $mdh-grey;
    text-align: left;
}
.mdh-table .info{
    margin-left:4px;
}
.mdh-table .actions-wrap{
    min-width: 85px;
    display: flex;
    justify-content: center;
}
@media (max-width:767.98px){
    .mdh-table thead tr th, .mdh-table tbody tr th, .mdh-table tbody tr td{
        white-space: nowrap;
    }
}
.mdh-table--admin {
    th{
        border-top: none;
        white-space: nowrap;
        text-align: center;
        padding: 18px 13px;
        border-top: 1px solid #dee2e6 !important;

        &:first-child{
            text-align: left !important;
        }

    }
    tr{
        border-top: 1px solid #dee2e6 !important;
        &:first-child{
            border-top: none;
        }
    }
    td{
        padding: 13px 13px;
        color: #888888;
        border: none;
        text-align: center;
        vertical-align: middle;
        @media( max-width:991.98px){
            white-space: nowrap;
        }
        p{
            margin: 0;
            padding:0;
        }
        h4{
            margin: 0;
        }
        &:first-child{
            text-align: left;
            color: #333333 ;

            span{
                position: relative;
                width: 24px;
                height: 24px;
                background-color: #E7F1F1;
                float: left;
                margin-right:10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
        }
        &:last-child{
            text-align: center !important;
        }
        .company-name{
            font-weight: 600;
            color: #137878;
            display: flex;
            align-items: center;
        }
    }
    .actions-wrap{
        text-align: center;
        display: flex;
        vertical-align: middle;
        justify-content: center;
    }
}

.mdh-table--admin-upload tbody {
    max-height: 200px;
    width: 100%;
    overflow-y: auto;
    display: inline-block;
}
.mdh-table--admin-upload td:first-child {
    text-align: left;
    color: #333333;
}
.mdh-table--admin-upload tr{
    height: 40px;
    width: 100%;
    display: inline-table;
}
.mdh-table--admin-upload td {
    color: #888888;
    border: none;
    text-align: center;
    vertical-align: middle;
    padding:0;

}
.mdh-table--admin-upload td:last-child{
    padding-right:10px;
}

.mdh-table--admin-upload tbody::-webkit-scrollbar {
    width: 10px;
}

.mdh-table--admin-upload tbody::-webkit-scrollbar-thumb {
    background-color: #137878;
    border-radius: 50px;
}

.mdh-table--admin-upload tbody::-webkit-scrollbar-track {
    background-color: #fafafa;
}

@media( max-width:1199.98px){
    .mdh-table--admin th, .mdh-table--admin td{
        white-space: nowrap;
    }
}

.mdh-table--nurse.task-table {
    th {
        text-align: left;

        &:last-child{
            text-align: center !important;
        }
    }
    td {
        text-align: left;

        &:last-child{
            text-align: center !important;
        }
    }
    .assigned-to {
        width: 18%;

        @media(max-width: 767.98px) {
            width: 100%;
        }
    }
    .p-badge {
        float: right;
        margin-left: 8px;
    }
    @media(max-width: 1199.98px) {
        .action-icon {
            width: 28px;
            height: 28px;
        }
    }
    .tasks-checkbox--item-strike {
        text-decoration: line-through;
    }
    .rain-toggle-drop-menu.action-icon .rain-toggle.action-icon--remove {
        background-color: #FCEBEB !important;
    }
    .rain-toggle-drop-menu.action-icon .rain-toggle {
        background-color: #E8F2F2 !important;
    }
}

.resources-top-section .user-type-col {
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 767.98px) {
        justify-content: flex-start;
    }
}

.mdh-table--nurse {
    th{
        border-top: none;
        white-space: nowrap;
        text-align: center;
        padding: 18px 13px;
        border-top: 1px solid #dee2e6 !important;

        &:first-child{
            text-align: left !important;
        }

    }
    tr{
        border-top: 1px solid #dee2e6 !important;
        &:first-child{
            border-top: none;
        }
    }
    td{
        padding: 13px 13px;
        color: #888888;
        border: none;
        text-align: center;
        vertical-align: middle;
        p{
            margin: 0;
            padding:0;
        }
        h4{
            margin: 0;
        }
        &:first-child{
            text-align: left;
            color: #333333 ;

            span{
                position: relative;
                width: 24px;
                height: 24px;
                background-color: #E7F1F1;
                float: left;
                margin-right:10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
        }
        &:last-child{
            text-align: center !important;
        }
        .company-name{
            font-weight: 600;
            color: #137878;
            display: flex;
            align-items: center;
        }
    }
    .actions-wrap{
        max-width: 135px;
        min-width: 90px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
    }
}
@media( max-width:1199.98px){
    .mdh-table--nurse td{
        white-space: nowrap;
    }
    .bulk-assign-care-team-table tr th, .bulk-assign-care-team-table tr td{
        white-space: nowrap;
    }
}

.solo-icon{
    width: 36px;
    height: 36px;
    background-color: #F5F9F9 !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.solo-icon-outer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.add-members-table-wrap,
.bulk-care-team-table-wrap  {
    border: 1px solid #dedede;
    box-shadow: 0px 0px 3px 0px #dedede;
    margin-bottom: 25px;
}
.start-chat-table{
    thead{
        th{
            &:first-child{
                width: 65%;
            }
            &:last-child{
                text-align: center;
            }
        }
    }
}

.drop-menu-in-table .task-table {
    .import-companies-modal__custom-input-wrap {
        .custom-checkbox .custom-control-label::before {
            top: -10px !important;
        }
        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
            top: -5px !important;
        }
    }
}
.mdh-table--nurse.task-table .tasks-checkbox--item-strike {
    margin-bottom: 0 !important
}
.notification-table .notification-btn-link{
    color: $mdh-blue !important;
    cursor: pointer;
}
