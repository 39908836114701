.rbc-calendar{
    height: calc(100vh - 185px) !important;
}
.rbc-toolbar{
    margin-bottom: 0 !important;
    background-color: #f3f8f8;
    padding: 13px 10px;
    border-top-left-radius:3px;
    border-top-right-radius:3px;
    .rbc-btn-group{
        button{
            margin-right:8px;
            margin-left:8px;
            padding:3px 10px;
            border-radius: 3px !important;
            color: #137878;
            border: solid 1px #137878;
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    .rbc-toolbar-label{
        font-size: 18px;
        text-align: center;
        letter-spacing: 3px;
        color: #137878;
        text-transform: uppercase;
    }

}

.rbc-toolbar .rbc-btn-group button:hover,
.rbc-toolbar .rbc-btn-group button:focus,
.rbc-toolbar .rbc-btn-group button:active,
.rbc-toolbar .rbc-btn-group button.rbc-active,
.rbc-toolbar .rbc-btn-group button.rbc-active:hover{
    background-color: #137878;
    box-shadow: none;
    color: #fff;
    font-weight: 400;
    border: solid 1px #137878;
}
.rbc-header{
    padding:20px 3px !important;
    font-weight: 400 !important;
    text-transform: uppercase;
    color: #888 !important;
    letter-spacing: 2px;
}
.rbc-header + .rbc-header{
    border-left: none !important;
}
.rbc-off-range-bg {
    background: #fbfafb;
}
.rbc-date-cell{
    padding-right:20px !important;
    padding-top:20px;
}
.rbc-event{
    border-radius: 0px !important;
    text-align: center !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
}
.rbc-event-label {
    color: $mdh-black;
}
.rbc-today{
    background-color: #fff !important;
}
.rbc-current a{
    width: 30px;
    height: 30px;
    background-color: #137878;
    position: relative;
    color: #fff !important;
    font-weight: 400;
    float: right;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
}
.rbc-event--task{
    background-color: #46A7FA !important;
    margin:0px !important;
    height: 100%;
    min-height: 10px;
    color: $mdh-white;
    display: flex;
    align-items: center;
}
.rbc-event--appointment{
    background-color: #00cc99 !important;
    margin:0px !important;
    height: 100%;
    min-height: 10px;
    color: $mdh-white;
    display: flex;
    align-items: center;
}
