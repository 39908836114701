.mdh-reactable{
   th{
       padding:18px 12px;
       border-bottom: none !important;
       text-align: center;
    &:first-child{
        text-align: left;
    }

   }
   td{
    padding:28px 12px;
    color: $mdh-grey-200;
    vertical-align: middle;
    text-align: center;
    &:first-child{
        text-align: left;
    }
    .actions-wrap{
        display: flex;
        justify-content: center;
    }
}
    .reactable-column-header th{
        font-size: 16px;
        color: $mdh-grey;
    }
}
.resource-icon{
    display: flex;
    align-items:center;
    font-size: 16px;
    cursor: default;
    color: $mdh-grey;
    &:hover{
        text-decoration: none;
        color: $mdh-grey;
    }
}


.sortable:after{
    position: relative;
    display: inline-block;
    content: '';
    width: 15px;
    height: 14px;
    top: 1px;
    left: 6px;
    background: transparent url("/images/global/up-and-down.svg") no-repeat;
 }
@media( max-width:767.98px ){
    .mdh-reactable th, .mdh-reactable td{
        white-space: nowrap;
    }
}
