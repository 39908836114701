// Request a Call -- Date Picker Styles Starts

.mdh-inline-calender .react-calendar:before {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #e0e0e0;
    position: absolute;
    top: 51px;
    left: 0;
    z-index: 1;
}
.mdh-inline-calender {
    width: 100% !important;
    border: none !important;
}
.mdh-inline-calender .react-calendar__navigation{
    border-bottom: solid 1px #e0e0e0;
}
.mdh-inline-calender .react-calendar__navigation__label{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: $mdh-black;
    &:hover{
        background-color: transparent !important;
    }
}
.mdh-inline-calender:before {
    top: 44px;
}
.mdh-inline-calender .react-calendar__navigation__prev-button, .mdh-inline-calender .react-calendar__navigation__next-button{
    position: relative;
    text-indent: -9999999em;
}
.mdh-inline-calender .react-calendar__navigation__prev-button:before{
    position: absolute;
    content: '';
    width: 12px;
    height: 17px;
    top: 13px;
    left: 15px;
    background-image:url('/images/global/in-calender-prev.svg');
    background-repeat:no-repeat;
}
.mdh-inline-calender .react-calendar__navigation__next-button:before{
    position: absolute;
    content: '';
    width: 12px;
    height: 17px;
    top: 13px;
    left: 15px;
    background-image:url('/images/global/in-calender-next.svg');
    background-repeat:no-repeat;
}
.mdh-inline-calender .react-calendar__month-view__weekdays__weekday{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #848B88;
    mix-blend-mode: normal;
    opacity: 0.6;
}
.mdh-inline-calender abbr[data-original-title], .mdh-inline-calender abbr[title]{
    text-decoration: none;
}
.mdh-inline-calender .react-calendar__navigation__prev2-button,
.mdh-inline-calender .react-calendar__navigation__next2-button {
    display: none;
}
.mdh-inline-calender .react-calendar__tile{
    padding:3px 0 !important;
}
.mdh-inline-calender .react-calendar__tile:enabled:hover,
.mdh-inline-calender .react-calendar__tile:enabled:focus,
.mdh-inline-calender .react-calendar__tile--active {
    background-color: transparent;
}

.mdh-inline-calender .react-calendar__tile:enabled:hover abbr,
.mdh-inline-calender .react-calendar__tile:enabled:focus abbr {
    background-color: $mdh-yellow;
}

.mdh-inline-calender .react-calendar__tile abbr {
    height: 38px !important;
    width: 38px !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .4s;
}

.mdh-inline-calender .react-calendar__tile--active abbr {
    height: 38px !important;
    width: 38px !important;
    border-radius: 50%;
    display: flex;
    background-color: $mdh-yellow;
    align-items: center;
    justify-content: center;
    color: $mdh-black;
}

.mdh-inline-calender .tileClass {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Source Sans Pro;
font-style: normal;
font-weight: normal;
font-size: 15px;
}



/*Date Picker Dropdown */
.react-datepicker{
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.0533571);
}

.react-datepicker__header {
    background-color: $mdh-white;
    border-color: #dedede;

}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected {
    background-color: $mdh-yellow !important;
    color: $mdh-black;
    border-radius: 50%;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover {
    background-color: $mdh-yellow !important;
    color: $mdh-black;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover {
    border-radius: 50% !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
    border-radius: 50% !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-top-color: #ccc !important;
}

.react-datepicker {
    border: 1px solid #eaeaea;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #ffffff;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #dadada;
}
