.mdh-card--admin{
    .mdh-card__head{
        padding: 10px 12px 10px 25px;
    }
    .dashboard__recent--label{
        padding: 8px 16px;
    }
    .dashboard__recent{
        max-height: none;
        overflow: unset;
    }
}

.admin-graph-widgets{
    position: relative;
    width: 100%;
    padding:20px;
    float: left;
    border-bottom: solid 1px #e6e6e6;
}
.widget-item{
    height: 99px;
    border-right: solid 1px #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
}
.no-border{
    border: none !important;
}
.graph-wrap{
    width: 71px;
    height: 71px;
    float: left;
}
.graph-value{
    margin-left:4%;
    h3{
        font-weight: 600;
        font-size: 20px;
        color: #333333;
        margin-bottom: 16px;
    }
    ul{
        width: 100%;
        float: left;
        padding:0;
        margin: 0;
        li{
            list-style: none;
            float: left;
            margin-right:20px;
            span{
                position: relative;
                width:12px;
                height: 12px;
                background-color: #ccc;
                float: left;
                top:5px;
                border-radius: 50%;
                margin-right:5px;
            }
        }
    }
}
.admin-line-chart-wrap{
    width: 100%;
    padding:24px 40px;
    float: left;
}
.dummy-height{
    height: 300px;
}
.admin-dashboard-table-outer{
    width: 100%;
    padding:0 10px 10px 10px;
}
@media( max-width:767.98px){
    .widget-item{
        border-right: none;
        justify-content: flex-start;
    }
}
