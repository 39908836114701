.members__care-team-head-wrap {
    width: 100%;
    padding: 31px;
}
.members__care-team-head-sm-card-wrap {

    .mdh-card {
        border-radius: 8px;
        padding: 5px 15px;
        padding-bottom: 14px;
        margin-left:16px;
        width: 180px;
        @media( max-width:574.98px ){
            margin-left: 0px;
        }
    }
    .profile-details__head-last-updated--text {
        padding: 12px 6px;
    }
    h4.text-center {
        font-size: 18px;
    }
}
.members__care-team-head-sm-card-head--text {
    font-weight: 600;
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: 0.7125px;
    color: #333333;
    margin-bottom: 0;
    position: relative;
}
.members__care-team-head-sm-card-head--subtext {
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.6175px;
    color: #333333;
    margin-bottom: 0;
    position: relative;
}
.admin__members-care-team-wrap {

    .custom-checkbox--fav .custom-control-label::after {
        top: 7px !important;
        left: -18.9px !important;
    }
}

.members__care-team-head-card-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.members__care-team-head-sm-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width : 575.98px) {
    .members__care-team-head-sm-card .mdh-card {
        width: 100% !important;
    }
    .members__care-team-head-sm-card-wrap {
        width: 100%;
    }
}
@media (max-width : 767.98px) {
    .admin__members-care-team-wrap .custom-checkbox--fav {
        float: left;
    }
}

.members__care-team-steps {
    display: flex;
    align-items: center;
    padding: 15px 0;
}
.members__care-team-steps-text {
    font-size: 20px;

    span {
        font-weight: 600;
    }
}
.members__care-team-steps-select-nurse--btn {
    padding: 5px 15px !important;
}

@media( max-width:767.98px){
    .members__care-team-steps {
        display: block;
    }
    .members__care-team-steps-text{
        margin-bottom: 10px !important;
    }
}
.members-profile-image{
    width: 45px;
    height: 45px;
    border-radius:50%;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
    }
}
.loading .members-profile-image{
    background-color: #F2F2F2;
}
.loading .members-profile-image img{
    display: none;
}
.loading .members__care-team-head-sm-card-head--text{
    background-color: #F2F2F2;
    text-indent: -99999999em;
    margin-bottom: 5px;
}
.loading .members__care-team-head-sm-card-head--subtext{
    background-color: #F2F2F2;
    text-indent: -99999999em;
}
