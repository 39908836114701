

    .custom-react-select--resources {
        width: 100% !important;
    }
    .mdh-reactable .resources-table__link-icon {

        @media( max-width:767.98px){
            padding: 9.5px 10.5px;
        }
    }

    .careteam-modal__team-item--text {
        font-size: 15px;
        font-weight: 600;
        color: #333;
        padding-left: 5px;
        cursor: pointer;

        &:hover,&:focus,&:active {
            text-decoration: none;
        }
    }

@media (max-width: 767.98px) {
    .custom-react-select--resources {
        margin-left: 0;
        margin-top: 8px;
    }
    .resources-top-section form {
        margin-right: 0 !important;
    }
}
// @media (max-width: 767.98px) {
//     .drop-menu-in-table .table-responsive{
//         overflow-x: auto !important;
//         overflow-y: auto !important;
//     }
// }
// .drop-menu-in-table .table-responsive{
//     overflow-x: unset;
// }
@media (max-width: 575.98px) {
    .careteam-modal__team-item--text {
        padding-left:8px;
    }
    .call-center-profile__head-btn .btn-primary, .call-center-profile__head-btn .btn-outline-primary {
        margin: 8px 13px 8px 0;
    }
}
.drop-menu-in-table{
    width: 100%;
    display: block;

    .table-responsive {
        overflow-x: unset;

        @media(max-width: 1199.98px) {
            overflow-x: auto;
        }
    }
}
.rain-toggle-drop-menu .dropdown-menu{
    display: none;
}
.rain-toggle-drop-menu .dropdown-menu.show{
    display: flex;
}
.patients-dependents-list{
    position: relative;
    width: 100%;
    background-color: #ebfbf7;
    border: solid 1px rgba(19, 120, 120, 0.1);
    border-radius: 4px;
    padding: 8px 8px;
    margin-bottom: 15px;
    .has-access {
        float: right
    }
    .dependent-remove-text{
        color: red;
        padding-left: 5px;
        font-weight: 600
    }
    .dependent-name-text{
        font-weight: 600;
    }
}
.import-companies-modal__custom-input-wrap.dependent-alert-box {
    font-size: 15px;
    color: #333333 !important;
    background-color: #F7F8E7 !important;
    border-color: #DDE63C !important;
    border-radius: 3px;
    padding: 15px;
}
