.member-call-center-wrap {
    .call-center-profile__call-request-list {
        float: left;
        width: 100%;
    }
    .call-center-profile-wrap .call-center-profile__call-request-link {
        font-size: 16px;
    }
    .call-center-profile-wrap .call-center-profile__call-request .call-center-profile__call-request-head {
        margin-bottom: 0;
    }
    .call-center-profile-wrap .call-center-profile__call-request-item h5.call-center-profile__call-request-item-text {
        font-size: 15px;
        font-weight: 600;
        margin-top: 7px;
        padding-bottom: 6px;
        
        @media(max-width: 575.98px) {
            padding-left: 10px;
        }
    }
    .call-center-profile-wrap .call-center-profile__call-request-item p.call-center-profile__call-request-item-text {
        font-size: 14px;
        padding-bottom: 10px;

        span {
            padding-left: 5px;
            font-weight: normal ;
        }
        @media(max-width: 575.98px) {
            padding-left: 10px;
        }
    }
    .call-center-profile-wrap .call-center-profile__call-request-item {
        position: relative;
        float: left;
        width: 100%;
        border-bottom: 1px solid #E0E0E0;
    }
    .mdh-pagination {
        float: left;
        width: 100%;
        padding: 30px 0;

        .pagination {
            margin-bottom: 0;
        }
    }
}