.rc-tabs-top{
    display: grid;
    -ms-display: grid;
    border-bottom: none;
}
.mdh-rc-tab .rc-tabs-bar{
    border-bottom: none;
}
.mdh-rc-tab .rc-tabs-tab{
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px !important;
    cursor: pointer;
    color: #C5C5C5;
    transition: .4s all;
    margin-right:0;
}
.mdh-rc-tab .rc-tabs-ink-bar{
    background-color: transparent;
}
.mdh-rc-tab .rc-tabs-ink-bar:after{
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    bottom: 0px;
    left: 0;
    background-color: #00cc99;
    box-shadow: 0px 0px 20px 1px #00cc99;
}
.mdh-rc-tab .rc-tabs-tab-prev-icon, .mdh-rc-tab .rc-tabs-tab-next-icon{
    padding:9px 0;
}
.mdh-rc-tab .rc-tabs-tab-prev-icon:before{
    content: '' !important;
    background: transparent url(/images/global/previous.svg) no-repeat;
    width: 9px;
    height: 18px;
    position: absolute;
    top: 14px;
    left: -6px;
}
.mdh-rc-tab .rc-tabs-tab-next-icon:before{
    content: '' !important;
    background: transparent url(/images/global/previous.svg) no-repeat;
    width: 9px;
    height: 18px;
    position: absolute;
    top: 14px;
    right: -6px;
    transform: rotate(180deg);
}
.mdh-rc-tab  .rc-tabs-content .rc-tabs-tabpane {

    overflow: unset;
}
.mdh-rc-tab .rc-tabs-inner{
    padding: 30px 30px 60px 30px;
}
@media(max-width:575.98px){
    .mdh-rc-tab .rc-tabs-inner{
        padding: 20px 0px 20px 0px;
    }
    .sm-accordion-btn-wrap {
        .btn {
            width: 100%;
            margin: 7px 11px 4px 11px !important;
            padding: 6px 10px;
        }
    }
}

.rc-tabs-tab:hover{
    color: #00cc99 !important;
}

.cp-tab-pane {
    .goals-wrap {
        display: flex;
        flex-direction: column;

        .boxed-custom-input-wrap.white {
            padding: 10px 15px !important;

            @media(max-width: 575.98px) {
                flex-direction: column;
                align-items: flex-start;

                .task-number {
                    font-size: 16px;
                    margin-left: 0;
                    margin-top: 6px;
                }
                .boxed-custom-input--text {
                    font-size: 15px;
                }
            }
        }
    }
    .create-care-plan-btn-wrap {
        float: right;
        width: 100%;
        text-align: right;
        margin-top: 16px;

        @media(max-width: 575.98px) {
            .btn {
                width: 100%;
                padding: 6px 10px;
            }
        }
    }
}
