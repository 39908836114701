.appointment-wrap {

    .mdh-accordion {
        margin-bottom: 27px;
        padding: 19px 24px;
    }
}
.appointments__accordion-address {
    padding: 16px 10px;
    padding-left: 80px;
    display: flex;

    img {
        padding-right: 12px;
        padding-top: 5px;
    }
}
.appointments__accordion-address-text {
    font-size: 16px;
    padding-top: 4px;
    padding-bottom: 5px;
    margin-bottom: 0;

    p {
        margin-bottom: 0;
        line-height: 1;
        color: #333333;
    }
}
.appointments__accrodion-address-link {
    color: $mdh-green300;
}
.appointments__accrodion-address-link:hover,
.appointments__accrodion-address-link:focus,
.appointments__accrodion-address-link:active {
    text-decoration: none;
    color: $mdh-green300;
}
.appointments__accordion-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 12px;

    .btn {
        margin-left: 15px;
        margin-top: 7px;
        margin-bottom: 4px;
    }
}
.appointment-wrap {

    .mdh-acc-text--date {

        .mdh-acc-text--date-head {
            padding-top: 5px;
            line-height: 18px;
        }
    }
}
@media (max-width : 767.98px) {
    .mdh-accordion-outer .mdh-accordion--white-valign:first-child {
        justify-content: flex-end;

        .mdh-acc-text--date {
            padding-left: 0;
        }
    }
    .mdh-accordion--white-valign {
        justify-content: flex-start !important;

        .mdh-acc-text--date {
            padding-left: 80px;
            margin-top: 10px;
            @media( max-width:576.98px){
                padding-left:0px;
                margin-top:0px;
            }
            .plan-status {
                margin-left: 0 !important;
            }
        }

    }
    .appointments__accordion-address {
        padding-left: 10px;
    }
    .appointments__accordion {
        max-width: 100%;
        position: relative;
        overflow: hidden;
        margin-top: 15px;
    }
}
