.mdh-card--nurse {

    .mdh-card__head {
        padding: 25px 12px 25px 25px;
    }
    .mdh-card__head--label {
        font-size: 20px;

        @media(max-width: 1199.98px) {
            margin-bottom: 10px;
        }
    }
    .dashboard__recent--label {
        padding: 8px 16px;
    }
    .dashboard__recent {
        max-height: none;
        overflow: unset;
    }
    .mdh-accordion-outer {
        padding: 19px 29px;

        .btn--add-task {
            min-width: 186px;

            @media(max-width: 575.98px) {
                width: 100%;
            }
        }
    }
    .mdh-accordion {
        padding: 14px 0;
    }
    .mdh-accordion--top {
        padding: 0 24px;
    }
    .appointments__accordion {
        padding: 0 24px;
    }
    .mdh-accordion--white-left-date {
        padding-left: 0;
    }
    .mdh-acc-text--date-show {
        float: left;
        font-size: 23px;
        font-weight: 600;
        color: $mdh-grey25;
        text-align: center;
        margin-bottom: 0;
        margin-right: 27px;
        padding-right: 18px;
        line-height: 1;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -12px;
            width: 6px;
            height: 100%;
            border-radius: 30px;
        }
        &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: 63px;
            width: 1px;
            height: 75%;
            background-color: $mdh-grey10;

            @media(max-width: 575.98px) {
                top: 0;
                height: 55%;
            }
        }
        span {
            position: relative;
            top: -5px;
            font-size: 18px;
            font-weight: 500;
            color: $mdh-grey25;
        }
    }
    .mdh-acc-text--date-show.task {
        color: $mdh-blue;
    }
    .mdh-acc-text--date-show.event {
        color: $mdh-green300;
    }
    .mdh-acc-text--date-show.requests {
        color: $mdh-lime;
    }
    .mdh-acc-text--date-show.task::before {
        background-color: $mdh-blue;
    }
    .mdh-acc-text--date-show.event::before {
        background-color: $mdh-green300;
    }
    .mdh-acc-text--date-show.requests::before {
        background-color: $mdh-lime;
    }
    .appointments__accordion-address {
        padding-left: 0;

        .appointments__accordion-address--text {
            color: $mdh-grey-200;
            line-height: 1.2;
            word-break: break-word;
        }
    }
    .mdh-acc-text--date-sub-head.main-head {
        font-size: 19px;

        @media(max-width: 991.98px) {
            font-size: 17px;
        }
        @media(max-width: 575.98px) {
            font-size: 18px;
        }
    }
    .mdh-acc-text--date-sub-head {
        margin-bottom: 0;

        a {
            color: $mdh-green300;
        }
    }
    .appointments__accordion-btn-wrap .btn {
        min-width: 110px;
    }
    .mdh-acc-text--date {
        width: 100%;

        @media(max-width: 575.98px) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 8px;
        }
    }
    .appointments__accordion-btn-wrap {
        @media(max-width: 575.98px) {
            flex-direction: column;

            .btn {
                width: 100%;
                margin-left: 0;
            }
        }
    }
}
.nurse-dashboard-filter {
    width: 15%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .custom-react-select {
        width: 100%;
        margin-bottom: 0;
    }
    .react-select__control {
        min-height: 35px !important;

        @media(max-width: 767.98px) {
            margin-bottom: 10px;
        }
    }
}
.navbar__notification-dropdown-messages--time {
    line-height: 1;
    a{
        color: $mdh-green600;
    }
}
.dashboard-task-img {
    position: relative;
    margin-right:14px;
    display: flex;
    align-items: center;
    float: left;
}
.dashboard-task-data {
    position: relative;
    float: left;
}
.time-stamp {
    p {
        margin:0;
        padding:0;
        line-height: 1;
        font-size: 13px;
        color: $mdh-grey;
    }
    small {
        font-size: 12px;
        color: $mdh-grey-200;
        line-height: 1;
    }
}

.event-task-indicator {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 5px;
    position: relative;
    height: 40px;
    .custom-control{
        display: block !important;
        margin-top:10px;

        @media(max-width: 575.98px) {
            margin-top: 0;
        }
    }
    .event-task-indicator--item {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .indicator {
        font-size: 15px;
        font-weight: 500;
        margin: 0;
        padding-left: 12px;
        margin-right: 15px;
        margin-left:5px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 6px;
            height: 100%;
            border-radius: 30px;

        }
        @media(max-width: 575.98px) {
            margin-right: 25px;
            margin-bottom: 10px;
        }
    }
    .indicator.task::before {
        background-color: $mdh-blue;
    }
    .indicator.event::before {
        background-color: $mdh-green300;
    }

    @media(max-width: 1199.98px) {
        margin-bottom: 10px;
    }
    @media(max-width: 575.98px) {
        justify-content: flex-start;
        flex-direction: column;
        height: unset;
    }
}
@media (min-width: 767.98px) and (max-width: 991.98px) {
    .mdh-card--nurse span.mdh-acc-text--date-sub-head {
        font-size: 13px;
    }
}
